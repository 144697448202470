<div class="loading" *ngIf="global.loading">Loading&#8230;</div>
<div class="container-fluid py-5 px-5">
    <div class="row align-items-center justify-content-start">
        <div class="col">
            <form [formGroup]="form" class="py-3">

                <div class="mb-3 row d-none">
                    <label class="col-sm-2 col-form-label">Source ID</label>
                    <div class="col-sm-10">
                        <input type="text" readonly class="form-control-plaintext" formControlName="source_id">
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-2 col-form-label">Source</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control {{form.get('source').touched && form.get('source').invalid? 'is-invalid' : ''}}" formControlName="source">
                    </div>
                </div>

                <div class="mb-3 row">
                    <label class="col-sm-2 col-form-label">Source Link</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control {{form.get('source_link').touched && form.get('source_link').invalid? 'is-invalid' : ''}}" formControlName="source_link">
                    </div>
                </div>
            </form>
            <div class="row justify-content-end">
                <div class="col-3">
                    <div class="d-grid gap-2">
                        <button class="btn btn-primary" type="button" (click)="save()">Save</button>
                    </div>
                </div>
            </div>
            
    </div>
</div>