

<div class="d-none">
    <qrcode #parent (qrCodeURL)="onChangeURL($event)" 
    [qrdata]="this.qr_code_value" 
    
    
    [width]="256" 
    [errorCorrectionLevel]="'M'" 
    
    [imageHeight]="75" 
    [imageWidth]="75" 
    [margin]="4" 
    [scale]="1" 
    elementType="img" 
    [colorDark]="'#000000ff'" 
    [colorLight]="'#ffffffff'" 
    [allowEmptyString]="true" 
    [ariaLabel]="'QR Code image with the following content...'"
    [imageSrc]="'https://cordobo.github.io/angularx-qrcode/assets/angular-logo.png'">
    </qrcode>
</div>
