import { Component } from '@angular/core';
import { FormBuilder,Validators } from '@angular/forms';
import { Globals } from '../global/global-variable';
import { LoginService } from '../services/login-service';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-login',
  
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    form = this.fb.group({
        email: ['', [Validators.required,Validators.pattern('[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
        password: ['',Validators.required],
        remember_me : [false]
    });

    field_label = {
        email       : "Email address",
        password    : "Password"
    }

    show_password = false;

    //Get Current Year
    public year_today;
    public date_today;

    constructor(
        public global           : Globals,
        private router          : Router,
        private fb              : FormBuilder,
        private toastr          : ToastrService,

        private loginService    : LoginService,
        ){

        //Get Current Year
        this.date_today = new Date()
        this.year_today = this.date_today.getFullYear();
        
    }

    ngOnInit(): void {
        if(localStorage.getItem('remember_me') !== null){
            const remember_me_arr   = JSON.parse(localStorage.getItem('remember_me') as string);
            
            if(remember_me_arr.remember_me){
                this.form.controls['remember_me'].setValue(remember_me_arr.remember_me);
                this.form.controls['email'].setValue(remember_me_arr.user_name);
            }//end if
        }//end if

        if(this.global.login_session !== null && this.global.login_session["id"] !== undefined){
            window.location.replace('/main/dashboard');
        }//end if
    }

    login(){
        this.global.loading = true;
        const val = this.form.value;

        

        try{
            //validate login
            let error_html_arr = new Array();

            
            if(!this.form.valid){
                //this.form.controls['email'].setErrors({'user_not_found': false});
                //this.form.controls['email'].setErrors({'password_incorrect': false});

                for(var k in this.form.controls){
                    var field = this.form.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['pattern'] !== undefined && field.errors?.['pattern']){
                        error_html_arr.push(this.field_label[k]+' is invalid format <br/>');
                        
                    }//end if

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if

                    if(field.errors?.['user_not_found'] !== undefined && field.errors?.['user_not_found']){
                        error_html_arr.push('The email you entered did not match our records. Please double-check and try again. <br/>');
                    }//end if

                    if(field.errors?.['password_incorrect'] !== undefined && field.errors?.['password_incorrect']){
                        error_html_arr.push('Incorrect password. Please try again.<br/>');
                    }//end if
                }//end for
            }//end if
            //end validate login

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "Require Field",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if
            
            var test_param = {
                "username" : val.email,
                "password" : val.password
            }
            this.loginService.login(test_param).subscribe((response) => {
                console.log('new version',response);

                if(val.remember_me){
                    const remember_me_arr = {
                        "remember_me" : val.remember_me,
                        "user_name" : val.email
                    }
                    localStorage.setItem('remember_me',JSON.stringify(remember_me_arr));
                }else{
                    if(localStorage.getItem('remember_me') !== null){
                        localStorage.removeItem('remember_me');
                    }//end if
                }//end if

                if(response["success"]){
                    //create session
                    //console.log(response["data"]);
                    localStorage.setItem('accessToken',response["data"]["fb_profile"]["idToken"]);
                    const now = new Date() as any;
                    const now_time = now.getTime();
                    localStorage.setItem('accessToken_start',now_time);
                    localStorage.setItem('date_set_time',now_time);
                    localStorage.setItem('session_start',now_time);
                    localStorage.setItem("token_start",now_time);

                    localStorage.setItem("login_session",JSON.stringify(response["data"]));
                    //end create session
                    console.log("Successfully Logged In!");
                    //this.toastr.success("Successfully Logged In!","System Message");
                 
                    //console.log(response.data)
                    window.open('/main/dashboard',"_self");
                    
                }else{
                    
                    const errObj = {
                        code : response["data"]["error"]["code"],
                        message : response["data"]["error"]["message"],
                    }//end 
                  

                    /*if(JSON.stringify(response["data"]["message"]).includes('auth/user-not-found')){
                        this.form.controls['email'].setErrors({'user_not_found': true});
                    }else if(JSON.stringify(response["data"]["message"]).includes('auth/wrong-password')){
                        this.form.controls['password'].setErrors({'password_incorrect': true});
                    }else if(JSON.stringify(response["data"]["message"]).includes('auth/invalid-login-credentials')){
                        this.form.controls['password'].setErrors({'password_incorrect': true});
                    }else{
                        this.toastr.error(response.data["message"],"System Message");
                    }//end if
                    */

                    


                    if(JSON.stringify(response["data"]["error"]["message"]).toLowerCase().includes('email_not_found') || JSON.stringify(response["data"]["error"]["message"]).toLowerCase().includes('user not found')){
                        this.form.controls['email'].setErrors({'user_not_found': true});
                    }else if(JSON.stringify(response["data"]["error"]["message"]).toLowerCase().includes('invalid_password')){
                        this.form.controls['password'].setErrors({'password_incorrect': true});
                    }else if(JSON.stringify(response["data"]["error"]["message"]).includes('auth/invalid-login-credentials')){
                        this.form.controls['password'].setErrors({'password_incorrect': true});
                    }else{
                        
                        this.toastr.error(response["data"]["error"]["message"],"System Message");
                    }//end if
                    

                    
                    //throw errObj;
                    
                }//end if
                this.global.loading = false;
            })

            /*this.loginService.signIn(val).then((response) => {

                if(val.remember_me){
                    const remember_me_arr = {
                        "remember_me" : val.remember_me,
                        "user_name" : val.email
                    }
                    localStorage.setItem('remember_me',JSON.stringify(remember_me_arr));
                }else{
                    if(localStorage.getItem('remember_me') !== null){
                        localStorage.removeItem('remember_me');
                    }//end if
                }//end if

                
                if(response.success){
                    //create session
                    console.log(response.data)
                    const now = new Date() as any;
                    const now_time = now.getTime();
                    localStorage.setItem('date_set_time',now_time);
                    localStorage.setItem('session_start',now_time);
                    localStorage.setItem("token_start",now_time);

                    localStorage.setItem("login_session",JSON.stringify(response.data));
                    //end create session
                    console.log("Successfully Logged In!");
                    //this.toastr.success("Successfully Logged In!","System Message");
                 
                    //console.log(response.data)
                    //window.open('/main/dashboard',"_self");
                    
                }else{
                    const errObj = {
                        code : response.data["code"],
                        message : response.data["message"],
                    }//end 
                  

                    if(JSON.stringify(response["data"]["message"]).includes('auth/user-not-found')){
                        this.form.controls['email'].setErrors({'user_not_found': true});
                    }else if(JSON.stringify(response["data"]["message"]).includes('auth/wrong-password')){
                        this.form.controls['password'].setErrors({'password_incorrect': true});
                    }else if(JSON.stringify(response["data"]["message"]).includes('auth/invalid-login-credentials')){
                        this.form.controls['password'].setErrors({'password_incorrect': true});
                    }else{
                        this.toastr.error(response.data["message"],"System Message");
                    }//end if

                    

                    
                    //throw errObj;
                    
                }//end if
                this.global.loading = false;
            });
            */
        }catch(e : any){
            //this.toastr.error(e.message,"System Message",{enableHtml : true});
            console.log(e.message);
            this.global.loading = false;
        }//end try
        
        //alert(val.email);
        //this.global.loading = false;
    }//end if

    s_password(name){
        
        if(name == 'password'){
            if(this.show_password){
                this.show_password = false;
            }else{
                this.show_password = true;
            }//end if
            
        }//end if
    }

    
}//end main
