import { Component } from '@angular/core';
import { Globals } from 'src/app/global/global-variable';
@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent {
    title = "Dashboard";
    constructor(public global : Globals){
       
    }

    ngOnInit(){
        

    }

   


}
