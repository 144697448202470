import { Component, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  @Output() nameEmitter = new EventEmitter(); 
  loading = false

  receivename($event: boolean) {  
    this.loading = $event;  
  } 
}
