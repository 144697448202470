import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { Globals } from '../global/global-variable';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from '../services/user-management-service';
import { LoginService } from '../services/login-service';
import { CommonService } from '../library/pcl_lib';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent {
    @ViewChild('confirmation_close_button') closebutton;
    
    @ViewChild('success_delete_btn') success_delete_modal;
    
    currevent;
    filter_form = this.fb.group({
        filter_search : [''],
        page_size_placeholder : [10],
    });
    form = this.fb.group({
        id                          : [''],
        action                      : ['add'],
        first_name                  : ['',[Validators.required,Validators.pattern('[a-zA-Z .\-]*')]],
        last_name                   : ['',[Validators.required,Validators.pattern('[a-zA-Z .\-]*')]],
        position                    : ['',[Validators.required]],
        country_code                : ['ph'],
        country_name                : ['Philippines'],
        dial_code                   : ['+63'],
        mobile_number               : [''],
        username                    : ['',[Validators.required,Validators.pattern('[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
        username_placeholder        : [''],
        user_type                   : ['',Validators.required],
        password                    : ['',[Validators.required,Validators.minLength(6)]],
        password_placeholder        : [''],
        conf_password_placeholder   : ['',[Validators.required,Validators.minLength(6)]]
    });

    field_label = {
        "first_name" : "First Name",
        "last_name" : "Last Name",
        "position" : "Position",
        "user_type" : "Role",
        "mobile_number" : "Mobile Number",
        "dial_code" : "Dial Code",
        "username" : "Email Address",
        "password" : "Password",
        "conf_password_placeholder" : "Confirm Password",
    }

    table_list = new Array();

    show_edit_btn       = false;
    show_add_btn        = false;
    show_delete_btn     = false;

    //show password
    show_password = false;
    show_password2 = false;

    show_eye_icon = true;
    eye_icon_style = "border-right:none !important;";
    //end show password

    readonly_group      = false;

    //for pagination
    previous                    = false;
    lastVisibleField            = "id";
    lastVisibleVal              = "";
    prevPageVisibleVal          = new Array();
    pageNumber                  = 0;
    row_counter                 = 0;
    pagination_counter          = 0;
    pageSize                    = [10,20,50,100];

    show_total_search_result    = false;
    total_search_result         = 0;
    total_result                = 0;

    for_delete                  = {};
    session = this.global.login_session;

    constructor(
        private fb : FormBuilder,
        public global : Globals,
        private toastr : ToastrService,
        private userManagementService : UserManagementService,
        private loginService : LoginService,
        public commonService : CommonService
    ){
        this.form.addValidators(
            matchValidator(this.form.controls["password"], this.form.controls["conf_password_placeholder"])
        );
        
        
    }//end constructor

    ngOnInit(){

        

        //check permission
        let w_access     = false;
        const permission = this.global.login_session["permission"]["manage_user"];
        for(const k in permission){
            if(permission[k] == 'view'){
                w_access = true;
            }//end if

            if(permission[k] == 'add'){
                this.show_add_btn = true;
            }//end if

            if(permission[k] == 'delete'){
                this.show_delete_btn = true;
            }//end if

            if(permission[k] == 'edit'){
                this.show_edit_btn = true;
            }//end if

            
            
        }//end for
        if(!w_access){
            window.location.replace('/main/dashboard');
        }//end if
        //end check permission
        
        this.refresh_list();
    }

    refresh_list(){
        const val               = this.filter_form.value;
        this.global.loading     = true;
        
        try{
            let filters = {};
            filters["filterFieldArr"]       = new Array();
            filters["filterOperatorArr"]    = new Array();
            filters["filterValueArr"]       = new Array();
            filters["orderByFieldArr"]      = new Array();
            filters["sortOrderArr"]         = new Array();

            if(val.filter_search !== ""){
                filters["filterFieldArr"].push("search_full_name");
                filters["filterOperatorArr"].push("array-contains");
                filters["filterValueArr"].push(val.filter_search.toLowerCase());
            }//end if
            
            filters["orderByFieldArr"].push("date_created");
            filters["orderByFieldArr"].push("id");

            filters["sortOrderArr"].push("desc");
            filters["sortOrderArr"].push("desc");

           
            
            const query_param = {
                "record_type" : environment.user_management,
                "pageNumber" : this.pageNumber,
                "lastVisibleField" : this.lastVisibleField,
                "lastVisibleVal" : this.lastVisibleVal,
                "previous" : this.previous,
                "pageSize" : val.page_size_placeholder,
                ...filters
            }//end

            console.log('query_param',query_param)
    
            //return this.settingService.filterList(query_param);
            this.userManagementService.filterList(query_param).subscribe(res => {
                console.log(res);
                if(res["success"]){
                    this.table_list                 = res["data"]["query_data"];
                    this.total_search_result        = res["data"]["total_result"];
                    this.total_result               = res["data"]["total_result"];
                    this.show_total_search_result   = true;
                    this.row_counter                = (this.pageNumber * val.page_size_placeholder) + 1;
                    this.pagination_counter         = this.row_counter + (this.table_list.length - 1);
                }else{
                    console.log(res);
                    this.toastr.error(res["data"]["message"],"System Message");
                }//end if
                
                this.global.loading = false;
            });
        }catch(error : any){
            this.toastr.error(error["message"],"System Message");
        }//end try
        
        

        
    }//end

    edit_data(param){
       
        this.show_password = false;
        this.show_password2 = false;
        this.form.controls['action'].setValue('update');
        for(const k in this.form.controls){
            if(k == "action"){
                continue;
            }//end if

            
            

            if(k.includes("_placeholder")){
                this.form.controls[k].setValue(param[k.replace("_placeholder","")]);
            }else{
                this.form.controls[k].setValue(param[k]);
            }//end if


            
        }//end for

        this.form.controls["conf_password_placeholder"].setValue(this.form.controls["password"].value);
        
        if(this.global.login_session["user_type"] !== 'super_admin' && this.global.login_session["user_type"] !== 'admin'){
            this.form.controls["user_type"].disable();
        }//end if

        if(this.global.login_session["user_type"] !== 'super_admin'){
            this.readonly_group = true;
        }//end if
        

        if(this.form.controls["country_code"].value !== undefined && this.form.controls["country_code"].value !== null){
            this.currevent.setCountry(this.form.controls["country_code"].value)
        }else{
            this.currevent.setCountry('ph')
        }//end if

        //alert(this.session["id"].trim() !== param.id.trim())
        if(this.session["id"] !== param.id){
            this.show_eye_icon = false;
            this.eye_icon_style = "";
        }else{
            this.show_eye_icon = true;
            this.eye_icon_style = "border-right:none !important;";
        }
       
    }//end

    set_for_delete(param){
        this.for_delete = param;
    }//end if

    delete_data(param){

        

        try {
            //check permission
            let compare_val  = "delete";
            let w_access     = false;
            const permission = this.global.login_session["permission"]["manage_user"]
            for(const k in permission){
                if(permission[k] == compare_val){
                    w_access = true;
                    break;
                }//end if
            }//end for
           
            if(!w_access){
                const errorObj = {
                    code : "Access_Denied",
                    message : "You don't have permission to do this action!"
                }//end

                throw errorObj;
            }//end if

            //if(confirm('Are you sure you want to delete this user?')){
                this.global.loading = true;
                /*const request_param = {
                    "records" : [{
                        "record_type"   : environment.user_management,
                        "action" : "delete",
                        "id"  : param["id"],
                        "username" : param["username"],
                        "password" : param["password"]
                    }]
                }*/

                const request_param = {
                    "record_type"   : environment.user_management,
                    "action" : "delete",
                    "id"  : param["id"]
                }

                this.loginService.createUserV2(request_param).subscribe((response) => {
                    console.log(response);
                    if(response["success"]){
                        this.toastr.success("User is successfully deleted!","System Message");
                        this.closebutton.nativeElement.click();
                        //this.success_delete_modal.nativeElement.click();
                        setTimeout(() => {
                            window.location.reload();    
                        }, 2000);
                        
                    }else{
                        this.toastr.error(response["data"]["error"]["message"],"System Message");
                    }//end if
                    
                    
                    this.global.loading = false;
                    
                    //this.refresh_list(this.selected_tab);
                });
    
                /*this.loginService.delete_firebase_user(request_param).then(response => {
                    console.log(response);
                    if(response["success"]){
                        this.toastr.success("User is successfully deleted!","System Message");

                   
                    }else{
                        this.toastr.error(response["data"]["message"],"System Message");
                       
                    }//end if

                    //close modal automatically
                    this.closebutton.nativeElement.click();
                    this.global.loading = false;

                    
    
                    this.refresh_list();

                    
                })*/
            //}//end if

            
            //end check permission
        } catch (error : any) {
            this.toastr.error(error.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end try
        
        
    }

    save_data(){
        this.global.loading = true;
        const val = this.form.value;

        try {

            //check permission
            let compare_val  = "";
            if(val.action == 'add'){
                compare_val = 'add';
            }else if(val.action == 'update'){
                compare_val = 'edit';
            }//end if

            let w_access     = false;
            const permission = this.global.login_session["permission"]["manage_user"]
            for(const k in permission){
                if(permission[k] == compare_val){
                    w_access = true;
                    break;
                }//end if
            }//end for
           
            if(!w_access){
                const errorObj = {
                    code : "Access_Denied",
                    message : "You don't have permission to do this action!"
                }//end

                throw errorObj;
            }//end if

            
            //end check permission


            //validate login
            if(this.form.controls["password"].value !== this.form.controls["conf_password_placeholder"].value){
                //error_html_arr.push('Passwords do not match. Try again! <br/>');
                this.form.controls['conf_password_placeholder'].setErrors({"password_match" : true});
            }//end if


            let error_html_arr = new Array();
            if(!this.form.valid){
                for(var k in this.form.controls){
                    var field = this.form.controls[k];
                    field.markAsTouched();

                    if(val.action == 'update'){
                        if(k.includes('password')){
                            continue;
                        }
                    }
                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if

                    if(field.errors?.['pattern'] !== undefined && field.errors?.['pattern']){
                        error_html_arr.push(this.field_label[k]+' is invalid format <br/>');
                        
                    }//end if

                    if(field.errors?.['minlength'] !== undefined && field.errors?.['minlength']){
                        error_html_arr.push(this.field_label[k]+' must have 6 minimum characters <br/>');
                        
                    }//end if

                    if(field.errors?.['matchValidator'] !== undefined && field.errors?.['matchValidator']){
                        error_html_arr.push(this.field_label[k]+' doesn\'t match! <br/>');
                        
                    }//end if

                    if(field.errors?.['password_match'] !== undefined && field.errors?.['password_match']){
                        error_html_arr.push('Passwords do not match. Try again! <br/>');
                        
                    }//end if

                    
                }//end for

                


            }//end if
            //end validate login

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "Require Field",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if

            //save to firebase

            //create search
            let full_name = val.first_name + " " + val.last_name;
            var search_full_name        = new Array();
                search_full_name        = full_name.toLowerCase().split(" ");
            let tmp_data                = ""; 
            for(const x in search_full_name){
                tmp_data += search_full_name[x] + " ";
                if(parseInt(x) > 0){
                    search_full_name.push(tmp_data.trim());
                }//end if
            }//end if


            let tmp_data_arr  = tmp_data.split(" ");
            while (tmp_data_arr.length !== 0) {
                tmp_data_arr.splice(0,1);
                const text = tmp_data_arr.join(" ");
                if(text !== ""){
                    search_full_name.push(text.trim());
                }//end if
                // code block to be executed
            }//end while
            //end create search

            search_full_name = [...new Set(search_full_name)]

            val["search_full_name"] = search_full_name;
            
            
            /*const request_param = {
                "records" : [{
                    "record_type"   : environment.user_management,
                    "date_created"  : new Date(),
                    ...val
                }]
            }*/

            var request_param = {
                "record_type"   : environment.user_management,
                ...val
            }

    
            if(val.action == 'add'){
                request_param['date_created'] = new Date();
                request_param['created_by'] = this.global.login_session["id"];
            }//end if

            console.log(request_param);

            this.loginService.createUserV2(request_param).subscribe((response) => {
                console.log(response);
                if(response["success"]){
                    if(val.action == "add"){
                        this.toastr.success("User is successfully added!","System Message");
                    }else if(val.action == "update"){
                        this.toastr.success("User details are successfully updated!","System Message");
                    }//end if
                    
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                    
                }else{
                    console.log(response["data"]["error"]["code"])
         
                    if((response["data"]["error"]["code"].toString()).includes('auth/email-already-exists')){
                        this.form.controls['username'].setErrors({'dup': true});  
                    }else{
                        this.toastr.error(response["data"]["error"]["message"],"System Message");
                    }//end if
                    
                }//end if
                this.global.loading = false;
            })
    

            /*this.loginService.createUser(request_param).then((response) => {
                console.log('request_param',request_param);
                console.log('response',response);
                if(response["success"]){
                    if(val.action == "add"){
                        this.toastr.success("User is successfully added!","System Message");
                    }else if(val.action == "update"){
                        this.toastr.success("User details are successfully updated!","System Message");
                    }//end if
                    
                    setTimeout(() => {
                        //window.location.reload();
                    }, 2000);
                    
                }else{
         
                    if(JSON.stringify(response["data"]["message"]).includes('auth/email-already-in-use')){
                        this.form.controls['username'].setErrors({'dup': true});  
                    }else{
                        this.toastr.error(response["data"]["message"],"System Message");
                    }//end if
                    this.global.loading = false;
                }//end if
                
            });
            */

            

          


        } catch (error : any) {
            //this.toastr.error(error.message,"System Message",{enableHtml : true});
            console.log(error.message)
            this.global.loading = false;
        }//end 
    }//end save data

    

    close(){
        this.form.reset();
        this.form.controls['action'].setValue('add');
        

        if(this.global.login_session["user_type"] !== 'super_admin' && this.global.login_session["user_type"] !== 'admin'){
            this.form.controls["user_type"].enable();
        }//end if

        if(this.global.login_session["user_type"] !== 'super_admin'){
            this.readonly_group = false;
        }//end if

        this.show_eye_icon = true;
        this.eye_icon_style = "border-right:none !important;";

        this.for_delete = {};
        this.refresh_list();
    }//end close

   

    onCountryChange(obj) {
       
        this.form.controls['country_name'].setValue(obj.name);
        this.form.controls['country_code'].setValue(obj.iso2);
        this.form.controls['dial_code'].setValue('+'+obj.dialCode);
    }

    telInputObject(obj) {
        
        this.currevent = obj;
        //console.log(obj);
        //obj.setCountry('ph');
    
        //this.form.controls['country_name'].setValue('Philippines');
        //this.form.controls['country_code'].setValue('ph');
        //this.form.controls['dial_code'].setValue('+63');
        /*
        if(this.data.profile !== undefined){
            obj.setCountry(this.data.profile.country_code);
        }else{
            obj.setCountry('ph');
            
        }*/
        
    }//end if

    s_password(name){
        
        if(name == 'password'){
            if(this.show_password){
                this.show_password = false;
            }else{
                this.show_password = true;
            }//end if
            
        }else{
            if(this.show_password2){
                this.show_password2 = false;
            }else{
                this.show_password2 = true;
            }//end if
        }//end if
    }

    search(){
        const val = this.form.value;
        this.previous                    = false;
        this.lastVisibleField            = "id";
        this.lastVisibleVal              = "";
        this. prevPageVisibleVal          = new Array();
        this.pageNumber                  = 0;
        this.refresh_list()
    }

    prev(){
        const val           = this.filter_form.value;;
        if(this.pageNumber > 0){
            this.pageNumber -= 1;
            this.previous = true;
            this.lastVisibleVal         = this.prevPageVisibleVal[this.pageNumber];
            this.refresh_list();
        }//end if
        
        
        
    }

    next(){
        const val           = this.filter_form.value;;
        const total_pages   = Math.ceil((this.total_result / val.page_size_placeholder));
        
        if(total_pages > (this.pageNumber + 1)){
            
            this.pageNumber             += 1;
            this.previous               = false;
            this.lastVisibleVal         = this.table_list[this.table_list.length - 1]["id"];
           
            if(!this.prevPageVisibleVal.includes(this.table_list[0]["id"])){
                this.prevPageVisibleVal.push(this.table_list[0]["id"]);
            }//end if

            this.refresh_list();
            
            
        }//end if
    }
}//end class


function matchValidator(
    control: AbstractControl,
    controlTwo: AbstractControl
): ValidatorFn {
    return () => {
        if (control.value !== controlTwo.value)
        return {matchValidator: {value: 'Value does not match' }};
        return null;
    };
}