import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { DatepickerOptions } from 'ng2-datepicker';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/en-US';
import { Globals } from '../global/global-variable';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../services/dashboard-service';
import { DatePipe } from '@angular/common';
import { CommonService } from '../library/pcl_lib';

var FileSaver = require('file-saver');


@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss']
})
export class AuditTrailComponent {
    @ViewChild('filter_date_from') filter_date_from: ElementRef;
    @ViewChild('filter_date_to') filter_date_to: ElementRef;

    empty_date : Date;
    form = this.fb.group({
        filter_weekly : [false],
        filter_monthly : [false],
        filter_yearly : [false],
        filter_date_from : [null],
        filter_date_to : [null],
        search_str_placeholder : [''],
        page_size_placeholder : [10]
    });

    disable_filter = false;
    
    date = new Date();
    date2 = new Date();
   
    date_option : DatepickerOptions = {
        //minYear: getYear(new Date()) - 30, // minimum available and selectable year
        //maxYear: getYear(new Date()) + 30, // maximum available and selectable year
        
        placeholder: 'Please pick a date', // placeholder in case date model is null | undefined, example: 'Please pick a date'
        format: 'MM/dd/yyyy', // date format to display in input
        formatTitle: 'LLLL yyyy',
        formatDays: 'EEEEE',
        //firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
        //locale: locale, // date-fns locale
        position: 'bottom',
        inputClass: 'form-control', // custom input CSS class to be applied
        scrollBarColor: '#dfe3e9', // in case you customize you theme, here you define scroll bar color
        enableKeyboard: true
        
    };

 

    show_total_search_result    = false;
    total_search_result         = 0;
    total_result                = 0;
    data_list                   = new Array();

    show_download_btn           = false;
    global_filter               = {};

    //for pagination
    previous                    = false;
    lastVisibleField            = "id";
    lastVisibleVal              = "";
    prevPageVisibleVal          = new Array();
    pageNumber                  = 0;
    row_counter                 = 0;
    pagination_counter          = 0;
    pageSize                    = [10,20,50,100];
    constructor(
        private fb : FormBuilder,
        private toastr : ToastrService,
        private dashboardService : DashboardService,
        private datePipe : DatePipe,
        public lib : CommonService,
        public global : Globals){

    }
    
    ngOnInit(){
        //check permission
        let w_access     = false;
        const permission = this.global.login_session["permission"]["audit_trail"]
        for(const k in permission){
            if(permission[k] == 'view'){
                w_access = true;
            }//end if

            if(permission[k] == 'download'){
                this.show_download_btn = true;
            }//end if
            
        }//end for
        if(!w_access){
            window.location.replace('/main/dashboard');
        }//end if
        //end check permission

        
    }

    refresh_list(param){
        const val               = this.form.value;
        this.global.loading     = true;

        try {
            //filter
            let filters         = {};

            filters["filterFieldArr"]       = new Array();
            filters["filterOperatorArr"]    = new Array();
            filters["filterValueArr"]       = new Array();
            filters["orderByFieldArr"]      = new Array();
            filters["sortOrderArr"]         = new Array();

            filters["filterFieldArr"].push("type");
            filters["filterOperatorArr"].push("==");
            filters["filterValueArr"].push("search_count");
            filters["orderByFieldArr"].push("date_created");
            filters["orderByFieldArr"].push("id");

            filters["sortOrderArr"].push("desc");
            filters["sortOrderArr"].push("asc");

            filters["filterFieldArr"].push("created_by");
            filters["filterOperatorArr"].push("==");
            filters["filterValueArr"].push(this.global.login_session["id"]);


            let today               = new Date();
            var curr_date           = today.getDate();
            var curr_month          = today.getMonth();
            var curr_year           = today.getFullYear();
            let from_date           = null;
            let to_date             = ((curr_month+1) + "/" + curr_date + "/" + curr_year) + ' 23:59:00';
            if(val.filter_weekly){
                //weekly
                var day = today.getDay();
                var diff = today.getDate() - day + (day == 0 ? -6:1);

                today.setDate(diff);
                curr_date       = today.getDate();
                curr_month      = today.getMonth();
                curr_year       = today.getFullYear();

                from_date         = ((curr_month+1) + "/" + curr_date + "/" + curr_year) + ' 00:00:00';
                
                
            }else if(val.filter_monthly){
                //monthly
                //today.setMonth(today.getMonth() - 1);
                curr_date       = today.getDate();
                curr_month      = today.getMonth();
                curr_year       = today.getFullYear();

                from_date         = ((curr_month+1) + "/" + 1 + "/" + curr_year) + ' 00:00:00';
            }else if(val.filter_yearly){
                //yearly
                //today.setFullYear(today.getFullYear() - 1);
                curr_date       = today.getDate();
                curr_month      = today.getMonth();
                curr_year       = today.getFullYear();

                from_date         = 1 + "/" + 1 + "/" + curr_year + ' 00:00:00';
            }//end if
            //end filter

            if(val.filter_date_from === null && val.filter_date_to === null){
                
                if(from_date !== null){
                    filters["filterFieldArr"].push("date_created");
                    filters["filterOperatorArr"].push(">=");
                    filters["filterValueArr"].push(from_date);
    
                    filters["filterFieldArr"].push("date_created");
                    filters["filterOperatorArr"].push("<=");
                    filters["filterValueArr"].push(to_date);
                }//end if
            }else if(val.filter_date_from !== null && val.filter_date_to !== null){
                //from_date   = val.filter_date_from;
                //to_date     = val.filter_date_to;

                var tmp_date       = val.filter_date_from
                var tmp_day        = tmp_date.getDate();
                var tmp_month      = tmp_date.getMonth();
                var tmp_year       = tmp_date.getFullYear();
                from_date   = ((tmp_month+1) + "/" + tmp_day + "/" + curr_year) + ' 00:00:00';

                tmp_date       = val.filter_date_to
                tmp_day        = tmp_date.getDate();
                tmp_month      = tmp_date.getMonth();
                tmp_year       = tmp_date.getFullYear();
                to_date        = ((tmp_month+1) + "/" + tmp_day + "/" + curr_year) + ' 23:59:00';
              

                filters["filterFieldArr"].push("date_created");
                filters["filterOperatorArr"].push(">=");
                filters["filterValueArr"].push(from_date);

                filters["filterFieldArr"].push("date_created");
                filters["filterOperatorArr"].push("<=");
                filters["filterValueArr"].push(to_date);
            }else{
                if(val.filter_date_from !== null){
                    //from_date   = val.filter_date_from;
                    var tmp_date       = val.filter_date_from
                    var tmp_day        = tmp_date.getDate();
                    var tmp_month      = tmp_date.getMonth();
                    var tmp_year       = tmp_date.getFullYear();
                    from_date           = ((tmp_month+1) + "/" + tmp_day + "/" + curr_year) + ' 00:00:00';


                    filters["filterFieldArr"].push("date_created");
                    filters["filterOperatorArr"].push(">=");
                    filters["filterValueArr"].push(from_date);
                }else if(val.filter_date_to !== null){
                    //to_date   = val.filter_date_to;
                    var tmp_date       = val.filter_date_to;
                    var tmp_day        = tmp_date.getDate();
                    var tmp_month      = tmp_date.getMonth();
                    var tmp_year       = tmp_date.getFullYear();
                    to_date        = ((tmp_month+1) + "/" + tmp_day + "/" + curr_year) + ' 23:59:00';

                    filters["filterFieldArr"].push("date_created");
                    filters["filterOperatorArr"].push("<=");
                    filters["filterValueArr"].push(to_date);
                }//end if
            }//end if

          
            console.log(filters);

            this.global_filter = filters;
           

            const data_param = {
                "record_type" : environment.dashboard_counter,
                
                "pageNumber" : this.pageNumber,
                "lastVisibleField" : this.lastVisibleField,
                "lastVisibleVal" : this.lastVisibleVal,
                "previous" : this.previous,
                "pageSize" : val.page_size_placeholder,
                "created_by" : this.global.login_session["id"],
                ...filters
            }//end

            this.dashboardService.filterList(data_param).subscribe((response) => {
                console.log(response);
                if(response["success"]){
                    this.data_list                  = response["data"]["query_data"];
                    this.total_search_result        = response["data"]["total_result"];
                    this.total_result               = response["data"]["total_result"];
                    this.show_total_search_result   = true;
                    this.row_counter                = (this.pageNumber * val.page_size_placeholder) + 1;
                    this.pagination_counter         = this.row_counter + (this.data_list.length - 1);
                    //convert date
                    for(var k in this.data_list){
                        const es = this.data_list[k];
                        if(es["date_created"] !== undefined){
                            var u_date = new Date(es["date_created"]["_seconds"] * 1000 + es["date_created"]["_nanoseconds"]/1000000);
                            this.data_list[k]["date_created_str"] = this.datePipe.transform(u_date, 'MMM dd, yyyy h:mm:ss a');
                        }//end if
                    }//end forthis.datePipe.transform((new Date), 'MMM dd, yyyy h:mm:ss a');
                    //convert date

                }else{

                }//end if
                this.global.loading = false;
            })

        } catch (e : any) {
            this.toastr.error(e["message"],"System Message");
        }//end try

    }


    check(e : any,name){
        const field_to_check = ["filter_weekly","filter_monthly","filter_yearly"];
        var val = this.form.value;
        this.disable_filter = false;
        
        for(const k in this.form.controls){
            if(name == k){
                //---------------------clear date value---------------------
                if(this.form.controls['filter_date_from'].value !== null || this.form.controls['filter_date_to'].value !== null){
                    this.form.controls['filter_date_from'].setValue(null,{emitViewToModelChange : false});
                    this.form.controls['filter_date_to'].setValue(null,{emitViewToModelChange : false});
                    this.filter_date_from['displayValue'] = '';
                    this.filter_date_to['displayValue'] = '';
                }//end if
                //---------------------clear date value---------------------

                continue;
            }//end
            if(field_to_check.includes(k)){
                this.form.controls[k].setValue(false);
            }//end if
        }//end

        /*if(val.filter_date_from){
            for(const k in this.form.controls){
                
                if(field_to_check.includes(k)){
                    this.form.controls[k].setValue(false);
                }//end if
            }//end
        }//end if
        */
    }//end  

   

    prev(){
        const val           = this.form.value;
        if(this.pageNumber > 0){
            this.pageNumber -= 1;
            this.previous = true;
            this.lastVisibleVal         = this.prevPageVisibleVal[this.pageNumber];
            this.refresh_list(val.search_str_placeholder);
        }//end if
        
        
        
    }

    next(){
        const val           = this.form.value;
        const total_pages   = Math.ceil((this.total_result / val.page_size_placeholder));
        
       
        if(total_pages > (this.pageNumber + 1)){
            
            this.pageNumber             += 1;
            this.previous               = false;
            this.lastVisibleVal         = this.data_list[this.data_list.length - 1]["id"];

            if(!this.prevPageVisibleVal.includes(this.data_list[0]["id"])){
                this.prevPageVisibleVal.push(this.data_list[0]["id"]);
            }//end if

            this.refresh_list(val.search_str_placeholder);
            
            
        }//end if
        
    }

    search(){
       
        const val = this.form.value;

        
        
        this.previous                    = false;
        this.lastVisibleField            = "id";
        this.lastVisibleVal              = "";
        this. prevPageVisibleVal         = new Array();
        this.pageNumber                  = 0;
        this.refresh_list(val.search_str_placeholder)
    }
    

    get_date_from(event : any){
        this.disable_filter = false;
        //this.form.controls['filter_date_from'].setValue(event);
        //---------------------------clear checkbox---------------------------
        const val = this.form.value;
        if(val.filter_date_from !== null){
            if(val.filter_weekly){
                this.form.controls['filter_weekly'].setValue(false);
            }else if(val.filter_monthly){
                this.form.controls['filter_monthly'].setValue(false);
            }else{
                this.form.controls['filter_yearly'].setValue(false);
            }//end if
        }//end if
        if(val.filter_date_from !== null && val.filter_date_to !== null){
            if(val.filter_date_to < val.filter_date_from){
                console.log("disable button");
                this.disable_filter = true;
            }
        }//end if
        //---------------------------clear checkbox---------------------------
    }

    get_date_to(event : any){
        this.disable_filter = false;
        //this.form.controls['filter_date_to'].setValue(event);
        //---------------------------clear checkbox---------------------------
        const val = this.form.value;
        if(val.filter_date_to !== null){
            if(val.filter_weekly){
                this.form.controls['filter_weekly'].setValue(false);
            }else if(val.filter_monthly){
                this.form.controls['filter_monthly'].setValue(false);
            }else{
                this.form.controls['filter_yearly'].setValue(false);
            }//end if
        }//end if

        if(val.filter_date_from !== null && val.filter_date_to !== null){
            if(val.filter_date_to < val.filter_date_from){
                console.log("disable button");
                this.disable_filter = true;
            }//end if
        }//end if
        //---------------------------clear checkbox---------------------------
    }

    async download_log(){
        let excel_data = "No.,Search words/names,Total matched results,Searched on\n";
        console.log(this.total_search_result)
        for(const k in this.data_list){
            const map_data = this.data_list[k];
            excel_data += (this.row_counter + parseInt(k))+',';
            excel_data += '"'+map_data["searched_value"]+'"'+',';
            excel_data += map_data["query_result"]+',';
            excel_data += '"'+map_data["date_created_str"]+'"';
            excel_data += "\n";
        }//end if

        var blob = new Blob([excel_data], {type: "text/csv;charset=utf-8"});
        FileSaver.saveAs(blob, "audit_trail.csv");
        
    }

    async download_log_all(){
        this.global.loading = true;
        const val               = this.form.value;
        let excel_data = "No.,Search words/names,Total matched results,Searched on\n";
        console.log(this.total_search_result)
        /*for(const k in this.data_list){
            const map_data = this.data_list[k];
            excel_data += (this.row_counter + parseInt(k))+',';
            excel_data += '"'+map_data["searched_value"]+'"'+',';
            excel_data += map_data["query_result"]+',';
            excel_data += '"'+map_data["date_created_str"]+'"';
            excel_data += "\n";
        }//end if
        */


        //query
        try{
            const data_param = {
                "record_type" : environment.dashboard_counter,
                
                "pageNumber" : this.pageNumber,
                "lastVisibleField" : this.lastVisibleField,
                "lastVisibleVal" : this.lastVisibleVal,
                
                "pageSize" : this.total_search_result,
                "created_by" : this.global.login_session["id"],
                ...this.global_filter
            }//end

            this.dashboardService.filterList(data_param).subscribe((response) => {
                
                if(response["success"]){
                    var download_list               = response["data"]["query_data"];
                    //convert date
                    for(var k in download_list){
                        var es = download_list[k];
                        if(es["date_created"] !== undefined){
                            var u_date = new Date(es["date_created"]["_seconds"] * 1000 + es["date_created"]["_nanoseconds"]/1000000);
                            es["date_created_str"] = this.datePipe.transform(u_date, 'MMM dd, yyyy h:mm:ss a');
                        }//end if

                        excel_data += (parseInt(k) + 1)+',';
                        excel_data += '"'+es["searched_value"]+'"'+',';
                        excel_data += es["query_result"]+',';
                        excel_data += '"'+es["date_created_str"]+'"';
                        excel_data += "\n";

                    }//end forthis.datePipe.transform((new Date), 'MMM dd, yyyy h:mm:ss a');
                    //convert date

                }else{

                }//end if
                this.global.loading = false;

                var BOM = "\uFEFF"; 
                excel_data = BOM + excel_data; //catch russian and arabic

                var blob = new Blob([excel_data], {type: "text/csv;charset=utf-8"});
                FileSaver.saveAs(blob, "audit_trail_all.csv");
            })
        }catch(e : any){
            this.toastr.error(e["message"],"System Message");
        }//end try
        //end query
      
        
    }

}
