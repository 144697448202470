<div class="bg">
    
    <div class="container" >
        <div class="row justify-content-center align-items-center " style="min-height: 100vh;">
            <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6">
                <div class="row justify-content-center py-5">
                    
                    <img src="../../assets/images/uchech logo.svg" class="col-6 img-fluid">
                    
                </div>
                
                <div class="card">
                    <div class="card-body px-5 py-5" *ngIf="!show_change_password">
                        <h2 class="text-center fw-bolder">Password Changed!</h2>
                        <div class="text-center fs-6">
                            Your password has been successfully changed. You
                        </div>
                        <div class="text-center fs-6">
                            may now log in using your new credentials.
                        </div>

                        <div class="d-grid gap-2 mt-5">
                            <a href="login" class="btn btn-login text-white">Back to log in</a>
                        </div>
                    </div>
                    <div class="card-body px-5 py-5" *ngIf="show_change_password">
                        <h2 class="text-center fw-bolder">Reset Password</h2>
                        <div class="text-center fs-6">
                            Please input a new password that<br/>you haven't used before
                        </div>
                       
                        <form class="my-3" [formGroup]="form">
                           

                            <div class="mb-3 d-none">
                                <label for="email" class="form-label"><small class="fw-semibold">ID</small></label>
                                <div class="input-group has-validation">
                                    <input readonly formControlName="id" type="text" class="form-control">
                                </div>
                            </div>

                            <div class="mb-3 d-none">
                                <label for="email" class="form-label"><small class="fw-semibold">Email</small></label>
                                <div class="input-group has-validation">
                                    <input readonly formControlName="username" type="email" class="form-control {{form.get('username').touched && form.get('username').invalid? 'is-invalid' : ''}}">
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="email" class="form-label"><small class="fw-semibold">New Password</small></label>
                                <div class="input-group">
                                    <input style="border-right:none !important;" formControlName="password" type="{{show_password ? 'text' : 'password'}}" class="form-control {{form.get('password').touched && form.get('password').invalid? 'is-invalid' : ''}}">
                                    
                                    <img [ngStyle]="{'border-color' : form.get('password').touched && form.get('password').invalid? '#dc3545' : ''}" class="input-group-text bg-transparent" (click)="s_password('password')" src="{{show_password? '../../assets/images/eye-slash-regular.svg' : '../../assets/images/eye-regular.svg'}}" />

                                   
                                </div>

                                <small class="text-danger" *ngIf="form.get('password').touched && form.get('password').invalid && form.controls['password'].errors?.['required']">
                                    {{field_label['password']}} is required
                                </small>

                                <small class="text-danger" *ngIf="form.get('password').touched && form.get('password').invalid && form.controls['password'].errors?.['minlength']">
                                    {{field_label['password']}} must have 6 minimum characters
                                </small>

                                
                            </div>

                            <div class="mb-3">
                                <label for="email" class="form-label"><small class="fw-semibold">Confirm Password</small></label>
                                <div class="input-group">
                                    <input style="border-right:none !important;" formControlName="confirm_password" type="{{show_password2 ? 'text' : 'password'}}" class="form-control {{form.get('confirm_password').touched && form.get('confirm_password').invalid? 'is-invalid' : ''}}">
                                    <img [ngStyle]="{'border-color' : form.get('confirm_password').touched && form.get('confirm_password').invalid? '#dc3545' : ''}" class="input-group-text bg-transparent" (click)="s_password('password2')" src="{{show_password2? '../../assets/images/eye-slash-regular.svg' : '../../assets/images/eye-regular.svg'}}" />
                                
                                </div>

                                <small class="text-danger" *ngIf="form.get('confirm_password').touched && form.get('confirm_password').invalid && form.controls['confirm_password'].errors?.['required']">
                                    {{field_label['confirm_password']}} is required
                                </small>

                                <small class="text-danger" *ngIf="form.get('confirm_password').touched && form.get('confirm_password').invalid && form.controls['confirm_password'].errors?.['minlength']">
                                    {{field_label['confirm_password']}} must have 6 minimum characters
                                </small>

                                <small class="text-danger" *ngIf="form.get('confirm_password').touched && form.get('confirm_password').invalid && form.controls['confirm_password'].errors?.['password_match']">
                                    {{field_label['confirm_password']}} do not match.
                                </small>
                            </div>

                            <div class="mb-3 d-none">
                                <label for="email" class="form-label"><small class="fw-semibold">Password</small></label>
                                <div class="input-group has-validation">
                                    <input readonly formControlName="password_placeholder" type="password" class="form-control">
                                </div>
                            </div>
                            
                        </form>

                        <div class="d-grid gap-2">
                            <button class="btn btn-login text-white" type="button" (click)="reset_password()">Reset Password</button>
                            <a [routerLink]="['/login']" class="btn btn-outline-login" >Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>