import { Component, Directive, Input, OnInit, ViewChild } from '@angular/core';
import { LoginComponent } from 'src/app/login/login.component';
import { Globals } from 'src/app/global/global-variable';
import { LoginService } from 'src/app/services/login-service';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { UserManagementService } from 'src/app/services/user-management-service';

@Directive({selector: 'div'})
export class Div {
    @Input() id!: string;
}

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  @ViewChild(Div) div;
  @ViewChild(LoginComponent) loginComp;

  selectedItem = 'dashboard';
  constructor(
    public global : Globals,
    private loginService : LoginService,
    private router : Router,
    private toastr : ToastrService,
    private userManagementService : UserManagementService,
  ) { }
  ngOnInit(): void {
    
   

    if(this.router.url.includes('dashboard')){
        this.global.title = 'Dashboard';
        this.selectedItem = 'dashboard';
    }else if(this.router.url.includes('search')){
        this.global.title = 'Search';
        this.selectedItem = 'search';
    }else if(this.router.url.includes('faq')){
        this.global.title = 'FAQ';
        this.selectedItem = 'faq';
    }else if(this.router.url.includes('support')){
        this.global.title = 'Support';
        this.selectedItem = 'support';
    }else if(this.router.url.includes('account')){
        this.global.title = 'Account';
        this.selectedItem = 'account';
    }else if(this.router.url.includes('audit_trail')){
        this.global.title = 'Audit Trail';
        this.selectedItem = 'audit_trail';
    }else if(this.router.url.includes('user_management')){
        this.global.title = 'Manage users';
        this.selectedItem = 'user_management';
    }else if(this.router.url.includes('settings')){
        this.global.title = 'Settings';
        this.selectedItem = 'settings';
    }else if(this.router.url.includes('audit_log')){
        this.global.title = 'Audit Log';
        this.selectedItem = 'audit_log';
    }else if(this.router.url.includes('generated_manifest')){
        this.global.title = 'Reports';
        this.selectedItem = 'generated_manifest';
    }//end if

    //this.global.title = className;

  }
  
    logout(){
        this.global.loading = true;
        try {
            this.loginService.signOut().then((response) => {
                
                this.global.loading = false;
                if(response.success){
                    //update login
                    /*const request_param = {
                        "records" : [{
                            "record_type"   : environment.user_management,
                            "action" : "update",
                            "id"  : this.global.login_session["id"],
                            "logged_in" : false
                        }]
                    };
                    const api = await lastValueFrom(this.userManagementService.save_data(request_param));
                    */
                    //ennd update login

                    this.global.clearSession();
                    this.router.navigate(['/login']);
                }else{
                    const errObj = {
                        code : response.data["code"],
                        message : response.data["message"],
                    }//end 
                    this.toastr.error(response.data["message"],"System Message");
                    //throw errObj;
                    
                }//end if
            })
        } catch (e : any) {
            this.toastr.error(e.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end 
        
    }//end logout

    toggleClass(event: any, className: string) {
      
        this.global.title = className;
        if(className.toLowerCase().includes('dashboard')){
            this.selectedItem = 'dashboard';
        }else if(className.toLowerCase().includes('search')){
            this.selectedItem = 'search';
        }else if(className.toLowerCase().includes('faq')){
            this.selectedItem = 'faq';
        }else if(className.toLowerCase().includes('support')){
            this.selectedItem = 'support';
        }else if(className.toLowerCase().includes('account')){
            this.selectedItem = 'account';
        }else if(className.toLowerCase().includes('audit trail')){
            this.selectedItem = 'audit_trail';
        }else if(className.toLowerCase().includes('manage users')){
            this.selectedItem = 'user_management';
        }else if(className.toLowerCase().includes('settings')){
            this.selectedItem = 'settings';
        }else if(className.toLowerCase().includes('audit log')){
            this.selectedItem = 'audit_log';
        }else if(className.toLowerCase().includes('reports')){
            this.selectedItem = 'generated_manifest';
        }//end if
       
        
    }
}
