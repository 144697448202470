import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './account/account.component';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FaqComponent } from './faq/faq.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { SearchVwComponent } from './search-vw/search-vw.component';
import { SearchComponent } from './search/search.component';
import { SettingsComponent } from './settings/settings.component';
import { SupportComponent } from './support/support.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { SearchManifestComponent } from './search-manifest/search-manifest.component';
import { GeneratedManifestComponent } from './generated-manifest/generated-manifest.component';
import { BulkSearchComponent } from './bulk-search/bulk-search.component';

import { BulkSearchVwComponent } from './bulk-search-vw/bulk-search-vw.component';
import { BulkSearchVwSearchComponent } from './bulk-search-vw/bulk-search-vw-search/bulk-search-vw-search.component';
import { SearchPepVwComponent } from './search-pep-vw/search-pep-vw.component';
import { SearchWikiVwComponent } from './search-wiki-vw/search-wiki-vw.component';

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: "login", component: LoginComponent },
    { path: "forgot-password", component: ForgotPasswordComponent },
    { path: "change-password/:id", component: NewPasswordComponent },
    //{ path: "search-result/:search_type/:id", component: SearchManifestComponent },
    { path: "search-result/:module_type/:search_type/:id", component: SearchManifestComponent },
    { path: "search-result/:module_type/:search_type/:id/:page", component: SearchManifestComponent },
    
    
    //{ path: "search", component: SearchComponent },
    //{ path: "search/:id", component: SearchVwComponent },
    { path: "search/:search_type/:id/:view_type/:isIncludeAdverse", component: SearchVwComponent },
    { path: "search/:search_type/:id/:view_type/:isIncludeAdverse/:adverse_topic", component: SearchVwComponent },
    { path: "search-pep/:search_type/:id", component: SearchPepVwComponent },
    { path: "search-wiki/:search_type/:id", component: SearchWikiVwComponent },
    { path: "search-wiki/:search_type/:id/:isIncludeAdverse", component: SearchWikiVwComponent },
    { path: "search-wiki/:search_type/:id/:isIncludeAdverse/:adverse_topic", component: SearchWikiVwComponent },
    // { path: "user-invite-validate/:id/:team_id/:idToken", component: UserInviteValidateComponent },
    // { path: "checkout/:plan", component: CheckoutComponent },
    // { path: "multifactor", component: MultifactorComponent },
    // { path: "reauthenticate/:id", component: ReauthenticateComponent },
    //{ path: "maya_verification/:status/:rrn", component: MayaVerificationComponent },
    // { path: "verification/:status", component: MayaVerificationComponent },
    

    { path: "main", component:  LayoutComponent,
        children : [
            { path: "dashboard", component: DashboardComponent },
            { path: "search", component: SearchComponent },
            { path: "search/:id", component: SearchVwComponent },
            { path: "settings", component: SettingsComponent },
            { path: "account", component: AccountComponent },
            { path: "user_management", component: UserManagementComponent },
            { path: "faq", component: FaqComponent },
            { path: "support", component: SupportComponent },
            { path: "audit_trail", component: AuditTrailComponent },
            { path: "audit_log", component: AuditLogComponent },
            { path: "generated_manifest", component: GeneratedManifestComponent },
            { path: "bulk-search", component: BulkSearchComponent },
            { path: "bulk-search-vw/:type/:id", component: BulkSearchVwComponent },
            { path: "bulk-search-vw/search/:type/:id", component: BulkSearchVwSearchComponent },
            // { path: "bulk-search-export", component: BulkSearchExportComponent },
            // { path: "manage-groups", component: ManageGroupsComponent },
            // { path: "assign_trial_subscription", component: AssignTrialSubscriptionComponent },
        ]
    }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
