<div class="container-fluid">
    <div class="row mx-3">
        <div class="col">
            <div class="card py-2 shadow">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-auto">
                            <h5 class="fw-bolder">Bulk Search</h5>
                        </div>
                        <div class="col-xxl-2 col-xl-2 col-lg-2 col-auto">
                            <div class="d-grid gap-2">
                                <button type="button" (click)="submit()" [disabled]="isDisabled" class="btn btn-primary font-700" >Search</button>
                            </div>
                        </div>
                        <div class="col col-auto">
                        </div>
                    </div>
                    <hr/>
                    <form [formGroup]="form">
                        <div class="row align-items-center gx-2">

                            <label class="form-label">
                                <small class="text-dashboard-muted">List Reference</small>
                            </label>
                            <input (change)="upload($event)" formControlName="file" accept=".docx, .doc, .xlsx, .xls, .csv" type="file" id="upload-id" class="custom-file-bulk {{form.get('file').touched && form.get('file').invalid && form.controls['file'].errors?.['invalid_file']? 'custom-file-bulk-invalid' : ''}}" />
                                        
                        </div>
                        <div class="row align-items-center" *ngIf="totalFileSize > 0">
                            <div class="col-auto">
                                <small class="text-danger" *ngIf="form.get('file').touched && form.get('file').invalid && form.controls['file'].errors?.['exceed_file_size']">
                                    {{field_label['file']}} max size exceeded!
                                </small>

                                <small class="text-danger" *ngIf="form.get('file').touched && form.get('file').invalid && form.controls['file'].errors?.['invalid_file']">
                                    Invalid file type. Please upload a CSV file.
                                </small>
                            </div>
                        </div>
                        <div class="row align-items-center" *ngIf="totalFileSize > 0">
                            <div class="col-auto">
                                <small class="fw-bold">Total Size : {{ commonService.catchIsNaN(totalFileSize,2)}} MB</small>
                            </div>
                        </div>
                        
                    </form>

                    <form>
                        <div class="row mx-3 mb-3 mt-3 justify-content-end align-items-start">
                
                            <div class="col-auto d-none">
                                <div class="form-check form-check-inline">
                                    <!-- <a target="_blank" class="ms-3 btn btn-sm btn-primary text-white" data-bs-toggle="modal" data-bs-target="#confirmation_modal">Generate report</a> -->
                                    <input class="form-check-input" type="checkbox">
                                    <!-- data-bs-toggle="modal" data-bs-target="#confirmation_modal" -->
                                    <label class="form-check-label" for="">
                                        Include Consolidated PEP List
                                    </label>
                                </div>
                            </div>
                
                            <div class="col-auto d-none">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="">
                                        Include Adverse Media
                                    </label>
                                </div>
                            </div>
                
                            <div class="col-auto d-none">
                                <div class="form-check form-check-inline">
                                    <a tabindex="0" onmouseover="enablePopover()" role="button" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-placement="bottom" data-bs-content="Consolidating PEP List and Adverse Media are paid service. Additional charges may apply.">
                                        <div class="info-img"></div>
                                    </a>
                                </div>
                            </div>

                            <div class="col-auto">
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label" for="">
                                        Download:
                                        <a href="assets/template/UCheck Bulk Search Template 1.csv" download="bulk_search_template">Bulk Search Template</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                    
                </div>
            </div>
        </div>
    </div>
</div>
           
<div class="container-fluid mt-5">
    <div class="row justify-content-center mx-3">
        <div class="col-12">
            
            <p class="font-14 fw-bold">Recent Upload(s)</p>
            <div class="shadow bg-white rounded" *ngIf="total_result > 0">
                <div class="table-responsive" style="max-height:500px;">
                    <table class="table align-middle" style="min-width:2in;">
                        <thead>
                            <tr>
                                <th class="text-muted text-left px-3">File Name</th>
                                <th class="text-muted text-left px-3">Name of Uploader</th>
                                <th class="text-muted text-center px-3">No. of Records</th>
                                <th class="text-muted text-center px-3">No. of Hits</th>
                                <th class="text-muted text-left px-3">Date Uploaded</th>
                                <th class="text-center text-center px-3">CSV</th>
                                <th class="text-center text-center px-3">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of table_list; let x = index">
                                
                                <td class="text-left px-3">{{data.file_name}}</td>
                                <td class="text-left px-3">{{data.name}}</td>
                                <td class="text-center px-3">{{commonService.toCurrency_precision((data["no_of_records"] === undefined? 0 : data.no_of_records),0)}}</td>
                                <td class="text-center px-3">{{commonService.toCurrency_precision((data["no_of_hits"] === undefined? 0 : data.no_of_hits),0)}}</td>
                                <td class="text-left px-3">{{data.date_created_str}}</td>
                                <td class="text-center px-3">
                                    <a *ngIf="data['manifest_link'] !== undefined && data['manifest_link'] !== ''" href="{{data['manifest_link']}}" target="_blank" download>
                                        <img class="img-fluid" style="width:10.11px;height:12.64px;" src="../../assets/images/download_icon.png"/>
                                    </a>
                                </td>
                                <td class="text-center px-3">
                                    <a class="btn btn-sm btn-primary" href="main/bulk-search-vw/{{data['id']}}">View</a>
                                </td>
                            </tr>
                        </tbody>
                        
                        
                    </table>
                </div>
                

                <div class="row container-fluid justify-content-end align-items-center mt-4">
                    <div class="col-auto">
                        <form [formGroup]="form" *ngIf="show_total_search_result">

                            <div class="row mb-3 align-items-center">
                                <label for="inputPassword" class="col-auto col-form-label font-14">
                                    Rows per page
                                </label>
                                <div class="col">
                                    <select style="border-style:none !important;" (change)="search()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                        <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="col-auto">
                        <div class="mb-3 row align-items-center">
                            <div class="col-auto">
                                {{row_counter}} - {{pagination_counter}} of {{total_search_result}}
                            </div>
                        </div>
                        
                    </div>
                    
                    <div class="col-auto">
                        <nav aria-label="Page navigation example" *ngIf="show_total_search_result">
                            <ul class="pagination border-opacity-10">
                                <li class="page-item " (click)="prev()" >
                                    <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                        <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                    </a>
                                </li>
                                
                                <li class="page-item" (click)="next()">
                                    <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                        <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    

                    
                </div>
            </div>


            <div class="card border-0 shadow" *ngIf="total_result == 0">
                <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">

                    <div class="col-auto">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-auto">
                                <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                            </div>
                            <div class="col-12">
                                <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
            
    </div>
</div>

           
           
        