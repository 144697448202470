import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SearchService } from '../services/search-service';
import { CommonService } from '../library/pcl_lib';
import { Globals } from '../global/global-variable';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-search-pep-vw',
  templateUrl: './search-pep-vw.component.html',
  styleUrls: ['./search-pep-vw.component.scss']
})
export class SearchPepVwComponent {
    public year_today;
    public date_today;
    public app_version;

    pageNumber                  = 0;
    row_counter                 = 1;
    pageSize                    = [1,5,10];
    pagination_counter          = 0;
    form = this.fb.group({
        page_size_placeholder : [10],
        sort_by : ["date:d"]
     
    });

    adverse_list                = {};
    adverse_list_item           = new Array();
    data_list                   = new Array();
    loading                     = false;
    show_total_search_result    = false;
    total_search_result         = 0;
    snapshot_param              = {}; //snapshot parameter
    constructor(
        private searchService : SearchService,
        private _activatedRouter : ActivatedRoute,
        public commonService : CommonService,
        public global : Globals,
        private datePipe : DatePipe,
        private fb : FormBuilder
    ){
        this.snapshot_param = this._activatedRouter.snapshot.params;
        this.date_today = new Date()
        this.year_today = this.date_today.getFullYear();
        this.app_version = environment.app_version; 
    }//end constructor

    async ngOnInit(){
        
        this.global.loading    = true;
        //--------------------------------------------load data--------------------------------------------
        console.log(this.snapshot_param["id"]);
        const data_api = await lastValueFrom(this.searchService.view_open_api_search(this.snapshot_param["id"]));
        
        if(data_api["success"]){
            this.data_list = data_api["data"];
            this.load_adverse();
            for(const x in this.data_list){
                switch (x) {
                    case "last_change" :
                        var prop = this.data_list[x];
                        this.data_list['last_change_parsed'] = prop;
                        if(prop.length >= 10){
                            this.data_list['last_change_parsed'] =  this.datePipe.transform((prop), 'MMM dd, yyyy');
                        }//end if
                        break;
                    case "properties" :
                        var prop = this.data_list[x];
                       
                        if(prop['birthDate'] !== undefined){
                            var to_parse_data = prop['birthDate'];
                            this.data_list['properties']['birthDateParsed'] = to_parse_data;
                            for(const y in to_parse_data){
                                if(to_parse_data[y] !== undefined && to_parse_data[y] !== ""){
                                    //to_parse_data[y]["DATE"] =  this.datePipe.transform((to_parse_data[y]["DATE"]), 'MMM dd, yyyy');
                                    if(to_parse_data[y].length >= 10){
                                        to_parse_data[y] =  this.datePipe.transform((to_parse_data[y]), 'MMM dd, yyyy');
                                    }//end if
                                    
                                }//end if
                            }//end for
                            this.data_list['properties']['birthDateParsed'] = to_parse_data;
                        }//end for

                        if(prop['modifiedAt'] !== undefined){
                            var to_parse_data = prop['modifiedAt'];
                            this.data_list['properties']['modifiedAtParsed'] = to_parse_data;
                            for(const y in to_parse_data){
                                if(to_parse_data[y] !== undefined && to_parse_data[y] !== ""){
                                    //to_parse_data[y]["DATE"] =  this.datePipe.transform((to_parse_data[y]["DATE"]), 'MMM dd, yyyy');
                                    if(to_parse_data[y].length >= 10){
                                        to_parse_data[y] =  this.datePipe.transform((to_parse_data[y]), 'MMM dd, yyyy');
                                    }//end if
                                    
                                }//end if
                            }//end for
                            this.data_list['properties']['modifiedAtParsed'] = to_parse_data;
                        }//end if
                        
                        
                        
                        this.data_list[x] = prop;
                    break;
                }//end switch
            }//end for
            console.log(this.data_list); 
        }//end
        //--------------------------------------------load data--------------------------------------------
        //end load data
        this.global.loading    = false;
        
    }//end ngOnInit


    refresh_list(search_str){
           

            let filters = {};
            if(search_str.trim() !== ""){
                filters["filterFieldArr"]       = new Array();
                filters["filterOperatorArr"]    = new Array();
                filters["filterValueArr"]       = new Array();
                filters["filterFieldArr"].push("ID");
                filters["filterOperatorArr"].push("==");
                filters["filterValueArr"].push(search_str.trim());
            }//end if

            var record_type = "";
            if(this.snapshot_param["search_type"] == 'individual'){
                record_type = environment.individual;
            }else{
                record_type = environment.entity;
            }//end if

           
            
            const data_param = {
                "record_type" : record_type,
                "pageSize" : 1000,
                ...filters
            }//end

            return this.searchService.filterList(data_param);
            /*
           await table_data.subscribe((result) => {
                console.log(result);
               if(result["success"]){
                    if(result["total_result"] !== undefined && result["total_result"] > 0){
                        this.data_list = result["data"];
                        this.total_search_result        = result["total_result"];
                        this.show_total_search_result   = true;
                    }else{
                        this.data_list                  = [];
                        this.total_search_result        = 0;
                        this.show_total_search_result   = true;
                    }//end if

                    
               }//end if
               
               
            });
            */
       
    }//end if

    getTableList$(input_data) {
        return this.searchService.filterList(input_data);
    }//end getTableList
    
    go_back(){
        window.close()
    }

    selected_event : any;
    chevron_image = "../../assets/images/chevron-down.svg"

    hide_link(event : any,id){
        var target = event.target || event.srcElement || event.currentTarget;
        //document.getElementsByClassName(id)[0].children;
        var el = document.getElementById(id);
        this.selected_event = el;

        setTimeout(() => {
            if(this.selected_event.querySelector("img").src.indexOf('images/chevron-down.svg') >= 0){
                this.selected_event.querySelector("img").src = '../../assets/images/chevron-up.svg';
            }else{
                this.selected_event.querySelector("img").src = '../../assets/images/chevron-down.svg';
            }//end if
        }, 500)
        
        

       
    }

    load_adverse(){
        console.log(this.data_list["caption"]);
        this.global.loading    = true;
        var full_name = (this.data_list !== undefined? this.data_list["caption"] : "");
        const val = this.form.value;
        
        if(this.adverse_list["searchInformation"] === undefined){

        
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", environment.google_custom_search_key);
            const raw = JSON.stringify({
                "name": full_name,
                "num": val.page_size_placeholder,
                "start": this.row_counter,
                "sort" : val.sort_by
            });
            console.log('raw',raw);
            var requestOptions : any; 
            requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(environment.google_custom_search, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    this.adverse_list = JSON.parse(result);
                    this.show_total_search_result = true;
                    if(this.adverse_list["data"]["searchInformation"]["totalResults"] > 0){
                        
                        //console.log(this.adverse_list["data"]["searchInformation"]["totalResults"])
                        this.adverse_list_item = this.adverse_list["data"]["items"];
                        this.total_search_result  = parseInt(this.adverse_list["data"]["searchInformation"]["totalResults"]);
                        this.row_counter = (this.pageNumber * val.page_size_placeholder) + 1;
                        this.pagination_counter= this.row_counter + (this.adverse_list_item.length - 1);
                    }else{
                        this.adverse_list_item = new Array();
                        this.total_search_result  = parseInt(this.adverse_list["data"]["searchInformation"]["totalResults"]);
                        this.row_counter = 1;
                        this.pagination_counter= this.row_counter + (this.adverse_list_item.length - 1);
                    }//end if

                    this.global.loading = false;

                })
                .catch((error) => console.error(error));
        }
    }//


    
        
        
        prev(){
            const val           = this.form.value;
            if(this.pageNumber > 0){
                this.pageNumber -= 1;
                this.row_counter = (this.pageNumber * val.page_size_placeholder) + 1;
                this.pagination_counter= this.row_counter + (this.adverse_list_item.length - 1);
                this.load_adverse();
            }//end if
            
            
            
        }
    
        next(){
            const val           = this.form.value;
            const total_pages   = Math.ceil((this.total_search_result / val.page_size_placeholder));
            
           
            if(total_pages > (this.pageNumber + 1)){
                
                this.pageNumber             += 1;
               
                this.row_counter = (this.pageNumber * val.page_size_placeholder) + 1;
                this.pagination_counter= this.row_counter + (this.adverse_list_item.length - 1);
                this.load_adverse();
            }//end if
        }
    }
