import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../library/pcl_lib';
import { Globals } from '../global/global-variable';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { environment } from 'src/environments/environment';
import { BulkSearchService } from '../services/bulk-search-service';
import { firstValueFrom, lastValueFrom, shareReplay, throwError } from 'rxjs';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-bulk-search',
  templateUrl: './bulk-search.component.html',
  styleUrls: ['./bulk-search.component.scss']
})
export class BulkSearchComponent {
    totalFileSize = 0;
    isDisabled = false;
    form = this.fb.group({
        file : [null,Validators.required],
        file_placeholder : [null],
        page_size_placeholder : [10],
        page_size_placeholder2 : [10],
        page_size_placeholder3 : [10]
    });

    field_label = {
        file : "File",
    }

    //for pagination
    previous                    = false;
    lastVisibleField            = "id";
    lastVisibleVal              = "";
    prevPageVisibleVal          = new Array();
    pageNumber                  = 0;
    row_counter                 = 0;
    pagination_counter          = 0;
    pageSize                    = [10,20,50,100];


    //
    table_list = new Array();
    show_total_search_result    = false;
    total_search_result         = 0;
    total_result                = 0;

    //----------------------------pep----------------------------
    previous2 = false;
    prevPageVisibleVal2 = new Array();
    lastVisibleVal2 = "";
    table_list2 = new Array();
    total_search_result2 = 0;
    total_result2 = 0;
    pageNumber2 = 0;
    row_counter2 = 0;
    pagination_counter2 = 0;
    pageSize2 = [10,20,50,100];
    //----------------------------pep----------------------------

    //--------------------------adverse--------------------------
    previous3 = false;
    prevPageVisibleVal3 = new Array();
    lastVisibleVal3 = "";
    table_list3 = new Array();
    total_search_result3 = 0;
    total_result3 = 0;
    pageNumber3 = 0;
    row_counter3 = 0;
    pagination_counter3 = 0;
    pageSize3 = [10,20,50,100];
    //--------------------------adverse--------------------------
    

    selected_tab = "sanction-tab";
    search_type = 'Sanction';
    constructor(
        private fb: FormBuilder,
        private toastr : ToastrService,
        private global : Globals,
        public commonService : CommonService,
        private ngxCsvParser: NgxCsvParser,
        private bulkSearchService :BulkSearchService,
        private datePipe : DatePipe
    ) {

    }//end constructor

    ngOnInit(){
        //check permission
        let w_access     = false;
        const permission = this.global.login_session["permission"]["bulk_search"];
        for(const k in permission){
            if(permission[k] == 'view'){
                w_access = true;
            }//end if

        
        }//end for

        if(this.global.login_session["team_id"] === undefined && this.global.login_session["user_type"] !== "super_admin"){
            w_access = false;
        }//

        if(!w_access){
            window.location.replace('/main/dashboard');
        }//end if
        //end check permission

        try {
            this.global.loading = true;
            let request_arr = new Array();
            const val = this.form.value;
            request_arr.push(lastValueFrom(this.refresh_list_promise()));
            request_arr.push(lastValueFrom(this.wikidata_list_promise()));
            request_arr.push(lastValueFrom(this.adverse_list_promise()));
            Promise.allSettled(request_arr).then((r) => {
                r.forEach((result, index) => {
                    
                    if (result.status === 'fulfilled') {
                        if(index == 0){
                            //----------------------sanction----------------------
                            const res = (result.value);
                            if(res["success"]){
                                this.table_list                 = res["data"]["query_data"];
                                this.total_search_result        = res["data"]["total_result"];
                                this.total_result               = res["data"]["total_result"];
                                this.show_total_search_result   = true;
                                this.row_counter                = (this.pageNumber * val.page_size_placeholder) + 1;
                                this.pagination_counter         = this.row_counter + (this.table_list.length - 1);
                                
                                //convert date
                                for(var k in this.table_list){
                                    const es = this.table_list[k];
                                    if(es["date_created"] !== undefined){
                                        var u_date = new Date(es["date_created"]["_seconds"] * 1000 + es["date_created"]["_nanoseconds"]/1000000);
                                        this.table_list[k]["date_created_str"] = this.datePipe.transform(u_date, 'MMM dd, yyyy h:mm:ss a');
                                    }//end if

                                    this.table_list[k]["name"] = this.table_list[k]["profile"]["first_name"]+ " " + this.table_list[k]["profile"]["last_name"];
                                }//end forthis.datePipe.transform((new Date), 'MMM dd, yyyy h:mm:ss a');
                                //convert date
                            }else{
                                const errorObj = {
                                    code : "Firebase_Error",
                                    message : JSON.stringify(res["data"])
                                }//end
            
                                throw errorObj;
                            }//end if
                            
                            //----------------------sanction----------------------
                        }else if(index == 1){
                            //----------------------pep----------------------
                            const res = (result.value);
                            console.log('pep',res);
                            if(res["success"]){
                                this.table_list2                 = res["data"]["query_data"];
                                this.total_search_result2        = res["data"]["total_result"];
                                this.total_result2               = res["data"]["total_result"];
                                this.row_counter2                = (this.pageNumber2 * val.page_size_placeholder2) + 1;
                                this.pagination_counter2         = this.row_counter2 + (this.table_list2.length - 1);
                                
                                //convert date
                                for(var k in this.table_list2){
                                    const es = this.table_list2[k];
                                    if(es["date_created"] !== undefined){
                                        var u_date = new Date(es["date_created"]["_seconds"] * 1000 + es["date_created"]["_nanoseconds"]/1000000);
                                        this.table_list2[k]["date_created_str"] = this.datePipe.transform(u_date, 'MMM dd, yyyy h:mm:ss a');
                                    }//end if

                                    this.table_list2[k]["name"] = this.table_list2[k]["profile"]["first_name"]+ " " + this.table_list2[k]["profile"]["last_name"];
                                }//end forthis.datePipe.transform((new Date), 'MMM dd, yyyy h:mm:ss a');
                                //convert date
                            }else{
                                const errorObj = {
                                    code : "Firebase_Error",
                                    message : JSON.stringify(res["data"])
                                }//end
            
                                throw errorObj;
                            }//end if
                            //----------------------pep----------------------
                        }else if(index == 2){
                            //----------------------adverse----------------------
                            const res = (result.value);
                            if(res["success"]){
                                this.table_list3                 = res["data"]["query_data"];
                                this.total_search_result3        = res["data"]["total_result"];
                                this.total_result3               = res["data"]["total_result"];
                                this.row_counter3                = (this.pageNumber3 * val.page_size_placeholder3) + 1;
                                this.pagination_counter3         = this.row_counter3 + (this.table_list3.length - 1);
                                
                                //convert date
                                for(var k in this.table_list3){
                                    const es = this.table_list3[k];
                                    if(es["date_created"] !== undefined){
                                        var u_date = new Date(es["date_created"]["_seconds"] * 1000 + es["date_created"]["_nanoseconds"]/1000000);
                                        this.table_list3[k]["date_created_str"] = this.datePipe.transform(u_date, 'MMM dd, yyyy h:mm:ss a');
                                    }//end if

                                    this.table_list3[k]["name"] = this.table_list3[k]["profile"]["first_name"]+ " " + this.table_list3[k]["profile"]["last_name"];
                                }//end forthis.datePipe.transform((new Date), 'MMM dd, yyyy h:mm:ss a');
                                //convert date
                            }else{
                                const errorObj = {
                                    code : "Firebase_Error",
                                    message : JSON.stringify(res["data"])
                                }//end
            
                                throw errorObj;
                            }//end if
                            //----------------------adverse----------------------
                        }//end if
                    }//end if
                })//end foreach
                this.global.loading = false;
            })
        }catch (error : any) {
            this.toastr.error(error.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end try

        //this.refresh_list();
    }

    refresh_list_promise(){
        const val = this.form.value;
        //filter
        let filters         = {};
        filters["filterFieldArr"]       = new Array();
        filters["filterOperatorArr"]    = new Array();
        filters["filterValueArr"]       = new Array();
        filters["orderByFieldArr"]      = new Array();
        filters["sortOrderArr"]         = new Array();


        filters["filterFieldArr"].push("created_by");
        filters["filterOperatorArr"].push("==");
        filters["filterValueArr"].push(this.global.login_session["id"]);


        filters["orderByFieldArr"].push("date_created");
        filters["orderByFieldArr"].push("id");

        filters["sortOrderArr"].push("desc");
        filters["sortOrderArr"].push("asc");
        
        const data_param = {
            "record_type" : environment.bulk_search,
            "pageNumber" : this.pageNumber,
            "lastVisibleField" : this.lastVisibleField,
            "lastVisibleVal" : this.lastVisibleVal,
            "previous" : this.previous,
            "pageSize" : val.page_size_placeholder,
            ...filters
        }//end
        return this.bulkSearchService.filterList(data_param).pipe(
            shareReplay(1)
        );
    };

    refresh_list(enable_loading : boolean = true){
        if(enable_loading){
            this.global.loading = true;
        }//end if
        const val = this.form.value;
        //filter
        let filters         = {};
        filters["filterFieldArr"]       = new Array();
        filters["filterOperatorArr"]    = new Array();
        filters["filterValueArr"]       = new Array();
        filters["orderByFieldArr"]      = new Array();
        filters["sortOrderArr"]         = new Array();


        filters["filterFieldArr"].push("created_by");
        filters["filterOperatorArr"].push("==");
        filters["filterValueArr"].push(this.global.login_session["id"]);


        filters["orderByFieldArr"].push("date_created");
        filters["orderByFieldArr"].push("id");

        filters["sortOrderArr"].push("desc");
        filters["sortOrderArr"].push("asc");
        
        const data_param = {
            "record_type" : environment.bulk_search,
            "pageNumber" : this.pageNumber,
            "lastVisibleField" : this.lastVisibleField,
            "lastVisibleVal" : this.lastVisibleVal,
            "previous" : this.previous,
            "pageSize" : val.page_size_placeholder,
            ...filters
        }//end
        this.bulkSearchService.filterList(data_param).subscribe((res) => {
            if(res["success"]){
                this.table_list                 = res["data"]["query_data"];
                this.total_search_result        = res["data"]["total_result"];
                this.total_result               = res["data"]["total_result"];
                this.show_total_search_result   = true;
                this.row_counter                = (this.pageNumber * val.page_size_placeholder) + 1;
                this.pagination_counter         = this.row_counter + (this.table_list.length - 1);
                
                //convert date
                for(var k in this.table_list){
                    const es = this.table_list[k];
                    if(es["date_created"] !== undefined){
                        var u_date = new Date(es["date_created"]["_seconds"] * 1000 + es["date_created"]["_nanoseconds"]/1000000);
                        this.table_list[k]["date_created_str"] = this.datePipe.transform(u_date, 'MMM dd, yyyy h:mm:ss a');
                    }//end if

                    this.table_list[k]["name"] = this.table_list[k]["profile"]["first_name"]+ " " + this.table_list[k]["profile"]["last_name"];
                }//end forthis.datePipe.transform((new Date), 'MMM dd, yyyy h:mm:ss a');
                //convert date
            }else{
                this.toastr.error(res["data"]["error"]["message"],"System Message");
            }//end if
            if(enable_loading){
                this.global.loading = false;
            }//end if
        })
    };


    wikidata_list_promise(){
        const val = this.form.value;
        //filter
        let filters         = {};
        filters["filterFieldArr"]       = new Array();
        filters["filterOperatorArr"]    = new Array();
        filters["filterValueArr"]       = new Array();
        filters["orderByFieldArr"]      = new Array();
        filters["sortOrderArr"]         = new Array();


        filters["filterFieldArr"].push("created_by");
        filters["filterOperatorArr"].push("==");
        filters["filterValueArr"].push(this.global.login_session["id"]);


        filters["orderByFieldArr"].push("date_created");
        filters["orderByFieldArr"].push("id");

        filters["sortOrderArr"].push("desc");
        filters["sortOrderArr"].push("asc");
        
        const data_param = {
            "record_type" : environment.bulk_counter_wiki,
            "pageNumber" : this.pageNumber2,
            "lastVisibleField" : this.lastVisibleField,
            "lastVisibleVal" : this.lastVisibleVal2,
            "previous" : this.previous2,
            "pageSize" : val.page_size_placeholder2,
            ...filters
        }//end
        return this.bulkSearchService.filterList(data_param).pipe(
            shareReplay(1)
        );
    };
    



    wikidata_list(enable_loading : boolean = true){
        if(enable_loading){
            this.global.loading = true;
        }//end if
        const val = this.form.value;
        //filter
        let filters         = {};
        filters["filterFieldArr"]       = new Array();
        filters["filterOperatorArr"]    = new Array();
        filters["filterValueArr"]       = new Array();
        filters["orderByFieldArr"]      = new Array();
        filters["sortOrderArr"]         = new Array();
        filters["filterFieldArr"].push("created_by");
        filters["filterOperatorArr"].push("==");
        filters["filterValueArr"].push(this.global.login_session["id"]);


        filters["orderByFieldArr"].push("date_created");
        filters["orderByFieldArr"].push("id");

        filters["sortOrderArr"].push("desc");
        filters["sortOrderArr"].push("asc");
        
        const data_param = {
            "record_type" : environment.bulk_counter_wiki,
            "pageNumber" : this.pageNumber2,
            "lastVisibleField" : this.lastVisibleField,
            "lastVisibleVal" : this.lastVisibleVal2,
            "previous" : this.previous2,
            "pageSize" : val.page_size_placeholder2,
            ...filters
        }//end
        this.bulkSearchService.filterList(data_param).subscribe((res) => {
            if(res["success"]){
                this.table_list2                 = res["data"]["query_data"];
                this.total_search_result2        = res["data"]["total_result"];
                this.total_result2               = res["data"]["total_result"];
                this.row_counter2                = (this.pageNumber2 * val.page_size_placeholder2) + 1;
                this.pagination_counter2         = this.row_counter2 + (this.table_list2.length - 1);
                
                //convert date
                for(var k in this.table_list2){
                    const es = this.table_list2[k];
                    if(es["date_created"] !== undefined){
                        var u_date = new Date(es["date_created"]["_seconds"] * 1000 + es["date_created"]["_nanoseconds"]/1000000);
                        this.table_list2[k]["date_created_str"] = this.datePipe.transform(u_date, 'MMM dd, yyyy h:mm:ss a');
                    }//end if

                    this.table_list2[k]["name"] = this.table_list2[k]["profile"]["first_name"]+ " " + this.table_list2[k]["profile"]["last_name"];
                }//end forthis.datePipe.transform((new Date), 'MMM dd, yyyy h:mm:ss a');
                //convert date
            }else{
                this.toastr.error(res["data"]["error"]["message"],"System Message");
                
            }//end if

            if(enable_loading){
                this.global.loading = false;
            }//end if
        })
    };


    adverse_list_promise(){
        const val = this.form.value;
        //filter
        let filters         = {};
        filters["filterFieldArr"]       = new Array();
        filters["filterOperatorArr"]    = new Array();
        filters["filterValueArr"]       = new Array();
        filters["orderByFieldArr"]      = new Array();
        filters["sortOrderArr"]         = new Array();


        filters["filterFieldArr"].push("created_by");
        filters["filterOperatorArr"].push("==");
        filters["filterValueArr"].push(this.global.login_session["id"]);


        filters["orderByFieldArr"].push("date_created");
        filters["orderByFieldArr"].push("id");

        filters["sortOrderArr"].push("desc");
        filters["sortOrderArr"].push("asc");
        
        const data_param = {
            "record_type" : environment.bulk_counter_adverse,
            "pageNumber" : this.pageNumber3,
            "lastVisibleField" : this.lastVisibleField,
            "lastVisibleVal" : this.lastVisibleVal3,
            "previous" : this.previous3,
            "pageSize" : val.page_size_placeholder3,
            ...filters
        }//end
        console.log('data_param',data_param);
        return this.bulkSearchService.filterList(data_param).pipe(
            shareReplay(1)
        );
    };

    adverse_list(enable_loading : boolean = true){
        if(enable_loading){
            this.global.loading = true;
        }//end if
        const val = this.form.value;
        //filter
        let filters         = {};
        filters["filterFieldArr"]       = new Array();
        filters["filterOperatorArr"]    = new Array();
        filters["filterValueArr"]       = new Array();
        filters["orderByFieldArr"]      = new Array();
        filters["sortOrderArr"]         = new Array();
        filters["filterFieldArr"].push("created_by");
        filters["filterOperatorArr"].push("==");
        filters["filterValueArr"].push(this.global.login_session["id"]);


        filters["orderByFieldArr"].push("date_created");
        filters["orderByFieldArr"].push("id");

        filters["sortOrderArr"].push("desc");
        filters["sortOrderArr"].push("asc");
        
        const data_param = {
            "record_type" : environment.bulk_counter_adverse,
            "pageNumber" : this.pageNumber3,
            "lastVisibleField" : this.lastVisibleField,
            "lastVisibleVal" : this.lastVisibleVal3,
            "previous" : this.previous3,
            "pageSize" : val.page_size_placeholder3,
            ...filters
        }//end
        this.bulkSearchService.filterList(data_param).subscribe((res) => {
            if(res["success"]){
                this.table_list3                 = res["data"]["query_data"];
                this.total_search_result3        = res["data"]["total_result"];
                this.total_result3               = res["data"]["total_result"];
                this.row_counter3                = (this.pageNumber3 * val.page_size_placeholder3) + 1;
                this.pagination_counter3         = this.row_counter3 + (this.table_list3.length - 1);
                
                //convert date
                for(var k in this.table_list3){
                    const es = this.table_list3[k];
                    if(es["date_created"] !== undefined){
                        var u_date = new Date(es["date_created"]["_seconds"] * 1000 + es["date_created"]["_nanoseconds"]/1000000);
                        this.table_list3[k]["date_created_str"] = this.datePipe.transform(u_date, 'MMM dd, yyyy h:mm:ss a');
                    }//end if

                    this.table_list3[k]["name"] = this.table_list3[k]["profile"]["first_name"]+ " " + this.table_list3[k]["profile"]["last_name"];
                }//end forthis.datePipe.transform((new Date), 'MMM dd, yyyy h:mm:ss a');
                //convert date
            }else{
                this.toastr.error(res["data"]["error"]["message"],"System Message");
                
            }//end if

            if(enable_loading){
                this.global.loading = false;
            }//end if
        })
    };

    refresh_list_bak(){
        try {
            this.global.loading = true;
            const val = this.form.value;
            //filter
            let filters         = {};
            filters["filterFieldArr"]       = new Array();
            filters["filterOperatorArr"]    = new Array();
            filters["filterValueArr"]       = new Array();
            filters["orderByFieldArr"]      = new Array();
            filters["sortOrderArr"]         = new Array();


            filters["filterFieldArr"].push("created_by");
            filters["filterOperatorArr"].push("==");
            filters["filterValueArr"].push(this.global.login_session["id"]);


            filters["orderByFieldArr"].push("date_created");
            filters["orderByFieldArr"].push("id");

            filters["sortOrderArr"].push("desc");
            filters["sortOrderArr"].push("asc");
            
            const data_param = {
                "record_type" : environment.bulk_search,
                "pageNumber" : this.pageNumber,
                "lastVisibleField" : this.lastVisibleField,
                "lastVisibleVal" : this.lastVisibleVal,
                "previous" : this.previous,
                "pageSize" : val.page_size_placeholder,
                ...filters
            }//end

            console.log('data_param',data_param);

            

            this.bulkSearchService.filterList(data_param).subscribe((res) => {
                console.log(res);

                if(res["success"]){
                    this.table_list                 = res["data"]["query_data"];
                    this.total_search_result        = res["data"]["total_result"];
                    this.total_result               = res["data"]["total_result"];
                    this.show_total_search_result   = true;
                    this.row_counter                = (this.pageNumber * val.page_size_placeholder) + 1;
                    this.pagination_counter         = this.row_counter + (this.table_list.length - 1);
                    
                    //convert date
                    for(var k in this.table_list){
                        const es = this.table_list[k];
                        if(es["date_created"] !== undefined){
                            var u_date = new Date(es["date_created"]["_seconds"] * 1000 + es["date_created"]["_nanoseconds"]/1000000);
                            this.table_list[k]["date_created_str"] = this.datePipe.transform(u_date, 'MMM dd, yyyy h:mm:ss a');
                        }//end if

                        this.table_list[k]["name"] = this.table_list[k]["profile"]["first_name"]+ " " + this.table_list[k]["profile"]["last_name"];
                    }//end forthis.datePipe.transform((new Date), 'MMM dd, yyyy h:mm:ss a');
                    //convert date
                
                }else{
                    console.log(res["data"]);
                    this.toastr.error(res["data"]["message"],"System Message");
                }//end if
                this.global.loading = false;
            })
        } catch (e : any) {
            this.toastr.error(e["message"],"System Message");
        }//end try
    }//end

    upload(event : any){
        this.totalFileSize = 0;
        if (event.target instanceof HTMLInputElement && event.target.type === "file") {
            const files = event.target.files;
            

            const allowed_ext = [ 
                '.csv'
            ];
            

            
            
          

            let file_arr = new Array();
            
            for(const k in files){
                if (isNaN(parseInt(k))) {
                    continue;
                }//end if
                
                const file          = files[k];
                const file_ext      = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();
              
                const KBSize        = (parseFloat(file.size) / 1024) / 1024;
                this.totalFileSize  += KBSize;

                

                if(!allowed_ext.includes(file_ext)){
                    this.form.controls['file'].setErrors({'invalid_file': true}); 
                    this.isDisabled = true;
                }else{
                    this.isDisabled = false;
                }//end if

                
                file_arr.push({
                    file : file
                });

                
            }//end if
          
            //this.totalFileSize = this.commonService.catchIsNaN(this.totalFileSize,2)

            this.form.patchValue({
                file_placeholder : file_arr
            });

           
            //set max file size
            if(this.totalFileSize > 25){
                this.form.controls['file'].setErrors({'exceed_file_size': true});  
            }//end if
            //end set max file size
        }//end if
       
    }

    async submit() {
        
        this.global.loading = true;
        const val = this.form.value;
        try {
            //check permission
            let compare_val  = "add";
            let w_access     = false;
            const permission = this.global.login_session["permission"]["support"]
            for(const k in permission){
                if(permission[k] == compare_val){
                    w_access = true;
                    break;
                }//end if
            }//end for
           
            if(!w_access){
                const errorObj = {
                    code : "Access_Denied",
                    message : "You don't have permission to do this action!"
                }//end

                throw errorObj;
            }//end if

            
            //end check permission

            //-----------------------------------------------validate-----------------------------------------------
            let error_html_arr = new Array();
            if(!this.form.valid){
                for(var k in this.form.controls){
                    var field = this.form.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if

                    if(field.errors?.['pattern'] !== undefined && field.errors?.['pattern']){
                        error_html_arr.push(this.field_label[k]+' is invalid format <br/>');
                        
                    }//end if

                    if(field.errors?.['minlength'] !== undefined && field.errors?.['minlength']){
                        error_html_arr.push(this.field_label[k]+' must have 6 minimum characters <br/>');
                        
                    }//end if

                    if(field.errors?.['matchValidator'] !== undefined && field.errors?.['matchValidator']){
                        error_html_arr.push(this.field_label[k]+' doesn\'t match! <br/>');
                        
                    }//end if

                    if(field.errors?.['exceed_file_size'] !== undefined && field.errors?.['exceed_file_size']){
                        error_html_arr.push(this.field_label[k]+' max size exceeded! <br/>');
                    }//end if

                    if(field.errors?.['invalid_file'] !== undefined && field.errors?.['invalid_file']){
                        error_html_arr.push(this.field_label[k]+' contains invalid file type! <br/>');
                    }//end if
                }//end for
            }//end if

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "Require Field",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if
            //-----------------------------------------------validate-----------------------------------------------


            


            //-----------------------------------------------process------------------------------------------------
        

            //sanitize
            const attachments = val.file_placeholder;
            for(const k in val){
                if(k.includes('_placeholder')){
                    delete val[k];
                }//end if
            }//end for
            //end sanitize

            
            var today = new Date();
            var request_param = {};
            //--------------------------V2--------------------------
            let req_param = {};
            //--------------------------V2--------------------------
            var request_body_grp = new Array();
            var elastic_txt = "";
            if(val.file !== null){
                var file_name = "";
                
                
                console.log(attachments.length);
                var required_field = ['full_name','entity_type'];

                for(var s in attachments){
                    const m_data = attachments[s];
                    const file = m_data.file;
                    file_name = file.name;


                    
                    var parser = this.ngxCsvParser.parse(file, { header: true, delimiter: ',', encoding: 'utf8' });
                    var pipes = await firstValueFrom(parser);
                    var final_data = new Array();
                    for(const a in pipes){
                        var validator = required_field;
                        var mm_data = pipes[a];
                        
                        var fld = {};
                        for(const b in mm_data){
                            var field_name = b.replace(' ','_').toLocaleLowerCase();
                            fld[b.replace(' ','_').toLocaleLowerCase()] = mm_data[b];
                            if(validator.includes(field_name)){
                                var index = validator.indexOf(field_name);
                                validator.splice(index,1);
                            }//end if
                        }//end for

                        if(validator.length > 0){
                            var error_field = new Array();
                            for(const d in validator){
                                error_field.push(validator[d]);
                            }//end for

                            const errObj = {
                                code : "MSSNG_FLD(S)",
                                message : "Missing field(s) "+error_field.join(",")
                            }
                            throw errObj;
                        }//end if

                        final_data.push(fld);
                    }//end for

                    //--------------------------upload file--------------------------
                    const upload_param = {
                        storage_ref : environment.storage_folder+'/'+this.global.login_session["username"]+'/bulk_search/'+(today.getTime()+"_"+file_name),
                        file : file
                    }
                    //console.log('upload_param',upload_param)
                    const storageUrl = await uploadTaskPromise(upload_param);

                    if(!storageUrl["success"]){
                        const errObj = {
                            code : "Upload Error",
                            message : storageUrl["data"]
                        }
                        throw errObj;
                    }else{
                        request_param["file_link"] = storageUrl["data"]["url"];
                    }//end if
                    //--------------------------upload file--------------------------

                    var cols = [
                        'Entity Type',
                        'Last Name',
                        'First Name',
                        'Middle Name',
                        'Corporate Name',
                        'Result',
                        'Results List'
                    ];

                    
                    var required = [
                        'Entity Type',
                        'First Name',
                        'Corporate Name'
                    ]
                    if(file.name.indexOf('csv') >= 0){
                        var csv_result = {};
                        
                       
                       
                        
                       
                        


                        //parse csv file
                        //--------------------------V2--------------------------
                        var search_data = new Array();
                        //--------------------------V2--------------------------
                        this.ngxCsvParser.parse(file, { header: true, delimiter: ',', encoding: 'utf8' })
                        .pipe().subscribe({
                            next: async (result): Promise<void> => {
                                var field = {};
                                for(var k in result){
                                   
                                    //-----------------create filter-----------------
                                    var filters = {};
                                    filters["must"] = new Array();
                                    var bool = {
                                        "bool" : {
                                            "should" : new Array()
                                        }
                                    };
                                    //-----------------create filter-----------------

                                    const data = result[k];
                                    
                                    var full_name = "";
                                    for(var s in data){
                                        field[s.replace(' ','_').toLocaleLowerCase()] = data[s];
                                    }//end for

                                
                                    //Validate all Conditions
                                    /*
                                    1. If Row is empty
                                    2. Wrong Type Tagging (Individual or Corporartion) but with details
                                    3. If No Type Tagging
                                    
                                    */
                                    if(field["entity_type"] !== undefined){
                                        //Check if Individual, Full Name is not undefined and not empty
                                        if(field["entity_type"].toLocaleLowerCase() == "individual" && field["full_name"] !== undefined && field["full_name"] !== ''){

                                            //--------------------------Individual--------------------------
                                            
                                            if(field["full_name"] !== undefined){
                                                full_name = field["full_name"];
                                            }//end if
    
                                            /*if(field["first_name"] !== undefined){
                                                full_name += field["first_name"];
                                            }//end if
    
                                            if(field["middle_name"] !== undefined){
                                                full_name +=" "+field["middle_name"];
                                            }//end if
    
                                            if(field["last_name"] !== undefined){
                                                full_name +=" "+field["last_name"];
                                            }//end if
                                            */
    
                                            full_name = full_name.trim();
    
                                            //--------------------------V2--------------------------
                                            search_data.push({
                                                "entity_type" : field["entity_type"].toLocaleLowerCase(),
                                                "name" : full_name.toLocaleLowerCase()
                                            });
                                            //--------------------------V2--------------------------
    
                                            bool["bool"]["should"].push(
                                                {
                                                    "fuzzy" : {
                                                        "FULL_NAME" : full_name.toLocaleLowerCase()
                                                    }
                                                },
                                                {
                                                    "wildcard" : {
                                                        "FULL_NAME" : "*"+full_name.toLocaleLowerCase()+"*"
                                                    }
                                                },
                                                /*{
                                                    "match_phrase_prefix" : {
                                                        "FIRST_NAME" : full_name.toLocaleLowerCase()
                                                    }
                                                },
                                                {
                                                    "match_phrase_prefix" : {
                                                        "SECOND_NAME" : full_name.toLocaleLowerCase()
                                                    }
                                                },
                                                {
                                                    "match_phrase_prefix" : {
                                                        "THIRD_NAME" : full_name.toLocaleLowerCase()
                                                    }
                                                },
                                                {
                                                    "match_phrase_prefix" : {
                                                        "FOURTH_NAME" : full_name.toLocaleLowerCase()
                                                    }
                                                },
                                                */
                
                                                {
                                                    "match_phrase_prefix" : {
                                                        "FULL_NAME" : full_name.toLocaleLowerCase()
                                                    }
                                                },
                                                
                                                {
                                                    "combined_fields" : {
                                                        "query":      full_name.toLocaleLowerCase(),
                                                        "fields":     [ "FIRST_NAME","SECOND_NAME", "THIRD_NAME", "FOURTH_NAME"],
                                                        "operator":   "and"
                                                    }
                                                },
                                                {
                                                    "match_phrase_prefix" : {
                                                        "INDIVIDUAL_ALIAS.ALIAS_NAME" : full_name.toLocaleLowerCase()
                                                    }
                                                },
                                                {
                                                    "fuzzy" : {
                                                        "INDIVIDUAL_ALIAS.ALIAS_NAME" : full_name.toLocaleLowerCase()
                                                    }
                                                },
                                                {
                                                    "wildcard" : {
                                                        "INDIVIDUAL_ALIAS.ALIAS_NAME" : "*"+full_name.toLocaleLowerCase()+"*"
                                                    }
                                                }
                                            );
                                            //--------------------------Individual--------------------------
                                            
    
                                            filters["must"].push(bool)
                                            
    
                                            request_body_grp[k] = {
                                                "search_val" : full_name.toLocaleLowerCase(),
                                                "type" : "individual",
                                                "request_body" : [
                                                    {
                                                        "index" : environment.elastic_index,
                                                        
                                                    },
                                                    {
                                                        "query" : {
                                                            "bool" : filters
                                                        }
                                                    }
                                                ]
                                            };
    
                                            
    
                                            //elastic_txt += '{"index" : "'+environment.elastic_index+'"}'+"\r\n";
                                            //elastic_txt += '{"query" : { "bool" :'+JSON.stringify(filters)+'}}'+"\r\n";
                                        
                                        //Check if Coporation, Corporate Name is not undefined and not empty
                                        }else if(field["entity_type"].toLocaleLowerCase() == "corporation" && field["corporate_name"] !== undefined && field["corporate_name"] !== ''){
                                            //--------------------------Entity--------------------------
                                            if(field["corporate_name"] !== undefined){
                                                full_name = field["corporate_name"];
                                            }//end if
                                            full_name = full_name.trim();
    
    
                                            //--------------------------V2--------------------------
                                            search_data.push({
                                                "entity_type" : "corporation",
                                                "name" : full_name.toLocaleLowerCase()
                                            });
                                            //--------------------------V2--------------------------
    
                                            bool["bool"]["should"].push(
                                                {
                                                    "fuzzy" : {
                                                        "FIRST_NAME" : full_name.toLocaleLowerCase()
                                                    }
                                                },
                                                {
                                                    "wildcard" : {
                                                        "FIRST_NAME" : "*"+full_name.toLocaleLowerCase()+"*"
                                                    }
                                                },
                                                {
                                                    "match_phrase_prefix" : {
                                                        "FIRST_NAME" : full_name.toLocaleLowerCase()
                                                    }
                                                },
                                                {
                                                    "match_phrase_prefix" : {
                                                        "ENTITY_ALIAS.ALIAS_NAME" : full_name.toLocaleLowerCase()
                                                    }
                                                },
                                                {
                                                    "fuzzy" : {
                                                        "ENTITY_ALIAS.ALIAS_NAME" : full_name.toLocaleLowerCase()
                                                    }
                                                },
                                                {
                                                    "wildcard" : {
                                                        "ENTITY_ALIAS.ALIAS_NAME" : "*"+full_name.toLocaleLowerCase()+"*"
                                                    }
                                                }
                                            );
                                            
                                            //request_body[k]["index"] = environment.elastic_index_entity;
                                            //--------------------------Entity--------------------------
                                            
                                            filters["must"].push(bool)
                                            request_body_grp[k] = {
                                                "search_val" : full_name.toLocaleLowerCase(),
                                                "type" : "entity",
                                                "request_body" : [
                                                    {
                                                        "index" : environment.elastic_index_entity,
                                                        
                                                    },
                                                    {
                                                        "query" : {
                                                            "bool" : filters
                                                        },
                                                        "from" : 0,
                                                        "size" : 10
                                                    }
                                                ]
                                            };
    
    
                                            //elastic_txt += '{"index" : "'+environment.elastic_index_entity+'"}'+"\r\n";
                                            //elastic_txt += '{"query" : { "bool" :'+JSON.stringify(filters)+'}}'+"\r\n";
                                        
                                        
                                        }else{
                                            //Skip Loop
                                            continue;
                                        } 
                                    }else{
                                        //Skip Loop
                                        continue;
                                      
                                    }
                                    
                                    
                                    //create query filter
                                    
                                    
                                }//end for

                                request_param["file_name"] = file_name;
                                request_param["created_by"] = this.global.login_session["id"];
                                
                                //elastic_txt += "\r\n";


                                //var test = elastic_query.join("\r\n") + "\r\n";
                                //--------------------------upload payload as text file--------------------------
                                /*const blob = new Blob([JSON.stringify(request_body_grp)], { type : 'plain/text;charset=utf-8' });
                                var text_file_name = file_name.replace('.csv','.txt');
                                const upload_param = {
                                    storage_ref : environment.storage_folder+'/'+this.global.login_session["username"]+'/bulk_search_query/'+(today.getTime()+"_"+text_file_name),
                                    file : blob
                                }
                                //console.log('upload_param',upload_param)
                                const storageUrl = await uploadTaskPromise(upload_param);

                                if(!storageUrl["success"]){
                                    const errObj = {
                                        code : "Upload Error",
                                        message : storageUrl["data"]
                                    }
                                    throw errObj;
                                }else{
                                    request_param["payload_link"] = storageUrl["data"]["url"];
                                }//end if
                                */
                                //--------------------------upload file--------------------------
                                

                                //--------------------------V2--------------------------
                                req_param = {
                                    created_by : this.global.login_session["id"],
                                    file_name : file_name,
                                    data : search_data
                                };
                                console.log('req_param',req_param);
                                //--------------------------V2--------------------------
                                
                                /*this.bulkSearchService.process_bulk_search(request_param).subscribe(response => {
                                    console.log(response);
                                    if(response["success"]){
                                        if(response["notify"] !== undefined){
                                            this.toastr.warning(response["notify"]["message"],'System Message',{
                                                timeOut: 10000,
                                                enableHtml : true
                                            });
                                        }//end if

                                        var message = "Operation successfully completed!";
                                        this.toastr.success(message,"System Message");
                                        setTimeout(() => {
                                            window.location.reload(); 
                                        }, 2000);
                                    }//end if
                                    this.global.loading = false;
                                });
                                */
                               
                                let search_service = this.bulkSearchService.process_bulk_search(req_param);

                                if(this.selected_tab=='sanction-tab'){  
                                    search_service = this.bulkSearchService.process_bulk_search(req_param);
                                }else if(this.selected_tab=='pep-tab'){
                                    search_service = this.bulkSearchService.process_bulk_search_wiki(req_param);
                                }else if(this.selected_tab=='adverse-tab'){
                                    search_service = this.bulkSearchService.process_bulk_search_adverse(req_param);
                                }
                                search_service.subscribe((response) => {
                                    console.log(response);
                                    if(response["success"]){
                                        if(response["notify"] !== undefined){
                                            this.toastr.warning(response["notify"]["message"],'System Message',{
                                                timeOut: 10000,
                                                enableHtml : true
                                            });
                                        }//end if

                                        var message = "Operation successfully completed. This page will refresh in 5 seconds";
                                        this.toastr.success(message,"System Message");
                                        setTimeout(() => {
                                            window.location.reload(); 
                                        }, 5000);
                                    }else{
                                        this.toastr.error(response["data"]["error"]["message"],"System Message");
                                    }//end if
                                    this.global.loading = false;
                                });
                                
                                

                                var message = "Search result generation is currently processing on the background."
                                this.toastr.info(message,"System Message");
                               
                                
                                
                                
                            },
                            error: (error: NgxCSVParserError): void => {
                                console.log('Error', error);
                            }//end next
                        });
                        //parse csv file

                        

                        
                    }//end if
                  
                    

                }//end for

                

                
            }//end if


            //-----------------------------------------------process------------------------------------------------

            
        } catch (error : any) {
            this.toastr.error(error.message,"System Message",{enableHtml : true});
            this.global.loading = false;
            this.form.reset();
        }//end 
    }


    process_bulk(request_param){
        
        return this.bulkSearchService.process_bulk_search(request_param);
    }//end if

    async test(){
        var param = {
            "file_name": "mock_data.csv",
            "file_link": "",
            "created_by": this.global.login_session["id"],
            "payload": [
                {
                    "search_val": "ahmad taha",
                    "type": "individual",
                    "request_body": [
                        {
                            "index": "search-ucheck_staging"
                        },
                        {
                            "query": {
                                "bool": {
                                    "must": [
                                        {
                                            "bool": {
                                                "should": [
                                                    {
                                                        "match_phrase_prefix": {
                                                            "FIRST_NAME": "ahmad taha"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "SECOND_NAME": "ahmad taha"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "THIRD_NAME": "ahmad taha"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "FOURTH_NAME": "ahmad taha"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "FULL_NAME": "ahmad taha"
                                                        }
                                                    },
                                                    {
                                                        "combined_fields": {
                                                            "query": "ahmad taha",
                                                            "fields": [
                                                                "FIRST_NAME",
                                                                "SECOND_NAME",
                                                                "THIRD_NAME",
                                                                "FOURTH_NAME"
                                                            ],
                                                            "operator": "and"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "INDIVIDUAL_ALIAS.ALIAS_NAME": "ahmad taha"
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            }
                        }
                    ]
                },
                {
                    "search_val": "mousa mohammed",
                    "type": "individual",
                    "request_body": [
                        {
                            "index": "search-ucheck_staging"
                        },
                        {
                            "query": {
                                "bool": {
                                    "must": [
                                        {
                                            "bool": {
                                                "should": [
                                                    {
                                                        "match_phrase_prefix": {
                                                            "FIRST_NAME": "mousa mohammed"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "SECOND_NAME": "mousa mohammed"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "THIRD_NAME": "mousa mohammed"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "FOURTH_NAME": "mousa mohammed"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "FULL_NAME": "mousa mohammed"
                                                        }
                                                    },
                                                    {
                                                        "combined_fields": {
                                                            "query": "mousa mohammed",
                                                            "fields": [
                                                                "FIRST_NAME",
                                                                "SECOND_NAME",
                                                                "THIRD_NAME",
                                                                "FOURTH_NAME"
                                                            ],
                                                            "operator": "and"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "INDIVIDUAL_ALIAS.ALIAS_NAME": "mousa mohammed"
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            }
                        }
                    ]
                },
                {
                    "search_val": "nayif hawatma",
                    "type": "individual",
                    "request_body": [
                        {
                            "index": "search-ucheck_staging"
                        },
                        {
                            "query": {
                                "bool": {
                                    "must": [
                                        {
                                            "bool": {
                                                "should": [
                                                    {
                                                        "match_phrase_prefix": {
                                                            "FIRST_NAME": "nayif hawatma"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "SECOND_NAME": "nayif hawatma"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "THIRD_NAME": "nayif hawatma"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "FOURTH_NAME": "nayif hawatma"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "FULL_NAME": "nayif hawatma"
                                                        }
                                                    },
                                                    {
                                                        "combined_fields": {
                                                            "query": "nayif hawatma",
                                                            "fields": [
                                                                "FIRST_NAME",
                                                                "SECOND_NAME",
                                                                "THIRD_NAME",
                                                                "FOURTH_NAME"
                                                            ],
                                                            "operator": "and"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "INDIVIDUAL_ALIAS.ALIAS_NAME": "nayif hawatma"
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            }
                        }
                    ]
                },
                {
                    "search_val": "mohammad",
                    "type": "individual",
                    "request_body": [
                        {
                            "index": "search-ucheck_staging"
                        },
                        {
                            "query": {
                                "bool": {
                                    "must": [
                                        {
                                            "bool": {
                                                "should": [
                                                    {
                                                        "match_phrase_prefix": {
                                                            "FIRST_NAME": "mohammad"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "SECOND_NAME": "mohammad"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "THIRD_NAME": "mohammad"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "FOURTH_NAME": "mohammad"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "FULL_NAME": "mohammad"
                                                        }
                                                    },
                                                    {
                                                        "combined_fields": {
                                                            "query": "mohammad",
                                                            "fields": [
                                                                "FIRST_NAME",
                                                                "SECOND_NAME",
                                                                "THIRD_NAME",
                                                                "FOURTH_NAME"
                                                            ],
                                                            "operator": "and"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "INDIVIDUAL_ALIAS.ALIAS_NAME": "mohammad"
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            }
                        }
                    ]
                },
                {
                    "search_val": "vigan banco rural, incorporada",
                    "type": "entity",
                    "request_body": [
                        {
                            "index": "search-ucheck-entity_staging"
                        },
                        {
                            "query": {
                                "bool": {
                                    "must": [
                                        {
                                            "bool": {
                                                "should": [
                                                    {
                                                        "match_phrase_prefix": {
                                                            "FIRST_NAME": "vigan banco rural, incorporada"
                                                        }
                                                    },
                                                    {
                                                        "match_phrase_prefix": {
                                                            "ENTITY_ALIAS.ALIAS_NAME": "vigan banco rural, incorporada"
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            }
                        }
                    ]
                }
            ]
        };

        var api = await lastValueFrom(this.process_bulk(param));
        console.log('api',api);
    }


    search(){
       
        const val = this.form.value;
        this.previous                    = false;
        this.lastVisibleField            = "id";
        this.lastVisibleVal              = "";
        this. prevPageVisibleVal         = new Array();
        this.pageNumber                  = 0;
        this.refresh_list()
    }

    search2(){
       
        const val = this.form.value;
        this.previous2                    = false;
        this.lastVisibleField            = "id";
        this.lastVisibleVal2              = "";
        this. prevPageVisibleVal2         = new Array();
        this.pageNumber2                  = 0;
        this.wikidata_list()
    }

    search3(){
        const val = this.form.value;
        this.previous3                    = false;
        this.lastVisibleField            = "id";
        this.lastVisibleVal3              = "";
        this. prevPageVisibleVal3         = new Array();
        this.pageNumber3                  = 0;
        this.adverse_list()
    }

    prev(){
        const val           = this.form.value;
        if(this.pageNumber > 0){
            this.pageNumber -= 1;
            this.previous = true;
            this.lastVisibleVal         = this.prevPageVisibleVal[this.pageNumber];
            this.refresh_list();
        }//end if
    }

    prev2(){
        const val = this.form.value;
        if(this.pageNumber2 > 0){
            this.pageNumber2 -= 1;
            this.previous2 = true;
            this.lastVisibleVal2         = this.prevPageVisibleVal2[this.pageNumber2];
            this.wikidata_list();
        }//end if
    }

    prev3(){
        const val = this.form.value;
        if(this.pageNumber3 > 0){
            this.pageNumber3 -= 1;
            this.previous3 = true;
            this.lastVisibleVal3         = this.prevPageVisibleVal3[this.pageNumber3];
            this.adverse_list();
        }//end if
    }

    next(){
        const val           = this.form.value;
        const total_pages   = Math.ceil((this.total_result / val.page_size_placeholder));
        if(total_pages > (this.pageNumber + 1)){
            this.pageNumber             += 1;
            this.previous               = false;
            this.lastVisibleVal         = this.table_list[this.table_list.length - 1]["id"];

            if(!this.prevPageVisibleVal.includes(this.table_list[0]["id"])){
                this.prevPageVisibleVal.push(this.table_list[0]["id"]);
            }//end if
            this.refresh_list();
        }//end if
        
    }

    next2(){
        const val           = this.form.value;
        const total_pages   = Math.ceil((this.total_result2 / val.page_size_placeholder2));
        if(total_pages > (this.pageNumber2 + 1)){
            this.pageNumber2             += 1;
            this.previous2               = false;
            this.lastVisibleVal2         = this.table_list2[this.table_list2.length - 1]["id"];
            if(!this.prevPageVisibleVal2.includes(this.table_list2[0]["id"])){
                this.prevPageVisibleVal2.push(this.table_list2[0]["id"]);
            }//end if
            this.wikidata_list();
        }//end if
    }

    next3(){
        const val           = this.form.value;
        const total_pages   = Math.ceil((this.total_result3 / val.page_size_placeholder3));
        if(total_pages > (this.pageNumber3 + 1)){
            this.pageNumber3             += 1;
            this.previous3               = false;
            this.lastVisibleVal3         = this.table_list3[this.table_list3.length - 1]["id"];
            if(!this.prevPageVisibleVal3.includes(this.table_list3[0]["id"])){
                this.prevPageVisibleVal3.push(this.table_list3[0]["id"]);
            }//end if
            this.adverse_list();
        }//end if
    }

    change_tab(event : any,id){
        var target = event.target || event.srcElement || event.currentTarget;
        
        //document.getElementsByClassName(id)[0].children;
        var el = document.getElementById(id);
        this.selected_tab = id;
        let str = id.replace('-tab','');
        //Check Tab
        if(str == "sanction"){
            str = "Sanction";
        }else if(str == "pep"){
            str = "PEP";
        }else if(str == "adverse"){
            str = "Adverse News";
        }
        // str = this.commonService.ucwords(str);

        this.search_type = str;
       

    }


} 


function uploadTaskPromise(param) {
    let response = {
        success : false,
        data : {}
    }
    return new Promise(function(resolve, reject) {

        const storage             = getStorage();
        const storageRef          = ref(storage,param.storage_ref);
        const uploadTask          = uploadBytesResumable(storageRef, param.file);

        uploadTask.on('state_changed',
                function(snapshot) {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    //progressbar = (progress - 1);
                
                    
                }, 
                function error(err) {
                    response.success = false;
                    response.data = err;
                    reject(response)
                    
                }, 
                function complete() {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        response.success = true;
                        response.data   = {
                            url : downloadURL
                        }//end

                        //progressbar += 1;
                        resolve(response)
                        //applicant_doc.travel_doc_swab = downloadURL;
                    });
                }
            );
    })
}