import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../global/global-variable';
import { environment } from 'src/environments/environment';

@Component({
    
  selector: 'app-layout',
  
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

    public year_today;
    public date_today;
    public app_version;


    constructor(
        public global : Globals,
        private router: Router,) {
            if(this.global.login_session === null){
                this.router.navigate(['/login']);
            }//end if
            this.date_today = new Date()
            this.year_today = this.date_today.getFullYear();
            this.app_version = environment.app_version
            
    }






    
}
