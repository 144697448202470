import { Component } from '@angular/core';
import { FormBuilder,Validators } from '@angular/forms';
import { ToastrService } from "ngx-toastr";
import { Globals } from 'src/app/global/global-variable';
@Component({
  selector: 'app-settings-vw',
  templateUrl: './settings-vw.component.html',
  styleUrls: ['./settings-vw.component.scss']
})
export class SettingsVwComponent {
    form = this.fb.group({
        source_id : [''],
        source : ['',Validators.required],
        source_link : ['',Validators.required]
    });

    field_label = {
        "source" : "Source",
        "source_link" : "Source Link"
    }

    constructor(
        private fb : FormBuilder,
        private toastr : ToastrService,
        public global : Globals
    ){

    }//end

    save(){
        this.global.loading = true;
        const val = this.form.value;
        try {
            //validate login
            let error_html_arr = new Array();
            if(!this.form.valid){
                for(var k in this.form.controls){
                    var field = this.form.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if
                }//end for
            }//end if
            //end validate login

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "Require Field",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if

            this.global.loading = false;

            
        } catch (e : any) {
            this.toastr.error(e.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end try
    }

}
