<div class="container-fluid">
    <div class="row">
        <div class="col-xxl-8 col-xl-8 col-auto">
            <h3 class="mb-3 fw-bold">UNAWA UCheck</h3>
            <!--<div class="shadow bg-white rounded px-3 py-3">-->
            <!--FAQ 1-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>What is UNAWA UCheck?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq1" (click)="hide_link($event,'faq1')" data-bs-toggle="collapse" href=".faq1" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>

                    <div class="row faq1 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            UNAWA UCheck is a global database AMLA check with automated regulatory compliance reporting. UCheck allows users to instantly search databases and repositories based on identity particulars, aliases, and other important details. The users can then generate reports on the searched entity, which they can use for compliance with the AMLC’s documentary requirements
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 1-->

            <!--FAQ 2-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>Am I required to use UCheck before reporting to the AMLC?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq2" (click)="hide_link($event,'faq2')" data-bs-toggle="collapse" href=".faq2" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>

                    <div class="row faq2 collapse mb-3">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            UCheck can help in your KYC procedure by searching publicly available databases and generating a report for it. The report can then form part of your records and can be made easily available to authorities when needed.
                        </div>
                    </div>

                    <div class="row faq2 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            No! Currently, the AMLC only requires that Covered Persons collect records about their clients. Such records may include any information after conducting the required Know-Your- Client (KYC) procedure – not necessarily gathered through any tools, such as UCheck.
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 2-->

            <!--FAQ 3-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>What databases are searched by UCheck?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq3" (click)="hide_link($event,'faq3')" data-bs-toggle="collapse" href=".faq3" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>
                    <div class="row faq3 collapse mb-3">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            The following are the databases and repositories being constantly searched by UCheck:
                        </div>
                    </div>
                    <div class="row faq3 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            <ul>
                                <li>United Nations Security Council (UNSC) Consolidated List</li>
                                <li>UN Al-Qaida Sanctions List</li>
                                <li>*****</li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 3-->



            <!--FAQ 4-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>What parameters can be searched by UCheck?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq4" (click)="hide_link($event,'faq4')" data-bs-toggle="collapse" href=".faq4" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>
                    <div class="row faq4 collapse mb-3">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            The following may be searched through UCheck:
                        </div>
                    </div>
                    <div class="row faq4 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            <ul>
                                <li>Full Name (Romanized and/or Scripted)</li>
                                <li>Aliases</li>
                                <li>Affiliations</li>
                                <li>Address</li>
                                <li>****</li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 4-->

            <!--FAQ 5-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>Can UCheck generate a report regarding my client?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq5" (click)="hide_link($event,'faq5')" data-bs-toggle="collapse" href=".faq5" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>
                    <div class="row faq5 collapse mb-3">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            Yes, UCheck can generate two (2) types of reports:
                        </div>
                    </div>
                    <div class="row faq5 collapse mb-3">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            <b>Positive Hit:</b> When a client’s name appear in any of the searched databases, then a report containing all details about the client is generated.<br/>
                            <b>Negative Hit:</b> A report stating that the client’s name does not appear in any of the available databases is generated.

                            
                        </div>
                    </div>

                    <div class="row faq5 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            All reports contain a scannable QR code which shall redirect the user to an electronic copy of
                            the report in UCheck. This can be used to verify the information in report.
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 5-->



            <!--FAQ 6-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>UCheck showed a positive hit for my client, what should I do next?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq6" (click)="hide_link($event,'faq6')" data-bs-toggle="collapse" href=".faq6" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>
                    <div class="row faq6 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            The AMLC requires that KYC documents are uploaded to AMLC Portal 5 . UCheck’s generated report may form part of your KYC documents.
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 6-->

            <!--FAQ 7-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>UCheck showed a negative hit for my client, does it mean I should not upload anything to
                                the AMLC portal?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq7" (click)="hide_link($event,'faq7')" data-bs-toggle="collapse" href=".faq7" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>
                    <div class="row faq7 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            You may still upload UCheck’s generated report to show that you have done your part in
                            knowing your client!
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 7-->

            <!--FAQ 8-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>Is UCheck free to use?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq8" (click)="hide_link($event,'faq8')" data-bs-toggle="collapse" href=".faq8" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>
                    <div class="row faq8 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            ******
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 8-->

            <!--FAQ 9-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>Is UCheck compliant with existing AMLA regulations?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq9" (click)="hide_link($event,'faq9')" data-bs-toggle="collapse" href=".faq9" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>
                    <div class="row faq9 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            UCheck is a search tool designed to help you ease your compliance with AMLA regulations. The tool is closely monitored by IT and law professionals to make sure that the AMLA and other related laws (such as the Data Privacy Act) are fully complied with!
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 9-->


            <!--FAQ 10-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>Is UCheck compliant with existing AMLA regulations?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq10" (click)="hide_link($event,'faq10')" data-bs-toggle="collapse" href=".faq10" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>
                    <div class="row faq10 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            UCheck is a search tool designed to help you ease your compliance with AMLA regulations. The tool is closely monitored by IT and law professionals to make sure that the AMLA and other related laws (such as the Data Privacy Act) are fully complied with!
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 10-->

            <h3 class="mb-3 fw-bold">AMLA-Related Concerns</h3>

            <!--FAQ 11-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>What is the Anti-Money Laundering Act of 2001 (AMLA)?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq11" (click)="hide_link($event,'faq11')" data-bs-toggle="collapse" href=".faq11" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>
                    <div class="row faq11 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            The AMLA is the law that:
                            <ul>
                                <li>Criminalizes money laundering</li>
                                <li>Creates a financial intelligence unit – known as the Anti-Money Laundering Council</li>
                                <li>Imposes requirements on customer identification, record keeping, and reporting of covered and suspicious transactions</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 11-->

            <!--FAQ 12-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>What is the Anti-Money Laundering Council (AMLC)?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq12" (click)="hide_link($event,'faq12')" data-bs-toggle="collapse" href=".faq12" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>
                    <div class="row faq12 collapse mb-3">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            The Anti-Money Laundering Council (AMLC) was created pursuant to RA9160, otherwise known as the AMLA, to protect the integrity and confidentiality of bank accounts and to ensure that the Philippines shall not be used as a money laundering site for the proceeds of any unlawful activity 1 .
                        </div>
                    </div>

                    <div class="row faq12 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            It is the Philippines&#39; central AML authority and financial intelligence unit, which is the government instrumentality mandated to implement the AMLA and TFPSA 2 .
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 12-->


            <!--FAQ 13-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>Who are required to register with the AMLC?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq13" (click)="hide_link($event,'faq13')" data-bs-toggle="collapse" href=".faq13" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>
                    <div class="row faq13 collapse mb-3">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            “Covered Persons” are required to register with and report to the AMLC. These are the financial institutions and designated non-financial businesses and professions 3 , which include:
                        </div>
                    </div>

                    <div class="row faq13 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            <ul>
                                <li>Banks</li>
                                <li>Quasi-Banks</li>
                                <li>Trust entities</li>
                                <li>Pawnshops</li>
                                <li>Money changers</li>
                                <li>Money remittance or transfer companies</li>
                                <li>Casinos</li>
                                <li>Full list found here 4</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 13-->


            <!--FAQ 14-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>I’m a lawyer (or a law firm) or an accountant (or an accounting firm),
                                am I required to be registered?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq14" (click)="hide_link($event,'faq14')" data-bs-toggle="collapse" href=".faq14" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>
                    <div class="row faq14 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            If you are acting as an independent legal professional in relation to information concerning your clients or where disclosure of information would compromise client confidences or the attorney-client relationship, then you are NOT required to register with the AMLC
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 14-->


            <!--FAQ 15-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>Do all transactions by these institutions require the submission of reports to the AMLC?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq15" (click)="hide_link($event,'faq15')" data-bs-toggle="collapse" href=".faq15" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>
                    <div class="row faq15 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            No! Only Covered Transactions and Suspicious Transactions are required to be reported.
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 15-->


            <!--FAQ 16-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>What are Covered Transactions?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq16" (click)="hide_link($event,'faq16')" data-bs-toggle="collapse" href=".faq16" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>
                    <div class="row faq16 collapse mb-3">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            A Covered Person shall report to the AMLC the following covered transactions:
                        </div>
                    </div>

                    <div class="row faq16 collapse mb-3">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            <ul>
                                <li>
                                    <b>For cash transactions:</b> A transaction in cash or other equivalent monetary instrument involving a total amount in excess of P500,000 within 1 banking day
                                </li>
                                <li>
                                    <b>For casino transactions:</b> A single casino cash transaction involving an amount in excess of P5,000,000.000 or its equivalent in any other currency
                                </li>
                                <li>
                                    <b>For transactions by real estate developers and brokers:</b> A single cash transaction involving an amount in excess of P7,500,000.00 or its equivalent in any other currency.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="row faq16 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            This means that those transactions below the respective threshold amounts need not be reported – unless they’re Suspicious Transactions!
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 16-->

            <!--FAQ 17-->
            <div class="card rounded mb-3">
                <div class="card-body px-4 py-4">
                    <div class="row mb-3">
                        <div class="col">
                            <h5>What are Suspicious Transaction?</h5>
                        </div>
                        <div class="col-2 text-end">
                            
                            <a id="faq17" (click)="hide_link($event,'faq17')" data-bs-toggle="collapse" href=".faq17" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                        </div>
                    </div>
                    <div class="row faq17 collapse">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            <ul>
                                <li>There is no underlying legal or trade obligation, purpose, or economic justification for the transaction</li>
                                <li>The client is not properly identified</li>
                                <li>The amount involved is not commensurate with the business or financial capacity of the client</li>
                                <li>The client’s transaction is structured in order to avoid being the subject of reporting requirements</li>
                                <li>The transaction is in any way related to an unlawful activity or offense that is about to be, is being, or has been committed</li>
                                <li>Any transaction that is similar or analogous to any of the foregoing</li>
                            </ul>
                        </div>
                    </div>

                    <div class="row faq17 collapse mb-3">
                        <div class="col-xxl-12 col-xl-12 col-auto">
                            A Covered Person shall report to the AMLC suspicious transactions – regardless of the amount involved – if the following circumstances are involved:
                        </div>
                    </div>
                </div>
            </div>
            <!--FAQ 17-->
        </div>
    </div>
</div>