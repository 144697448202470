import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { CommonService } from "../library/pcl_lib";
@Injectable({
    providedIn : "root"
})

export class AccountService {
    constructor(private router: Router,
        private http: HttpClient,
        private lib : CommonService){        
    }

    public filterList(q): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+localStorage.getItem('accessToken')
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.get_data_staging,q,requestOptions);
    }

    public sync_data(): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+localStorage.getItem('accessToken')
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.get<result_data>(environment.process_data_staging,requestOptions);
    }

    public save_data(function_param): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+localStorage.getItem('accessToken')
        });
        const requestOptions: Object = {
            //headers: new HttpHeaders().append('Authorization', 'Bearer '+localStorage.getItem("accessToken")),
            responseType: 'json',
            headers : headers
        }//end
        return  this.http.put(environment.save_data_staging,function_param,requestOptions);
    }//end

    public process_data(function_param): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+localStorage.getItem('accessToken')
        });
        const requestOptions: Object = {
            //headers: new HttpHeaders().append('Authorization', 'Bearer '+localStorage.getItem("accessToken")),
            responseType: 'json',
            headers : headers
        }//end
        return  this.http.post(environment.process_data_staging,function_param,requestOptions);
    }//end
}

export interface result_data {}