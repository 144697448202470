<div class="container-fluid">

    <form [formGroup]="filter_form">
        <div class="row align-items-center justify-content-start ms-3 me-3">
            <div class="col-auto">
               <h5>Bulk Search</h5>
            </div>
            <div class="col">
    
            </div>
            <div class="col-auto">
                <div class="input-group">
                    <span class="input-group-text bg-white">
                        <img style="width:0.15in;height:0.15in;" src="{{'../../assets/images/search-icon.svg'}}">
                    </span>
                    <input style="border-left:none;" (keyup.enter)="search()" formControlName="filter_search" (blur)="search()" placeholder="Search name"  type="text" class="form-control">
                </div>
                
            </div>
        </div>
    </form>

    <div class="row justify-content-center ms-3 me-3">
        <div class="col">

            
            

            <p class="text-muted"></p>
            <div class="shadow bg-white rounded" *ngIf="(show_total_search_result)">
                
                
                <div class="table-responsive" style="max-height:500px;" >
                    <table class="table align-middle" style="min-width:2in;">
                        <thead>
                            <tr>
                                <th class="text-muted text-left px-3">Search words/names</th>
                                
                                <th class="text-muted text-center px-3">Total matched results</th>
                                <th class="text-muted px-3">Searched on</th>
                                <th class="text-muted text-center px-3">With Hit</th>
                                <th class="text-muted px-3 text-center" style="width:1.8in;">Action</th>
                                <th class="text-muted px-3 text-center" style="width:1in;"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of table_list; let x = index">
                                <td class="text-left px-3">{{data.searched_value}}</td>
                                <td class="text-center px-3">{{commonService.toCurrency_precision(data.query_result,0)}}</td>
                                <td class="text-left px-3">{{data.date_created_str}}</td>
                                <td class="text-center px-3">
                                    <span class="{{data.query_result > 0? 'text-success' : 'text-danger'}}">{{data.query_result > 0? 'Yes' : 'No'}}</span>
                                </td>
                                <td class="text-center px-3">
                                    <div class="d-grid gap-2">
                                        <a target="_blank" class="btn btn-sm btn-primary" href="search-result/generate/{{data.search_type}}/{{data.id}}">Generate Report <img src="../../assets/images/add.svg" />
                                        </a>
                                    </div>
                                </td>

                                <td class="text-center px-3">
                                    <div class="d-grid gap-2">
                                        <a class="btn btn-sm btn-primary" href="main/bulk-search-vw/search/{{data.id}}" >View
                                        </a>
                                    </div>
                                </td>
                               
                                
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row container-fluid justify-content-end align-items-center mt-4">
                    <div class="col-auto">
                        <form [formGroup]="filter_form">

                            <div class="mb-3 row align-items-center">
                                <label for="inputPassword" class="col-auto col-form-label font-14">
                                    Rows per page
                                </label>
                                <div class="col">
                                    <select style="border-style:none !important;" (change)="search()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                        <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                    </select>
                                </div>
                            </div>

                        </form>
                    </div>

                    <div class="col-auto">
                        <div class="mb-3 row align-items-center">
                            <div class="col-auto">
                                {{row_counter}} - {{pagination_counter}} of {{total_search_result}}
                            </div>
                        </div>
                        
                    </div>
                    
                    <div class="col-auto">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination border-opacity-10">
                                <li class="page-item " (click)="prev()" >
                                    <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                        <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                    </a>
                                </li>
                             
                                <li class="page-item" (click)="next()">
                                    <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                        <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    
                </div>
            </div>

            <div class="card border-0 shadow" *ngIf="show_total_search_result == 0">
                <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">

                    <div class="col-auto">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-auto">
                                <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                            </div>
                            <div class="col-12">
                                <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
