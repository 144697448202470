import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BulkSearchService } from '../services/bulk-search-service';
import { Globals } from '../global/global-variable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CommonService } from '../library/pcl_lib';
@Component({
  selector: 'app-bulk-search-vw',
  templateUrl: './bulk-search-vw.component.html',
  styleUrls: ['./bulk-search-vw.component.scss']
})
export class BulkSearchVwComponent {
	snapshot_param = {};
	show_no_data = false;
	constructor(
		public commonService : CommonService,
		private fb : FormBuilder,
		public global : Globals,
		private datePipe : DatePipe,
		private bulkSearchService : BulkSearchService,
		private toastr : ToastrService,
		private _activatedRouter : ActivatedRoute){
        this.snapshot_param = this._activatedRouter.snapshot.params;
    }

	table_list = new Array();

	filter_form = this.fb.group({
        filter_search : [''],
        page_size_placeholder : [10],
    });

	//for pagination
    previous                    = false;
    lastVisibleField            = "id";
    lastVisibleVal              = "";
    prevPageVisibleVal          = new Array();
    pageNumber                  = 0;
    row_counter                 = 0;
    pagination_counter          = 0;
    pageSize                    = [10,20,50,100];

    show_total_search_result    = 0;
    total_search_result         = 0;
    total_result                = 0;

	ngOnInit(){
		this.refresh_list();
		//alert(this.snapshot_param["id"]);
	}

	refresh_list(){
		this.global.loading = true;
		var val = this.filter_form.value;
		let filters = {};
		filters["filterFieldArr"]  = new Array();
		filters["filterOperatorArr"] = new Array();
		filters["filterValueArr"] = new Array();
		filters["orderByFieldArr"] = new Array();
        filters["sortOrderArr"] = new Array();



		filters["filterFieldArr"].push("bulk_search_id");
		filters["filterOperatorArr"].push("==");
		filters["filterValueArr"].push(this.snapshot_param["id"]);

		filters["filterFieldArr"].push("type");
		filters["filterOperatorArr"].push("==");
		filters["filterValueArr"].push("search_count");

		if(val.filter_search !== ""){
			filters["filterFieldArr"].push("search_searched_value");
			filters["filterOperatorArr"].push("array-contains");
			filters["filterValueArr"].push(val.filter_search.toLowerCase());
		}//end if

		filters["orderByFieldArr"].push("query_result");
		filters["orderByFieldArr"].push("date_created");
        filters["orderByFieldArr"].push("id");

        filters["sortOrderArr"].push("desc");
		filters["sortOrderArr"].push("desc");
        filters["sortOrderArr"].push("desc");

		const data_param = {
			"record_type" : environment.dashboard_counter,
			"pageNumber" : this.pageNumber,
			"lastVisibleField" : this.lastVisibleField,
			"lastVisibleVal" : this.lastVisibleVal,
			"previous" : this.previous,
			"pageSize" : val.page_size_placeholder,
			...filters
		}//end
    
		
                

		console.log(data_param);

		this.bulkSearchService.filterList(data_param).subscribe(res => {
			if(res["success"]){
				console.log(res["data"]["query_data"]);
				this.table_list                 = res["data"]["query_data"];
				//convert date
				for(var k in this.table_list){
					const es = this.table_list[k];
					if(es["date_created"] !== undefined){
						var u_date = new Date(es["date_created"]["_seconds"] * 1000 + es["date_created"]["_nanoseconds"]/1000000);
						this.table_list[k]["date_created_str"] = this.datePipe.transform(u_date, 'MMM dd, yyyy h:mm:ss a');
					}//end if

					
				}//end forthis.datePipe.transform((new Date), 'MMM dd, yyyy h:mm:ss a');
				//convert date

				this.total_search_result        = res["data"]["total_result"];
				this.total_result               = res["data"]["total_result"];
				this.show_total_search_result   = res["data"]["total_result"];
				this.row_counter                = (this.pageNumber * val.page_size_placeholder) + 1;
				this.pagination_counter         = this.row_counter + (this.table_list.length - 1);
			}else{
				console.log(res);
                this.toastr.error(res["data"]["message"],"System Message");
			}
			this.global.loading = false;
		})
	}

	search(){
       
        this.previous                    = false;
        this.lastVisibleField            = "id";
        this.lastVisibleVal              = "";
        this. prevPageVisibleVal          = new Array();
        this.pageNumber                  = 0;
        this.refresh_list()
    }

	prev(){
        const val           = this.filter_form.value;;
        if(this.pageNumber > 0){
            this.pageNumber -= 1;
            this.previous = true;
            this.lastVisibleVal         = this.prevPageVisibleVal[this.pageNumber];
            this.refresh_list();
        }//end if
        
        
        
    }

    next(){
        const val           = this.filter_form.value;;
        const total_pages   = Math.ceil((this.total_result / val.page_size_placeholder));
        
        if(total_pages > (this.pageNumber + 1)){
            
            this.pageNumber             += 1;
            this.previous               = false;
            this.lastVisibleVal         = this.table_list[this.table_list.length - 1]["id"];
           
            if(!this.prevPageVisibleVal.includes(this.table_list[0]["id"])){
                this.prevPageVisibleVal.push(this.table_list[0]["id"]);
            }//end if

            this.refresh_list();
            
            
        }//end if
    }
}
