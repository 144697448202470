import { Component } from '@angular/core';
import { Globals } from '../global/global-variable';
import { environment } from 'src/environments/environment';
import { AccountService } from '../services/account-service';
import { Toast, ToastrService } from 'ngx-toastr';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { LoginService } from '../services/login-service';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { CommonService } from '../library/pcl_lib';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
    currevent;
    table_list = new Array();
    form = this.fb.group({
        id                          : [''],
        action                      : ['update'],
        first_name                  : ['',[Validators.required,Validators.pattern('[a-zA-Z ]*')]],
        last_name                   : ['',[Validators.required,Validators.pattern('[a-zA-Z ]*')]],
       
        username                    : ['',Validators.required],
        username_placeholder        : [''],
        password                    : [''],
        password_placeholder        : [''],
        user_type                   : ['',Validators.required],
        profile_photo               : [''],
        profile_photo_placeholder   : [null]
       
    })
    
    form3 = this.fb.group({
        id                          : [''],
        action                      : ['update'],
        country_code                : [''],
        country_name                : [''],
        dial_code                   : [''],
        mobile_number               : ['',[Validators.required,Validators.pattern('[0-9]{9,14}')]]
    })

    form2 = this.fb.group({
        id                          : [''],
        action                      : ['update'],
        username                    : [''],
        username_placeholder        : [''],
        password                    : ['',[Validators.required,Validators.minLength(6)]],
        password_placeholder        : [''],
        new_password_placeholder    : ['',[Validators.required,Validators.minLength(6)]],
        conf_password_placeholder   : ['',[Validators.required,Validators.minLength(6)]]
    })

    field_label = {
        "first_name" : "First Name",
        "last_name" : "Last Name",
        "user_type" : "User Type",
        "mobile_number" : "Mobile Number",
        "dial_code" : "Dial Code",
        "username" : "Username",
        "password" : "Current password",
        "conf_password_placeholder" : "Confirm Password",
        "new_password_placeholder" : "New Password"
    }

    show_edit_btn = false;

    //password
    show_password = false;
    show_password2 = false;
    show_password3 = false;
    

    constructor(
        public global : Globals,
        private fb : FormBuilder,
        private accountService : AccountService,
        private loginService : LoginService,
        private toastr : ToastrService,
        public commonService : CommonService,
        private datePipe : DatePipe
    ){
        this.form2.addValidators(
            matchValidator(this.form2.controls["new_password_placeholder"], this.form2.controls["conf_password_placeholder"])
          )
        
    }

    ngOnInit(){
        //check permission
        let w_access     = false;
        const permission = this.global.login_session["permission"]["account"]
        for(const k in permission){
            if(permission[k] == 'view'){
                w_access = true;
            }//end if

            if(permission[k] == 'edit'){
                this.show_edit_btn = true;
            }//end if
            
        }//end for
        if(!w_access){
            window.location.replace('/main/dashboard');
        }//end if
        //end check permission
        
        this.load_data();
    }

    upload(event : any){
        
        if (event.target instanceof HTMLInputElement && event.target.type === "file") {
            const fileDoc = event.target.files[0];
                //const fileName = document.getElementById("file-name");

                const f   = event.target.files[0];
                this.form.patchValue({
                    profile_photo_placeholder : f
                });

                

                  
                //const fileName = document.getElementById("file-name");
                

                const reader = new FileReader();
                reader.readAsDataURL(fileDoc);
                reader.onload = () => {
                    const profile_pic = document.getElementById("profile_pic");
                    profile_pic?.setAttribute('src', <any>reader.result);
                    this.form.markAsDirty();
                }
                
                //fileName!.textContent = event.target.files[0]['name'];
        }//end if
       
    }

    load_data(){
        this.global.loading = true;
        const query_param = {
            "record_type" : environment.user_management,
            "pageSize" : 1,
            "filterFieldArr" : [
                "username"
            ],
            "filterOperatorArr" : [
                "=="
            ],
            "filterValueArr" : [
                this.global.login_session["username"]
            ]
        }//end

        //return this.settingService.filterList(query_param);
        this.accountService.filterList(query_param).subscribe(res => {
            console.log('res');
            console.log(res);
            if(res["success"]){
                this.table_list = res["data"]["query_data"];

                //populate fields
                for(const k in this.form.controls){
                    for(const y in this.table_list[0]){
                        if(k == 'profile_photo_placeholder'){continue;}
                        if(k.includes("_placeholder")){
                            this.form.controls[k].setValue(this.table_list[0][k.replace("_placeholder","")]);
                        }//end if
                        if(k == y){
                            this.form.controls[k].setValue(this.table_list[0][y]);
                        }//end if
                    }//end for
                }//end for

                for(const k in this.form3.controls){
                    for(const y in this.table_list[0]){
                        if(k == 'profile_photo_placeholder'){continue;}
                        if(k.includes("_placeholder")){
                            this.form3.controls[k].setValue(this.table_list[0][k.replace("_placeholder","")]);
                        }//end if
                        if(k == y){
                            this.form3.controls[k].setValue(this.table_list[0][y]);
                        }//end if
                    }//end for
                }//end for

                if(this.form3.controls['country_code'].value !== null && this.form3.controls['country_code'].value !== ""){
                    this.currevent.setCountry(this.form3.controls['country_code'].value)
                }
                //end populate fields

                //populate fields
                for(const k in this.form2.controls){
                    for(const y in this.table_list[0]){

                        if(k == 'password'){
                            continue;
                        }//end if

                        if(k.includes("_placeholder")){
                            this.form2.controls[k].setValue(this.table_list[0][k.replace("_placeholder","")]);
                        }//end if
                        if(k == y){
                            this.form2.controls[k].setValue(this.table_list[0][y]);
                        }//end if
                    }//end for
                }//end for
                
                //end populate fields

              

            }else{
                console.log(res);
                this.toastr.error(res["data"]["message"],"System Message");
            }//end if
            
            this.global.loading = false;
        });
    }


    async save_data(){
        this.global.loading = true;
        let val = this.form.value;

       

        try {

            //check permission
            let compare_val  = "";
            if(val.action == 'add'){
                compare_val = 'add';
            }else if(val.action == 'update'){
                compare_val = 'edit';
            }//end if

            let w_access     = false;
            const permission = this.global.login_session["permission"]["account"]
            for(const k in permission){
                if(permission[k] == compare_val){
                    w_access = true;
                    break;
                }//end if
            }//end for
           
            if(!w_access){
                const errorObj = {
                    code : "Access_Denied",
                    message : "You don't have permission to do this action!"
                }//end

                throw errorObj;
            }//end if

            
            //end check permission
            
            //validate login
            let error_html_arr = new Array();
            if(!this.form.valid){
                for(var k in this.form.controls){
                    var field = this.form.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if

                    if(field.errors?.['pattern'] !== undefined && field.errors?.['pattern']){
                        error_html_arr.push(this.field_label[k]+' is invalid format <br/>');
                        
                    }//end if

                   

                    if(field.errors?.['matchValidator'] !== undefined && field.errors?.['matchValidator']){
                        error_html_arr.push(this.field_label[k]+' doesn\'t match! <br/>');
                        
                    }//end if
                }//end for

               
            }//end if
            //end validate login

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "Require Field",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if

             //upload 1
            //--------------------------------------------------------------------------------------------------------
            const profile_photo       = val.profile_photo_placeholder;
            if(profile_photo !== undefined && profile_photo !== null){
                const upload_param = {
                    storage_ref : environment.storage_folder+'/'+val.username_placeholder+'/'+profile_photo.name,
                    file : profile_photo
                }
                const storageUrl = await uploadTaskPromise(upload_param);

                if(!storageUrl["success"]){
                    const errObj = {
                        code : "Upload Error",
                        message : storageUrl["data"]
                    }
                    throw errObj;
                }else{
                    val["profile_photo"] = storageUrl["data"]["url"];
                }//end if
                
            }//end if
            //--------------------------------------------------------------------------------------------------------
            //end upload 1


            //save to firebase

            val['email'] = val.username_placeholder;
            val['password'] = val.password_placeholder;
            
           
            const request_param = {
                
                "record_type"   : environment.user_management,
                "date_created"  : new Date(),
                ...val
               
            }

            
            console.log('request_param',request_param);
            

           
            
            this.loginService.createUserV2(request_param).subscribe(async (response) => {
                if(response["success"]){
                    //create session
                    response["data"]["profile_photo"] = val["profile_photo"];

                    var login_session_obj = JSON.parse(localStorage.getItem("login_session"));
                    console.log('login_session_obj before',login_session_obj);

                    Object.assign(login_session_obj,response["data"])
                    console.log('login_session_obj after',login_session_obj);

                    await localStorage.setItem("login_session",JSON.stringify(login_session_obj));
                    
                    //end create session

                    this.toastr.success("Profile updated successfully!","System Message");
                    
                    //window.location.reload();
                    setTimeout(() => {
                       window.location.reload();
                    }, 3000);
                }else{
                    this.toastr.error(response["data"]["error"]["message"],"System Message");
                    
                }//end if
                this.global.loading = false;
            })
                
            /*
            this.loginService.createUser(request_param).then((response) => {
                console.log('createUser',response);
                if(response["success"]){
                    
                    this.loginService.signIn(val).then(async (res) => {
                        console.log('res.data');
                        console.log(res.data);
                        if(res.success){
                            //create session
                            res.data["profile_photo"] = val["profile_photo"];
                            await localStorage.setItem("login_session",JSON.stringify(res.data));
                            console.log(JSON.stringify(res.data));
                            //end create session

                            this.toastr.success("Profile updated successfully!","System Message");
                            
                            //window.location.reload();
                            setTimeout(() => {
                               //window.location.reload();
                            }, 3000);
                            
                        }
                    });
                    
                    
                }else{
                    this.toastr.error(response["data"]["message"],"System Message");
                    this.global.loading = false;
                }//end if
                
            });
            
            */
            

          


        } catch (error : any) {
            //this.toastr.error(error.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end 
    }//end save data

    validate(){
        this.global.loading = true;
        let val = this.form3.value;
        
        try {
            //validate login
            let error_html_arr = new Array();
            
            if(!this.form3.valid){
                for(var k in this.form3.controls){
                    var field = this.form3.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if

                    

                    if(field.errors?.['matchValidator'] !== undefined && field.errors?.['matchValidator']){
                        error_html_arr.push(this.field_label[k]+' doesn\'t match! <br/>');
                        
                    }//end if

                    if(field.errors?.['pattern'] !== undefined && field.errors?.['pattern']){
                        error_html_arr.push(this.field_label[k]+' format is invalid <br/>');
                        
                    }//end if
                }//end for

               
            }//end if
            //end validate login

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "Require Field",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if

            /*const request_param = {
                "records" : [{
                    "record_type"   : environment.user_management,
                    ...val
                }]
            }*/

            const request_param = {
                "record_type"   : environment.user_management,
                ...val
            }

            console.log(request_param);

            this.loginService.createUserV2(request_param).subscribe((response) => {
                if(response["success"]){
                    this.toastr.success("Your mobile number has been updated!","System Message");
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }else{
                    this.toastr.error(response["data"]["error"]["message"],"System Message");
                }//end if

                this.global.loading = false;
            })

            /*
            this.loginService.save_data(request_param).subscribe((response) => {
                console.log(response);
                if(response["success"]){
                    this.toastr.success("Your mobile number has been updated!","System Message");
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }else{
                    this.toastr.error(response["data"]["message"],"System Message");
                }//end if

                this.global.loading = false;
            })*/
        } catch (error : any) {
            //this.toastr.error(error.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end try
    }//end validate


    async change_password(){
        this.global.loading = true;
        let val = this.form2.value;
        let val2 = this.form.value;
       

        try {
            //validate login
            let error_html_arr = new Array();
            //check if current password match

            const check_password = await lastValueFrom(this.loginService.login({
                "username" : val2.username,
                "password" : val.password
            }));
            

            if(!check_password["success"]){
                this.form2.controls['password'].setErrors({"password_match" : true});
            }

            /*if(val.password !== this.table_list[0]["password"]){
                this.form2.controls['password'].setErrors({"password_match" : true});
                
            }//end if
            */

            if(this.form2.controls["new_password_placeholder"].value !== this.form2.controls["conf_password_placeholder"].value){
                this.form2.controls['conf_password_placeholder'].setErrors({"cp_validator" : true});
            }//end ifform2

          
            //end check if current password match

            if(!this.form2.valid){

                

                for(var k in this.form2.controls){
                    var field = this.form2.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if

                    if(field.errors?.['minlength'] !== undefined && field.errors?.['minlength']){
                        error_html_arr.push(this.field_label[k]+' must have 6 minimum characters <br/>');
                        
                    }//end if

                    if(field.errors?.['password_match'] !== undefined && field.errors?.['password_match']){
                        error_html_arr.push(this.field_label[k]+' doesn\'t matched <br/>');
                        
                    }//end if

                    if(field.errors?.['matchValidator'] !== undefined && field.errors?.['matchValidator']){
                        error_html_arr.push(this.field_label[k]+' doesn\'t match! <br/>');
                        
                    }//end if

                    if(field.errors?.['cp_validator'] !== undefined && field.errors?.['cp_validator']){
                        error_html_arr.push(this.field_label[k]+' doesn\'t match! <br/>');
                        
                    }//end if

                    
                }//end for

                
            }//end if
            //end validate login

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "Require Field",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if

           

            //save to firebase

           
            val["email"] = val.username;
            val["password_placeholder"] = val.password;
            val["password"] = val.new_password_placeholder;
           

            /*const request_param = {
                "records" : [{
                    "record_type"   : environment.user_management,
                    "date_created"  : new Date(),
                    ...val
                }]
            }*/

            const request_param = {
                "record_type"   : environment.user_management,
                "last_update_date"  : new Date(),
                ...val
            }

            this.loginService.createUserV2(request_param).subscribe(async (response) => {
                if(response["success"]){
                    this.toastr.success("Your password has been updated!","System Message");
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }else{
                    this.toastr.error(response["data"]["error"]["message"],"System Message");
                    
                }//end if
                this.global.loading = false;
            })

            
            /*
            this.loginService.createUser(request_param).then((response) => {
                console.log(response);
                if(response["success"]){
                    val['email'] = val.username;
                    this.loginService.signIn(val).then((res) => {
                        if(res.success){
                            //create session
                            localStorage.setItem("login_session",JSON.stringify(res.data));
                            //end create session

                            this.toastr.success("Your password has been updated!","System Message");
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                            
                        }
                    });
                    
                    
                }else{
                    this.toastr.error(response["data"]["message"],"System Message");
                    this.global.loading = false;
                }//end if
                
            });
            */

            

          


        } catch (error : any) {
            //this.toastr.error(error.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end 
    }//end save data

    onCountryChange(obj) {
       
        this.form3.controls['country_name'].setValue(obj.name);
        this.form3.controls['country_code'].setValue(obj.iso2);
        this.form3.controls['dial_code'].setValue('+'+obj.dialCode);
    }

    telInputObject(obj) {
        
        this.currevent = obj;
        //console.log(obj);
        //obj.setCountry('ph');
    
        //this.form.controls['country_name'].setValue('Philippines');
        //this.form.controls['country_code'].setValue('ph');
        //this.form.controls['dial_code'].setValue('+63');
        /*
        if(this.data.profile !== undefined){
            obj.setCountry(this.data.profile.country_code);
        }else{
            obj.setCountry('ph');
            
        }*/
        
    }//end if

    s_password(name){
        
        if(name == 'password'){
            if(this.show_password){
                this.show_password = false;
            }else{
                this.show_password = true;
            }//end if
            
        }else if(name == 'password2'){
            if(this.show_password2){
                this.show_password2 = false;
            }else{
                this.show_password2 = true;
            }//end if
        }else{
            if(this.show_password3){
                this.show_password3 = false;
            }else{
                this.show_password3 = true;
            }//end if
        }//end if
    }

    check_if_changed(frm : FormGroup){

        const val = frm.value;
        let w_changed = false;

        //populate fields
        for(const k in frm.controls){
            for(const y in this.table_list[0]){
                if(k == 'profile_photo_placeholder'){continue;}
                
                if(k.includes("_placeholder")){
                    continue;
                }//end if

                if(k == y){
                    if(frm.controls[k].value !== this.table_list[0][y]) {
                        w_changed = true;
                        break;
                    }//end if
                }//end if
            }//end for
        }//end for
        
        return w_changed;


    }
}


function matchValidator(
    control: AbstractControl,
    controlTwo: AbstractControl
): ValidatorFn {
    return () => {
        if (control.value !== controlTwo.value)
        return {matchValidator: {value: 'Value does not match' }};
        return null;
    };
}

async function uploadTaskPromise(param) {
    let response = {
        success : false,
        data : {}
    }
    return new Promise(function(resolve, reject) {

        const storage             = getStorage();
        const storageRef          = ref(storage,param.storage_ref);
        const uploadTask          = uploadBytesResumable(storageRef, param.file);

        uploadTask.on('state_changed',
                function(snapshot) {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    //progressbar = (progress - 1);
                
                    
                }, 
                function error(err) {
                    response.success = false;
                    response.data = err;
                    reject(response)
                    
                }, 
                function complete() {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        response.success = true;
                        response.data   = {
                            url : downloadURL
                        }//end

                        //progressbar += 1;
                        resolve(response)
                        //applicant_doc.travel_doc_swab = downloadURL;
                    });
                }
            );
    })
}