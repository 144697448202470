
<div class="navbar_float_left v2-color-side-middle">
    <div style="min-height: 0.76in;border-bottom: 1px solid white;">
        <div class="pt-3 ps-5">
            <img class="img-fluid" src="../../assets/images/uchech logo.svg">
        </div>
       
    </div>
    
    <div class="pt-3" >
        <app-side-menu></app-side-menu>
    </div>

    <div class="pt-5" style="height:10% !important;">
        <p class="text-center">
            <small class="fw-bold" style="font-family: 'Lato', sans-serif !important;">
                <a href="https://unawa.asia/terms-conditions" class="text-white">Terms & Condition</a> <span class="text-white"> | </span> <a href="https://unawa.asia/privacy-policy" class="text-white">Privacy Policy</a>
            </small><br/>
            <small>
                <span class="text-white" style="font-family: 'Lato', sans-serif !important;">&copy; {{year_today}} </span><a href="https://unawa.asia/" class="text-white"><span class="fw-bold" style="font-family: 'Lato', sans-serif !important;">UNAWA. </span></a> <span class="text-white" style="font-family: 'Lato', sans-serif !important;"> All rights reserved</span>
            </small><br/>
            <small>
                <span class="text-white" style="font-family: 'Lato', sans-serif !important;">{{app_version}} </span>
            </small>
        </p>
        
    </div>
    
</div>

<div class="navbar_float_right bg-dark-green" style="min-height: 0.76in;">
    <div class="container-fluid">
        <div class="row justify-content-end">
            <div class="col-xxl-9 col-xl-9 col-lg-9 col">
                <nav class="navbar navbar-expand-md pt-3">
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </div>

                    <div class="v2-color-side-middle offcanvas offcanvas-start collapse" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div class="offcanvas-header" style="border-bottom: 1px solid white;">
                            <div style="min-height: 0.76in;">
                                <div class="pt-3 ps-5">
                                    <img class="img-fluid" src="../../assets/images/uchech logo.svg">
                                </div>
                               
                            </div>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <app-side-menu></app-side-menu>

                            <div class="pt-5" style="height:10% !important;">
                                <p class="text-center">
                                    <small class="fw-bold" style="font-family: 'Lato', sans-serif !important;">
                                        <a href="https://unawa.asia/terms-conditions" class="text-white">Terms & Condition</a> <span class="text-white"> | </span> <a href="https://unawa.asia/privacy-policy" class="text-white">Privacy Policy</a>
                                    </small><br/>
                                    <small>
                                        <span class="text-white" style="font-family: 'Lato', sans-serif !important;">&copy; {{year_today}} </span><a href="https://unawa.asia/" class="text-white"><span class="fw-bold" style="font-family: 'Lato', sans-serif !important;">UNAWA. </span></a> <span class="text-white" style="font-family: 'Lato', sans-serif !important;"> All rights reserved</span>
                                    </small><br/>
                                    <small>
                                        <span class="text-white" style="font-family: 'Lato', sans-serif !important;">{{app_version}} </span>
                                    </small>
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <div class="col-auto">
                <div class="row align-items-center justify-content-end" style="min-height: 0.76in;">
                    
                    <div class="col-auto me-5">
                        <a  href="javascript: void(0)" class="navbar-brand text-white" (click)="go_back()">
                            <span class="me-2">x</span> <small>Close page</small>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="view-bg">
    <div class="view_bg_body">
        <div class="row justify-content-center" *ngIf="snapshot_param['search_type'] == 'individual'">
            <div class="col-xxl-10 col-xl-10 col-lg-10">
                <div class="card shadow  mb-5">
                    <div class="card-header bg-transparent py-4 px-4">
                        <div class="mb-3 row align-items-center">
                            <div class="col-2">
                                <img style="height:1in;" class="img-fluid" src="../../assets/images/indiv-profile-pic.png"/>
                            </div>
                            <h4 class="col text-bg-dark-green">{{data_list["caption"] !== undefined? data_list["caption"] : ""}}</h4>
                            
                        </div>
                    </div>
                    <div class="card-body px-5">
                        <div class="collapse-success px-3 py-3 mb-3">
                            <div class="row justify-content-end">
                                <div class="col text-success fw-bolder">
                                    Consolidated PEP List
                                </div>
                                <div class="col-auto">
                                    <a id="pep" (click)="hide_link($event,'pep')" data-bs-toggle="collapse" href=".pep" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                                </div>
                            </div>
                        </div>

                        <div class="container-fluid pep collapse">
                            <h5 class="text-success mt-3 mb-4">Personal Details</h5>
                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Family name</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['lastName'] !== undefined">
                                        <label class="col-form-label">{{data_list["properties"]["lastName"]}}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>First name</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['firstName'] !== undefined">
                                        <label class="col-form-label">{{data_list["properties"]["firstName"]}}</label>
                                    </span>
                                    
                                </div>
                            </div>
    
                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Nationality</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['nationality'] !== undefined">
                                        <span *ngFor="let alias of data_list['properties']['nationality']" for="staticEmail" class="col-form-label">
                                            <p class="col-form-label">
                                                {{commonService.map_countries(alias.toUpperCase()) !== undefined? commonService.map_countries(alias.toUpperCase()) : ''}}
                                            </p>
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Birth date</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['birthDateParsed'] !== undefined">
                                        <span *ngFor="let alias of data_list['properties']['birthDateParsed'];" for="staticEmail" class="col-form-label">
                                            <p class="col-form-label">
                                                {{alias}}
                                            </p>
                                        </span>
                                    </span>
    
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Place of birth</b></label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['birthPlace'] !== undefined">
                                        <span *ngFor="let alias of data_list['properties']['birthPlace'];" for="staticEmail" class="col-form-label">
                                            <p class="col-form-label">
                                                {{alias}}
                                            </p>
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Citizenship</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['country'] !== undefined">
                                        <span *ngFor="let alias of data_list['properties']['country']" for="staticEmail" class="col-form-label">
                                            <p class="col-form-label">
                                                {{commonService.map_countries(alias.toUpperCase()) !== undefined? commonService.map_countries(alias.toUpperCase()) : ''}}
                                            </p>
                                        </span>
                                    </span>
                                </div>
                            </div>

                            

                            <h5 class="text-success mt-5 mb-4">Also known as</h5>
                            <div class="mb-3 row">
                                <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Good quality aliases</b></label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['alias'] !== undefined">
                                        <span *ngFor="let alias of data_list['properties']['alias']" for="staticEmail" class="col-form-label">
                                            
                                            <p class="col-form-label">
                                                {{alias}}
                                            </p>
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Low quality aliases</b></label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['weakAlias'] !== undefined">
                                        <span *ngFor="let alias of data_list['properties']['weakAlias']" for="staticEmail" class="col-form-label">
                                            
                                            <p class="col-form-label">
                                                {{alias}}
                                            </p>
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <h5 class="text-success mt-5 mb-4">Other details</h5>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Relationships</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['familyPerson'] !== undefined">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th colspan="2">Family Members</th>
                                                    
                                                </tr>
                                                <tr>
                                                    <th>Relative</th>
                                                    <th>Relationship</th>
                                                </tr>
                                            </thead> 
                                            <tbody>
                                                <tr *ngFor="let family of data_list['properties']['familyPerson']">
                                                    <td>
                                                        <span *ngIf="family['properties'] !== undefined && family['properties']['relative'] !== undefined">
                                                            <span *ngFor="let relative of family['properties']['relative']" for="staticEmail" class="col-form-label">
                                                                <p class="col-form-label">
                                                                    {{relative['caption']}}
                                                                </p>
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="family['properties'] !== undefined && family['properties']['relationship'] !== undefined">
                                                            <span *ngFor="let relationship of family['properties']['relationship']" for="staticEmail" class="col-form-label">
                                                                <p class="col-form-label">
                                                                    {{relationship}}
                                                                </p>
                                                            </span>
                                                        </span>
                                                    </td>

                                                </tr>
                                            </tbody>  
                                        </table>
                                    </span>

                                    <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['familyRelative'] !== undefined">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th colspan="2">Relatives</th>
                                                    
                                                </tr>
                                                <tr>
                                                    <th>Person</th>
                                                    <th>Relationship</th>
                                                </tr>
                                            </thead> 
                                            <tbody>
                                                <tr *ngFor="let family of data_list['properties']['familyRelative']">
                                                    <td>
                                                        <span *ngIf="family['properties'] !== undefined && family['properties']['person'] !== undefined">
                                                            <span *ngFor="let relative of family['properties']['person']" for="staticEmail" class="col-form-label">
                                                                <p class="col-form-label">
                                                                    {{relative['caption']}}
                                                                </p>
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="family['properties'] !== undefined && family['properties']['relationship'] !== undefined">
                                                            <span *ngFor="let relationship of family['properties']['relationship']" for="staticEmail" class="col-form-label">
                                                                <p class="col-form-label">
                                                                    {{relationship}}
                                                                </p>
                                                            </span>
                                                        </span>
                                                    </td>

                                                </tr>
                                            </tbody>  
                                        </table>
                                    </span> 
                                        
                                    
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Address</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['address'] !== undefined">
                                        <span *ngFor="let alias of data_list['properties']['address']" for="staticEmail" class="col-form-label">
                                            <p class="col-form-label">
                                                {{alias}}
                                            </p>
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Designation</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['position'] !== undefined">
                                        <span *ngFor="let alias of data_list['properties']['position']" for="staticEmail" class="col-form-label">
                                            <p class="col-form-label">
                                                {{alias}}
                                            </p>
                                        </span>
                                    </span>
                                </div>
                            </div>


                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Comments</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['notes'] !== undefined">
                                        <span *ngFor="let alias of data_list['properties']['notes']" for="staticEmail" class="col-form-label">
                                            <p class="col-form-label">
                                                {{alias}}
                                            </p>
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Listed On</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['modifiedAtParsed'] !== undefined">
                                        
                                        <span *ngFor="let alias of data_list['properties']['modifiedAtParsed']" for="staticEmail" class="col-form-label">
                                            <p class="col-form-label">
                                                {{alias}}
                                            </p>
                                        </span>
                                        
                                    </span>
                                </div>
                            </div>


                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Last Updated</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['last_change_parsed'] !== undefined">
                                        <p class="col-form-label">
                                            {{data_list['last_change_parsed']}}
                                        </p>
                                    </span>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                    <b>Source</b>
                                </label>
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                    <span *ngIf="data_list['datasets'] !== undefined">
                                        <span *ngFor="let alias of data_list['datasets']" for="staticEmail" class="col-form-label">
                                            <p class="col-form-label">
                                                {{alias}}
                                            </p>
                                        </span>
                                    </span>
                                </div>
                            </div>

                        </div>

                        <!--adverse news-->
                        <div class="collapse-success px-3 py-3 mb-3">
                            <div class="row justify-content-end">
                                <div class="col text-success fw-bolder">
                                    Adverse News
                                </div>
                                <div class="col-auto">
                                    <a id="adverse" (click)="hide_link($event,'adverse')" data-bs-toggle="collapse" href=".adverse" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                                </div>
                            </div>
                        </div>

                        <!--adverse content-->
                        <div class="container-fluid adverse collapse">
                            <div class="row mt-5 mb-5 align-items-center">
                                <div class="col-xxl-7 col-xl-7 col-auto">
                                    <h5>About {{commonService.toCurrency_precision(this.total_search_result,0)}} result(s)</h5>
                                </div>
                                <div class="col-auto">
                                    <label class="form-label">
                                        Sort By
                                    </label>
                                </div>
                                <div class="col-auto">
                                    <form [formGroup]="form">
                                        <select class="form-select" (change)="load_adverse()" formControlName="sort_by">
                                            <option value="date:d">Most Recent</option>
                                            <option value="review-rating:d">Top News</option>
                                        </select>  
                                    </form>
                                    
                                </div>
                            </div>
                            
                            <hr/>
                            <div *ngFor="let dt of adverse_list_item; let x = index">
                            
                                <div class="card mb-3 border-0">
                                    <div class="row">
                                        <div class="col-auto col-form-label"><a target="_blank" href="{{dt.link}}">{{dt.title}}</a></div>
                                    </div>
                                    <div class="row g-0">
                                        <div class="col-xxl-2 col-xl-2 col-lg-2 col-auto">
    
                                            <img *ngIf="dt['pagemap'] === undefined || dt['pagemap']['cse_thumbnail'] === undefined" style="height:1in;width:1in;" src="../../assets/images/indiv-profile-pic.png" class="img-fluid" alt="...">
    
                                            <img *ngIf="dt['pagemap'] !== undefined && dt['pagemap']['cse_thumbnail'] !== undefined" style="height:1in;width:1in;"  src="{{dt['pagemap']['cse_thumbnail'][0]['src']}}" class="img-fluid" alt="...">
                                        </div>
                                        <div class="col-xxl-10 col-xl-10 col-lg-10 col-auto">
                                            <div class="card-body">
                                                
                                                <p class="card-text" [innerHTML]="dt.htmlSnippet"></p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
    
                        <div class="row container-fluid justify-content-end align-items-center mt-4 collapse adverse">
                            <div class="col-auto">
                                <form [formGroup]="form" *ngIf="show_total_search_result">
    
                                    <div class="mb-3 row align-items-center">
                                        <label for="inputPassword" class="col-auto col-form-label font-14">
                                            Rows per page
                                        </label>
                                        <div class="col">
                                            <select style="border-style:none !important;" (change)="load_adverse()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                                <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                            </select>
                                        </div>
                                    </div>
    
                                    
    
                                    
                                </form>
                            </div>
    
                            <div class="col-auto">
                                <div class="mb-3 row align-items-center">
                                    <div class="col-auto">
                                        {{row_counter}} - {{pagination_counter}} of {{commonService.toCurrency_precision(total_search_result,0)}}
                                    </div>
                                </div>
                                
                            </div>
    
                            <div class="col-auto">
                                <nav aria-label="Page navigation example" *ngIf="show_total_search_result">
                                    <ul class="pagination border-opacity-10">
                                        <li class="page-item " (click)="prev()" >
                                            <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                            </a>
                                        </li>
                                     
                                        <li class="page-item" (click)="next()">
                                            <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <!--adverse content-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row d-none" style="min-height: 11%;">
    <div class="v2-color-side-top col-xxl-2 col-xl-2 col-lg-2 col-md-2 d-sm-none d-md-block d-none d-sm-block gx-0" style="min-width: 1.2in;">
        <div class="container-fluid pt-3 ps-5">
            <img  class="img-fluid" src="../../assets/images/uchech logo.svg">
        </div>
        <hr class="text-white"/>
    </div>

    <div class="col-xxl-10 col-xl-10 col-lg-10 col">
        
        <div class="row align-items-center justify-content-end bg-dark-green" style="min-height: 0.76in;">
            <div class="col ms-5">
                
            </div>
            <div class="col-auto me-5">
                <a  href="javascript: void(0)" class="navbar-brand text-white" (click)="go_back()">
                    <span class="me-2">x</span> <small>Close page</small>
                </a>
            </div>
        </div>
    </div>
</div>
<div class="row view-bg d-none" style="min-height: 81%;">

    <div class="v2-color-side-middle ps-4 col-xxl-2 col-xl-2 col-lg-2 col-md-2 d-sm-none d-md-block d-none d-sm-block " style="min-width: 1.2in;">
        <app-side-menu></app-side-menu>
    </div>
    <div class="col-xxl-10 col-xl-10 col-lg-10 col">
        <div class="container pt-5">
            <!--individual-->
            <div class="row justify-content-center" *ngIf="snapshot_param['search_type'] == 'individual'">
                <div class="col-xxl-10 col-xl-10 col-lg-10">
                    <div class="card shadow  mb-5">
                        <div class="card-header bg-transparent py-4 px-4">
                            <div class="mb-3 row align-items-center">
                                <div class="col-2">
                                    <img style="height:1in;" class="img-fluid" src="../../assets/images/indiv-profile-pic.png"/>
                                </div>
                                <h4 class="col text-bg-dark-green">{{data_list["caption"] !== undefined? data_list["caption"] : ""}}</h4>
                                
                            </div>
                        </div>
                        <div class="card-body px-5">
                            <div class="collapse-success px-3 py-3 mb-3">
                                <div class="row justify-content-end">
                                    <div class="col text-success fw-bolder">
                                        Consolidated PEP List
                                    </div>
                                    <div class="col-auto">
                                        <a id="pep" (click)="hide_link($event,'pep')" data-bs-toggle="collapse" href=".pep" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                                    </div>
                                </div>
                            </div>

                            <div class="container-fluid pep collapse">
                                <h5 class="text-success mt-3 mb-4">Personal Details</h5>
                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Family name</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['lastName'] !== undefined">
                                            <label class="col-form-label">{{data_list["properties"]["lastName"]}}</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>First name</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['firstName'] !== undefined">
                                            <label class="col-form-label">{{data_list["properties"]["firstName"]}}</label>
                                        </span>
                                        
                                    </div>
                                </div>
        
                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Nationality</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['nationality'] !== undefined">
                                            <span *ngFor="let alias of data_list['properties']['nationality']" for="staticEmail" class="col-form-label">
                                                <p class="col-form-label">
                                                    {{commonService.map_countries(alias.toUpperCase()) !== undefined? commonService.map_countries(alias.toUpperCase()) : ''}}
                                                </p>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Birth date</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['birthDateParsed'] !== undefined">
                                            <span *ngFor="let alias of data_list['properties']['birthDateParsed'];" for="staticEmail" class="col-form-label">
                                                <p class="col-form-label">
                                                    {{alias}}
                                                </p>
                                            </span>
                                        </span>
        
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Place of birth</b></label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['birthPlace'] !== undefined">
                                            <span *ngFor="let alias of data_list['properties']['birthPlace'];" for="staticEmail" class="col-form-label">
                                                <p class="col-form-label">
                                                    {{alias}}
                                                </p>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Citizenship</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['country'] !== undefined">
                                            <span *ngFor="let alias of data_list['properties']['country']" for="staticEmail" class="col-form-label">
                                                <p class="col-form-label">
                                                    {{commonService.map_countries(alias.toUpperCase()) !== undefined? commonService.map_countries(alias.toUpperCase()) : ''}}
                                                </p>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                

                                <h5 class="text-success mt-5 mb-4">Also known as</h5>
                                <div class="mb-3 row">
                                    <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Good quality aliases</b></label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['alias'] !== undefined">
                                            <span *ngFor="let alias of data_list['properties']['alias']" for="staticEmail" class="col-form-label">
                                                
                                                <p class="col-form-label">
                                                    {{alias}}
                                                </p>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label for="staticEmail" class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label"><b>Low quality aliases</b></label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['weakAlias'] !== undefined">
                                            <span *ngFor="let alias of data_list['properties']['weakAlias']" for="staticEmail" class="col-form-label">
                                                
                                                <p class="col-form-label">
                                                    {{alias}}
                                                </p>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <h5 class="text-success mt-5 mb-4">Other details</h5>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Relationships</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['familyPerson'] !== undefined">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2">Family Members</th>
                                                        
                                                    </tr>
                                                    <tr>
                                                        <th>Relative</th>
                                                        <th>Relationship</th>
                                                    </tr>
                                                </thead> 
                                                <tbody>
                                                    <tr *ngFor="let family of data_list['properties']['familyPerson']">
                                                        <td>
                                                            <span *ngIf="family['properties'] !== undefined && family['properties']['relative'] !== undefined">
                                                                <span *ngFor="let relative of family['properties']['relative']" for="staticEmail" class="col-form-label">
                                                                    <p class="col-form-label">
                                                                        {{relative['caption']}}
                                                                    </p>
                                                                </span>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span *ngIf="family['properties'] !== undefined && family['properties']['relationship'] !== undefined">
                                                                <span *ngFor="let relationship of family['properties']['relationship']" for="staticEmail" class="col-form-label">
                                                                    <p class="col-form-label">
                                                                        {{relationship}}
                                                                    </p>
                                                                </span>
                                                            </span>
                                                        </td>

                                                    </tr>
                                                </tbody>  
                                            </table>
                                        </span>

                                        <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['familyRelative'] !== undefined">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2">Relatives</th>
                                                        
                                                    </tr>
                                                    <tr>
                                                        <th>Person</th>
                                                        <th>Relationship</th>
                                                    </tr>
                                                </thead> 
                                                <tbody>
                                                    <tr *ngFor="let family of data_list['properties']['familyRelative']">
                                                        <td>
                                                            <span *ngIf="family['properties'] !== undefined && family['properties']['person'] !== undefined">
                                                                <span *ngFor="let relative of family['properties']['person']" for="staticEmail" class="col-form-label">
                                                                    <p class="col-form-label">
                                                                        {{relative['caption']}}
                                                                    </p>
                                                                </span>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span *ngIf="family['properties'] !== undefined && family['properties']['relationship'] !== undefined">
                                                                <span *ngFor="let relationship of family['properties']['relationship']" for="staticEmail" class="col-form-label">
                                                                    <p class="col-form-label">
                                                                        {{relationship}}
                                                                    </p>
                                                                </span>
                                                            </span>
                                                        </td>

                                                    </tr>
                                                </tbody>  
                                            </table>
                                        </span> 
                                            
                                        
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Address</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['address'] !== undefined">
                                            <span *ngFor="let alias of data_list['properties']['address']" for="staticEmail" class="col-form-label">
                                                <p class="col-form-label">
                                                    {{alias}}
                                                </p>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Designation</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['position'] !== undefined">
                                            <span *ngFor="let alias of data_list['properties']['position']" for="staticEmail" class="col-form-label">
                                                <p class="col-form-label">
                                                    {{alias}}
                                                </p>
                                            </span>
                                        </span>
                                    </div>
                                </div>


                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Comments</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['notes'] !== undefined">
                                            <span *ngFor="let alias of data_list['properties']['notes']" for="staticEmail" class="col-form-label">
                                                <p class="col-form-label">
                                                    {{alias}}
                                                </p>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Listed On</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['properties'] !== undefined && data_list['properties']['modifiedAtParsed'] !== undefined">
                                            
                                            <span *ngFor="let alias of data_list['properties']['modifiedAtParsed']" for="staticEmail" class="col-form-label">
                                                <p class="col-form-label">
                                                    {{alias}}
                                                </p>
                                            </span>
                                            
                                        </span>
                                    </div>
                                </div>


                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Last Updated</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['last_change_parsed'] !== undefined">
                                            <p class="col-form-label">
                                                {{data_list['last_change_parsed']}}
                                            </p>
                                        </span>
                                    </div>
                                </div>

                                <div class="mb-3 row">
                                    <label class="col-xxl-4 col-xl-4 col-lg-4 col-auto col-form-label">
                                        <b>Source</b>
                                    </label>
                                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-auto">
                                        <span *ngIf="data_list['datasets'] !== undefined">
                                            <span *ngFor="let alias of data_list['datasets']" for="staticEmail" class="col-form-label">
                                                <p class="col-form-label">
                                                    {{alias}}
                                                </p>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <!--adverse news-->
                            <div class="collapse-success px-3 py-3 mb-3">
                                <div class="row justify-content-end">
                                    <div class="col text-success fw-bolder">
                                        Adverse News
                                    </div>
                                    <div class="col-auto">
                                        <a id="adverse" (click)="hide_link($event,'adverse')" data-bs-toggle="collapse" href=".adverse" role="button" aria-expanded="false" aria-controls="collapseExample"><img src="../../assets/images/chevron-down.svg" /></a>
                                    </div>
                                </div>
                            </div>

                            <!--adverse content-->
                            <div class="container-fluid adverse collapse">
                                <div class="row mt-5 mb-5 align-items-center">
                                    <div class="col-xxl-7 col-xl-7 col-auto">
                                        <h5>About {{commonService.toCurrency_precision(this.total_search_result,0)}} result(s)</h5>
                                    </div>
                                    <div class="col-auto">
                                        <label class="form-label">
                                            Sort By
                                        </label>
                                    </div>
                                    <div class="col-auto">
                                        <form [formGroup]="form">
                                            <select class="form-select" (change)="load_adverse()" formControlName="sort_by">
                                                <option value="date:d">Most Recent</option>
                                                <option value="review-rating:d">Top News</option>
                                            </select>  
                                        </form>
                                        
                                    </div>
                                </div>
                                
                                <hr/>
                                <div *ngFor="let dt of adverse_list_item; let x = index">
                                
                                    <div class="card mb-3 border-0">
                                        <div class="row">
                                            <div class="col-auto col-form-label"><a target="_blank" href="{{dt.link}}">{{dt.title}}</a></div>
                                        </div>
                                        <div class="row g-0">
                                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-auto">
        
                                                <img *ngIf="dt['pagemap'] === undefined || dt['pagemap']['cse_thumbnail'] === undefined" style="height:1in;width:1in;" src="../../assets/images/indiv-profile-pic.png" class="img-fluid" alt="...">
        
                                                <img *ngIf="dt['pagemap'] !== undefined && dt['pagemap']['cse_thumbnail'] !== undefined" style="height:1in;width:1in;"  src="{{dt['pagemap']['cse_thumbnail'][0]['src']}}" class="img-fluid" alt="...">
                                            </div>
                                            <div class="col-xxl-10 col-xl-10 col-lg-10 col-auto">
                                                <div class="card-body">
                                                    
                                                    <p class="card-text" [innerHTML]="dt.htmlSnippet"></p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
        
                            <div class="row container-fluid justify-content-end align-items-center mt-4 collapse adverse">
                                <div class="col-auto">
                                    <form [formGroup]="form" *ngIf="show_total_search_result">
        
                                        <div class="mb-3 row align-items-center">
                                            <label for="inputPassword" class="col-auto col-form-label font-14">
                                                Rows per page
                                            </label>
                                            <div class="col">
                                                <select style="border-style:none !important;" (change)="load_adverse()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                                    <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                                </select>
                                            </div>
                                        </div>
        
                                        
        
                                        
                                    </form>
                                </div>
        
                                <div class="col-auto">
                                    <div class="mb-3 row align-items-center">
                                        <div class="col-auto">
                                            {{row_counter}} - {{pagination_counter}} of {{commonService.toCurrency_precision(total_search_result,0)}}
                                        </div>
                                    </div>
                                    
                                </div>
        
                                <div class="col-auto">
                                    <nav aria-label="Page navigation example" *ngIf="show_total_search_result">
                                        <ul class="pagination border-opacity-10">
                                            <li class="page-item " (click)="prev()" >
                                                <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                    <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                                </a>
                                            </li>
                                         
                                            <li class="page-item" (click)="next()">
                                                <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                    <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <!--adverse content-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row view-bg2" style="min-height: 8%;">
    <div class="v2-color-side-bottom ps-4 col-xxl-2 col-xl-2 col-lg-2 col-md-2 d-sm-none d-md-block d-none d-sm-block">
        <p class="text-center">
            <small class="fw-bold" style="font-family: 'Lato', sans-serif !important;">
                <a href="https://unawa.asia/terms-conditions" class="text-white">Terms & Condition</a> <span class="text-white"> | </span> <a href="https://unawa.asia/privacy-policy" class="text-white">Privacy Policy</a>
            </small><br/>
            <small>
                <span class="text-white" style="font-family: 'Lato', sans-serif !important;">&copy; {{year_today}} </span><a href="https://unawa.asia/" class="text-white"><span class="fw-bold" style="font-family: 'Lato', sans-serif !important;">UNAWA. </span></a> <span class="text-white" style="font-family: 'Lato', sans-serif !important;"> All rights reserved</span>
            </small><br/>
            <small>
                <span class="text-white" style="font-family: 'Lato', sans-serif !important;">{{app_version}} </span>
            </small>
        </p>
    </div>
    <div class="col">

    </div>
</div>
