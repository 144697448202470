import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Globals } from '../global/global-variable';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SupportService } from '../services/support-service';
import { CommonService } from '../library/pcl_lib';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent {
    date_today = new Date()
    year_today = this.date_today.getFullYear();
    form = this.fb.group({
        action                  : ['add'],
        email                   : [this.global.login_session["username"],[Validators.required,Validators.pattern('[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
        name                    : [this.global.login_session["first_name"]+' '+this.global.login_session["last_name"],Validators.required],
        message                 : ['',Validators.required],
        attachment              : [null],
        attachment_placeholder  : [null]
    });

    field_label = {
        email : "Email",
        name : "Name",
        message : "Message",
        attachment : "Attachment",
    }

    totalFileSize = 0;

    show_add_btn = false;
    
    constructor(
        private supportService : SupportService,
        private fb : FormBuilder,
        public global : Globals,
        public commonService : CommonService,
        public toastr : ToastrService
    ){

    }

    ngOnInit(){
        //check permission
        let w_access     = false;
        const permission = this.global.login_session["permission"]["support"]
        for(const k in permission){
            if(permission[k] == 'view'){
                w_access = true;
            }//end if

            if(permission[k] == 'add'){
                this.show_add_btn = true;
            }//end if
            
            
        }//end for
        if(!w_access){
            window.location.replace('/main/dashboard');
        }//end if
        //end check permission
    }//end if

    async submit(){
        this.global.loading = true;
        const val = this.form.value;

        console.log(val.attachment_placeholder);
        
        try {

            //check permission
            let compare_val  = "";
            if(val.action == 'add'){
                compare_val = 'add';
            }else if(val.action == 'update'){
                compare_val = 'edit';
            }//end if

            let w_access     = false;
            const permission = this.global.login_session["permission"]["support"]
            for(const k in permission){
                if(permission[k] == compare_val){
                    w_access = true;
                    break;
                }//end if
            }//end for
           
            if(!w_access){
                const errorObj = {
                    code : "Access_Denied",
                    message : "You don't have permission to do this action!"
                }//end

                throw errorObj;
            }//end if

            
            //end check permission


            //validate login
            let error_html_arr = new Array();
            if(!this.form.valid){
                for(var k in this.form.controls){
                    var field = this.form.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if

                    if(field.errors?.['pattern'] !== undefined && field.errors?.['pattern']){
                        error_html_arr.push(this.field_label[k]+' is invalid format <br/>');
                        
                    }//end if

                    if(field.errors?.['minlength'] !== undefined && field.errors?.['minlength']){
                        error_html_arr.push(this.field_label[k]+' must have 6 minimum characters <br/>');
                        
                    }//end if

                    if(field.errors?.['matchValidator'] !== undefined && field.errors?.['matchValidator']){
                        error_html_arr.push(this.field_label[k]+' doesn\'t match! <br/>');
                        
                    }//end if

                    if(field.errors?.['exceed_file_size'] !== undefined && field.errors?.['exceed_file_size']){
                        error_html_arr.push(this.field_label[k]+' max size exceeded! <br/>');
                    }//end if

                    if(field.errors?.['invalid_file'] !== undefined && field.errors?.['invalid_file']){
                        error_html_arr.push(this.field_label[k]+' contains invalid file type! <br/>');
                    }//end if
                }//end for

                


            }//end if
            //end validate login

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "Require Field",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if

            //save to firebase

            

            //sanitize
            const attachments = val.attachment_placeholder;
            for(const k in val){
                if(k.includes('_placeholder')){
                    delete val[k];
                }//end if
            }//end for
            //end sanitize

            let message = {
                
                subject: 'UCheck Support Request from '+val.name+'('+val.email+')!'
                //text: val.message,
                //html: val.message
            };
            
            if(val.attachment !== null){
                var files = new Array();

                for(var s in attachments){
                    const m_data = attachments[s];
                    const attachment = m_data.file;
                    const upload_param = {
                        storage_ref : environment.storage_folder+'/'+val.email+'/email/'+attachment.name,
                        file : attachment
                    }
                    const storageUrl = await uploadTaskPromise(upload_param);
                    if(!storageUrl["success"]){
                        const errObj = {
                            code : "Upload Error",
                            message : storageUrl["data"]
                        }
                        throw errObj;
                    }else{
                        val["profile_photo"] = storageUrl["data"]["url"];
                        files.push({
                            filename: attachment.name,
                            path: storageUrl["data"]["url"]
                        })
                    }//end if
                    
                }//end if

                if(files.length > 0){
                    message["attachments"] = files;
                }//end if
                
            }//end if

            const request_param = {
                "records" : [{
                    "record_type"   : environment.support,
                    "date_created"  : new Date(),
                    "email_option" : {
                        "from" : environment.from_email,
                        "to" : environment.support_email,
                        "message" : message,
                        "template" : {
                            "email" : val.email,
                            "name"  : 'u_check_support_email',
                            "data"  : {
                                "full_name" : val.name,
                                "email" : val.email,
                                "message" : val.message,
                                "copyright" : this.year_today
                            }
                        }
                    },
                    ...val
                }]
            }

  

            this.supportService.save_data(request_param).subscribe((response) => {
                if(response["success"]){
                    this.toastr.success("Operation Successfully Completed!","System Message");
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                    
                }else{
         
                    this.toastr.error(response["data"]["message"],"System Message");
                    
                }//end if
                this.global.loading = false;
            })


        } catch (error : any) {
            this.toastr.error(error.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end 

    }

    upload(event : any){
        this.totalFileSize = 0;
        if (event.target instanceof HTMLInputElement && event.target.type === "file") {
            const files = event.target.files;
            const allowed_ext = [
                '.docx', 
                '.doc', 
                '.xlsx', 
                '.xls', 
                '.csv', 
                '.pdf', 
                '.png', 
                '.jpg', 
                '.jpeg', 
                '.svg'
            ];
            
          

            let file_arr = new Array();
            
            for(const k in files){
                if (isNaN(parseInt(k))) {
                    continue;
                }//end if
                
                const file          = files[k];
                const file_ext      = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();
              
                const KBSize        = (parseFloat(file.size) / 1024) / 1024;
                this.totalFileSize  += KBSize;

                if(!allowed_ext.includes(file_ext)){
                    this.form.controls['attachment'].setErrors({'invalid_file': true}); 
                }//end if

                
                file_arr.push({
                    file : file
                });

                /*var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    
                  file_arr.push({
                        path : reader.result
                    });
                }*/
                
            }//end if
          
            //this.totalFileSize = this.commonService.catchIsNaN(this.totalFileSize,2)

            this.form.patchValue({
                attachment_placeholder : file_arr
            });

           
            //set max file size
            if(this.totalFileSize > 25){
                this.form.controls['attachment'].setErrors({'exceed_file_size': true});  
            }//end if
            //end set max file size

            
        }//end if
       
    }


}


async function uploadTaskPromise(param) {
    let response = {
        success : false,
        data : {}
    }
    return new Promise(function(resolve, reject) {

        const storage             = getStorage();
        const storageRef          = ref(storage,param.storage_ref);
        const uploadTask          = uploadBytesResumable(storageRef, param.file);

        uploadTask.on('state_changed',
                function(snapshot) {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    //progressbar = (progress - 1);
                
                    
                }, 
                function error(err) {
                    response.success = false;
                    response.data = err;
                    reject(response)
                    
                }, 
                function complete() {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        response.success = true;
                        response.data   = {
                            url : downloadURL
                        }//end

                        //progressbar += 1;
                        resolve(response)
                        //applicant_doc.travel_doc_swab = downloadURL;
                    });
                }
            );
    })
}