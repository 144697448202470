
<div class="container-fluid">
   
    <div class="row align-items-center justify-content-start gx-3 mx-3">
        <div class="col-xxl-4 col-xl-4 ">
            <div class="card shadow py-2">
                <div class="card-body">
                    <div class="row align-items-center justify-content-center" >
                        <div class="col-auto">
                             <div id="avatar">
                                <img *ngIf="global.login_session['profile_photo'] === undefined || global.login_session['profile_photo'] === ''" id="profile_pic" src="../../assets/images/dashboard-user-profile.svg" class="main-profile-img" />
                                <img *ngIf="global.login_session['profile_photo'] !== undefined && global.login_session['profile_photo'] !== ''" id="profile_pic" src="{{global.login_session['profile_photo']}}" class="main-profile-img" />
                            </div>
                        </div>
                        <div class="col">
                            <h5 class="text-dashboard-profile">Hello, {{global.login_session["first_name"]}} {{global.login_session["last_name"]}}!</h5>
                            <small class="text-dashboard-profile-success">Professional Plan</small><br/>
                            <small class="text-dashboard-profile-text-muted">will renew on Jan 1, 2040</small>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="col-xxl-2 col-xl-2" *ngFor="let data of table_list; let x = index">
            
                <div class="card shadow py-3" *ngIf="data.record_type=='source_count'">
                    <div class="card-body">
                        <div class="row gx-0 d-flex flex-wrap align-items-center justify-content-center" >
                            <div class="col-xxl-4 col-auto">
                                <img src="../../assets/images/dashboard-source.svg" class="img-fluid" alt="...">    
                            </div>
                            <div class="col">
                                <h5 class="text-dashboard">{{lib.toCurrency_precision(data.query_result,0)}}</h5>
                                <small class="text-dashboard-muted">No. of sources</small>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div class="card shadow py-3" *ngIf="data.record_type=='individual_count'">
                    <div class="card-body ps-2 pe-1">

                        <div class="row gx-0 d-flex flex-wrap align-items-center justify-content-center" >
                            <div class="col-xxl-4 col-auto">
                                <img src="../../assets/images/dashboard-user.svg" class="img-fluid" alt="...">    

                                
                            </div>
                            <div class="col">
                                <h5 class="text-dashboard">{{lib.toCurrency_precision(data.query_result,0)}}</h5>
                                <small class="text-dashboard-muted">Total no. of individuals</small>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div class="card shadow py-3" *ngIf="data.record_type=='search_count'">
                    <div class="card-body ps-2 pe-1">

                        <div class="row gx-0 d-flex flex-wrap align-items-center justify-content-center" >
                            <div class="col-xxl-4 col-auto">
                                <img src="../../assets/images/dashboard-search.svg" class="img-fluid" alt="...">    
                            </div>
                            <div class="col">
                                <h5 class="text-dashboard">{{lib.toCurrency_precision(data.query_result,0)}}</h5>
                                <small class="text-dashboard-muted">No. of searches</small>
                            </div>
                        </div>

                        
                    </div>
                </div>
                
                <div class="card shadow py-3" *ngIf="data.record_type=='hit_count'">
                    <div class="card-body ps-2 pe-1">

                        <div class="row gx-0 d-flex flex-wrap align-items-center justify-content-center" >
                            <div class="col-xxl-4 col-auto">
                                <img src="../../assets/images/dashboard-hits.svg" class="img-fluid" alt="...">    
                            </div>
                            <div class="col">
                                <h5 class="text-dashboard">{{lib.toCurrency_precision(data.query_result,0)}}</h5>
                                <small class="text-dashboard-muted">No. of hits</small>
                            </div>
                        </div>

                        
                    </div>
                </div>
            
        </div>
    </div>
    
    

    
</div>


<div class="container-fluid mt-5">
    <div class="row justify-content-center mx-3 ">
        <div class="col-12">
                
            <div class="text-center" *ngIf="(!show_total_search_result && !global.loading && !show_no_data)">
                <div class="spinner-grow" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <p class="font-14" *ngIf="show_total_search_result">Recent Search</p>

            <div class="shadow bg-white rounded" *ngIf="show_total_search_result">
                <div class="table-responsive" style="max-height:500px;">
                    <table class="table align-middle" style="min-width:2in;">
                        <thead>
                            <tr>
                                <th class="text-muted px-3" style="min-width:2.2in;max-width:2.2in;">Date</th>
                                <th class="text-muted px-3">Searched Value</th>
                                <th class="text-muted px-3 text-center">Search Result</th>
                            
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of data_list; let x = index">
                                <td class="px-3" >{{data.date_created_str}}</td>
                                <td class="px-3 fw-bold">{{data.searched_value}}</td>
                                <td class="px-3 text-center">{{lib.toCurrency_precision(data.query_result,0)}}</td>
                                
                            </tr>
                        </tbody>
                        
                        
                    </table>
                </div>


                <div class="row container-fluid justify-content-end align-items-center mt-4">
                    <div class="col-auto">
                        <form [formGroup]="filter_form">

                            <div class="mb-3 row align-items-center">
                                <label for="inputPassword" class="col-auto col-form-label font-14">
                                    Rows per page
                                </label>
                                <div class="col">
                                    <select style="border-style:none !important;" (change)="search()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                        <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                    </select>
                                </div>
                            </div>

                        </form>
                    </div>

                    <div class="col-auto">
                        <div class="mb-3 row align-items-center">
                            <div class="col-auto">
                                {{row_counter}} - {{pagination_counter}} of {{total_search_result}}
                            </div>
                        </div>
                        
                    </div>
                    
                    <div class="col-auto">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination border-opacity-10">
                                <li class="page-item " (click)="prev()" >
                                    <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                        <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                    </a>
                                </li>
                            
                                <li class="page-item" (click)="next()">
                                    <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                        <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    
                </div>
            </div>


            <div class="card border-0 shadow" *ngIf="show_total_search_result == 0 && show_no_data">
                <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">

                    <div class="col-auto">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-auto">
                                <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                            </div>
                            <div class="col-12">
                                <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                
        </div>
    </div>
</div>