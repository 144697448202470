import { Component,ElementRef,OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SearchService } from '../services/search-service';
import { Globals } from '../global/global-variable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { delay, filter, lastValueFrom, of, shareReplay } from 'rxjs';
import { DatepickerOptions } from 'ng2-datepicker';
import { CommonService } from '../library/pcl_lib';
import { AccountService } from '../services/account-service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
    public selectedPEP     = false;
    public selectedAdverse = false;

    public enable_pep = environment.enable_pep;
    public enable_adverse = environment.enable_adverse;

    subscriptionForm = this.fb.group({
        includePEP      : [false],
        includeAdverse  : [false]

        //this.subscriptionForm.value;

    });

    form_adverse = this.fb.group({
        page_size_placeholder : [10],
    })

    form = this.fb.group({
        search_type : ['individual'],
        search_str_placeholder : [""],
        filter_all : [true],
        page_size_placeholder : [10],
        page_size_placeholder2 : [50],
        page_size_placeholder3 : [10],
        filter_reference : [''],
        filter_source_list : [''],
        filter_family_name : [''],
        filter_pob : [''],
        filter_birth_date : [null],
        filter_birth_date_day : [""],
        filter_birth_date_month : [""],
        filter_birth_date_year : [""],
        
        filter_address : [''],
        filter_comments : [''],
        filter_name_original_script : [''],
        filter_nationality : [''],
        filter_first_name : [false],
        filter_last_name : [false]
    });

    field_label = {
        "search_str_placeholder" : "Name"
    }

    //adverse variable
    public data_list_adverse : any;
    show_total_search_result_adverse = false;
    total_search_result_adverse = 0;
    total_result_adverse = 0;
    row_counter_adverse = 0;
    pagination_counter_adverse = 0;
    pageNumber_adverse = 0;
    previous_adverse = false;
    lastVisibleField_adverse = "ID";
    lastVisibleVal_adverse = "";
    prevPageVisibleVal_adverse = new Array();
    //adverse variable

    //adverse pep variable
    public data_list_adverse_pep : any;
    total_search_result2_adverse = 0;
    total_result2_adverse = 0;
    offset_adverse = 0;
    row_counter2_adverse = 0;
    pagination_counter2_adverse = 0;
    pageNumber2_adverse = 0;
    //adverse pep variable

    //wikidata variable
    public data_list_wikidata  = new Array();
    offset_wikidata = 0;
    row_counter_wikidata = 0;
    pagination_counter_wikidata = 0;
    //wikidata variable

    public data_list            : any;
    
    public pep_list_arr : any;
    public enable_elastic = environment.enable_elastic;
    loading                     = false;
    show_total_search_result    = false;
    total_search_result         = 0;
    total_result                = 0;
    search_string               = "";
    view_link                   = "";

    

    //for dropdowns
    source_list                 = new Array();
    nationality_list            = new Array();
    adverse_parameter_list      = new Array();

    //for pagination
    pagination_counter          = 0;
    previous                    = false;
    lastVisibleField            = "ID";
    lastVisibleVal              = "";
    prevPageVisibleVal          = new Array();
    pageNumber                  = 0;
    row_counter                 = 0;
    pageSize                    = [10,20,50,100];
    today = new Date();

    //for pagination pep list
    total_result2 = 0;
    total_search_result2 = 0;
    pagination_counter2 = 0;
    pageNumber2  = 0;
    offset = 0;
    row_counter2 = 0;
    pageSize2 = [10,20,50];
    pageSize3 = [10];
    show_pep_loading = false;
    show_pep_result_counter = false;
    show_sanction_counter = true;
    show_adverse_counter = false;


    date_option : DatepickerOptions = {
        minYear: this.today.getFullYear() - 100, // minimum available and selectable year
        //maxYear: getYear(new Date()) + 30, // maximum available and selectable year
        
        placeholder: 'Please pick a date', // placeholder in case date model is null | undefined, example: 'Please pick a date'
        format: 'MM/dd/yyyy', // date format to display in input
        formatTitle: 'LLLL yyyy',
        formatDays: 'EEEEE',
        //firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
        //locale: locale, // date-fns locale
        position: 'bottom',
        inputClass: 'form-control', // custom input CSS class to be applied
        
        scrollBarColor: '#dfe3e9', // in case you customize you theme, here you define scroll bar color
        enableKeyboard: true
    };

    year_min = this.today.getFullYear() - 100;
    year_max = this.today.getFullYear();
    year_arr = [];
    months = [
        {"index_name" : 1,"long_name" : "January", "short_name" : "Jan"},
        {"index_name" : 2,"long_name" : "February", "short_name" : "Feb"},
        {"index_name" : 3,"long_name" : "March", "short_name" : "Mar"},
        {"index_name" : 4,"long_name" : "April", "short_name" : "Apr"},
        {"index_name" : 5,"long_name" : "May", "short_name" : "May"},
        {"index_name" : 6,"long_name" : "June", "short_name" : "June"},
        {"index_name" : 7,"long_name" : "July", "short_name" : "Jul"},
        {"index_name" : 8,"long_name" : "August", "short_name" : "Aug"},
        {"index_name" : 9,"long_name" : "September", "short_name" : "Sept"},
        {"index_name" : 10,"long_name" : "October", "short_name" : "Oct"},
        {"index_name" : 11,"long_name" : "November", "short_name" : "Nov"},
        {"index_name" : 12,"long_name" : "December", "short_name" : "Dec"},
    ]
    days = 31

    selected_event : any;

    subscription = {};

    //@ViewChild('expand') myButton : ElementRef;
    @ViewChild('confirmation_modal_close_button') closebutton;
    constructor(
        private searchService : SearchService,
        private toastr : ToastrService,
        public global : Globals,
        private fb : FormBuilder,
        private datePipe : DatePipe,
        public lib : CommonService,
        private accountService : AccountService

        ){
    }
    async ngOnInit(){
        //check users to disable checkbox
        
        
        //check permission
        let w_access     = false;
        const permission = this.global.login_session["permission"]["search"]
        for(const k in permission){
            if(permission[k] == 'view'){
                w_access = true;
            }//end if
        }//end for
        if(!w_access){
            window.location.replace('/main/dashboard');
        }//end if
        //end check permission

        for(var x = this.year_min; x <= this.year_max; x++){
            this.year_arr.push(x)
        }//end for



        this.global.loading = true;

        var request_arr = new Array();
        request_arr.push(lastValueFrom(this.get_source_list()));
        request_arr.push(lastValueFrom(this.get_nationality_list()));
        

        Promise.allSettled(request_arr).then((r) => {
            //console.log(r);
            r.forEach(async (res, index) => {
                if (res.status === 'fulfilled') {
                    const result = res.value;
                    if(index == 0){
                        this.source_list = result["data"]["query_data"];
                    }else if(index == 1){
                        this.nationality_list = result["data"]["query_data"];
                    }else{
                        for(const k in result["data"]["hits"]){
                            const map_data = result["data"]["hits"][k];
                            this.adverse_parameter_list.push({
                                "id" : map_data["_source"]["name"],
                                "name" : map_data["_source"]["name"],
                            });
                        }//end for

                        
                    }//end if
                }//end if
            });
            
            this.global.loading = false;
        });

        /*
        const source_list = await lastValueFrom(this.get_source_list());
        console.log(source_list);
        const nationality_list = await lastValueFrom(this.get_nationality_list());
        if(source_list["success"]){
            this.source_list = source_list["data"]["query_data"];
            
        }//end if

        if(nationality_list["success"]){
            this.nationality_list = nationality_list["data"]["query_data"];
        }//end if
        //this.refresh_list("MOHAMMAD TEST sdfdsa");


        const adverse_parameter = await lastValueFrom(this.load_adverse_parameter());
        if(adverse_parameter["success"]){
            for(const k in adverse_parameter["data"]["hits"]){
                const map_data = adverse_parameter["data"]["hits"][k];
                this.adverse_parameter_list.push({
                    "id" : map_data["_source"]["name"],
                    "name" : map_data["_source"]["name"],
                });
            }//end for
        }//end if
        */

      
        

        


     
        
            
            
      
    }

    async search_wikidata(enable_loading : boolean,save_search_result : boolean, show_loading : boolean){
        var val = this.form.value;
        var schema = '';
        if(val.search_type == 'individual'){
            schema = 'Person';
        }else{
            schema = 'Company'
        }//end if
        

        if(enable_loading){
            this.global.loading = true;
        }//end if

        var request_param = {
            "action" : "wbsearchentities",
            "search" : val.search_str_placeholder.toLocaleLowerCase().trim(),
            "language" : "en",
            "format" : "json",
            "limit" : val.page_size_placeholder2,
            "continue" : this.offset_wikidata,
            "created_by": this.global.login_session["id"],
            "search_type": val.search_type,
            "save_search_result" : save_search_result
        
        }
        console.log('reqparam',request_param);
        var wikidata = await lastValueFrom(this.searchService.search_wikidata(request_param));
        if(wikidata["success"]){
            if(wikidata["data"]["search"].length > 0){
                this.data_list_wikidata = wikidata["data"]["search"];
                if(wikidata["notify"] !== undefined){
                    this.toastr.warning(wikidata["notify"]["message"],'System Message',{
                        timeOut: 10000,
                        enableHtml : true
                    });
                }//end if

                this.row_counter2 = (this.pageNumber2 * val.page_size_placeholder2) + 1;
            }//end if
            

            if(enable_loading){
                this.global.loading = false;
            }//end if
        }else{
            if(enable_loading){
                this.global.loading = false;
            }//end if
            var error_message = wikidata["data"]["error"];
            this.toastr.error(error_message["message"],"System Message");

        }//end if
    
           
        

    }//end



    search_wikidata_promise(enable_loading : boolean,save_search_result : boolean, show_loading : boolean){
        var val = this.form.value;
        var schema = '';
        if(val.search_type == 'individual'){
            schema = 'Person';
        }else{
            schema = 'Company'
        }//end if
        

    

        var request_param = {
            "action" : "wbsearchentities",
            "search" : val.search_str_placeholder.toLocaleLowerCase().trim(),
            "language" : "en",
            "format" : "json",
            "limit" : val.page_size_placeholder2,
            "continue" : this.offset_wikidata,
            "created_by": this.global.login_session["id"],
            "search_type": val.search_type,
            "save_search_result" : save_search_result
        
        }
        console.log('request_param',request_param)
        return this.searchService.search_wikidata(request_param).pipe(
            shareReplay(1)
        );
    }//end


    async refresh_pep_list(enable_loading : boolean){
       
        var val = this.form.value;
        var schema = '';
        if(val.search_type == 'individual'){
            schema = 'Person';
        }else{
            schema = 'Company'
        }//end if

        
        var query_filter = "limit="+val.page_size_placeholder2+"&offset="+this.offset+"&schema="+schema;

        

        

        var open_sanction_filters = {
            "created_by" : this.global.login_session["id"],
            "search_type" : val.search_type,
            "queries" : {
                
                "q1" : {
                    "properties": {
                        "name": [
                            val.search_str_placeholder
                        ],
                    }
                },
                "schema": "Person"
            }
        }

        //console.log('limit',val.page_size_placeholder2);
        //console.log('offset',this.offset);
        //console.log(query_filter);
        if(enable_loading){
            //this.global.loading = true;
        }//end if
        
        var pep_list = await lastValueFrom(this.searchService.open_api_search(open_sanction_filters,query_filter));
        console.log('open_sanction_filters',open_sanction_filters);
        console.log('open_sanction_filters',query_filter);
        if(pep_list["success"]){
            if(pep_list["notify"] !== undefined){
                this.toastr.warning(pep_list["notify"]["message"],'System Message',{
                    timeOut: 10000,
                    enableHtml : true
                });
            }//end if

            this.pep_list_arr = pep_list["data"]["results"];

            this.total_search_result2        = pep_list["data"]["total"].value;
            this.total_result2               = pep_list["data"]["total"].value;
            console.log('total_search_result2',this.total_search_result2);
            this.offset                     = (this.pageNumber2 * val.page_size_placeholder2)
            this.row_counter2                = (this.pageNumber2 * val.page_size_placeholder2) + 1;
            this.pagination_counter2         = this.row_counter2 + (this.pep_list_arr.length - 1);

            //-------------------parse-------------------
            for(const s in this.pep_list_arr){
                var m_data = this.pep_list_arr[s];

                if(m_data["properties"]["country"] !== undefined){
                    var tmp = new Array();
                    for(var j in m_data["properties"]["country"]){
                        const mm_data = m_data["properties"]["country"][j];
                        if(this.lib.map_countries(mm_data.toUpperCase()) !== undefined){
                            tmp.push(this.lib.map_countries(mm_data.toUpperCase()));
                        }//end if
                        
                    }
                    
                    m_data["properties"]["country_parsed"] = tmp.join(",");
                    
            
                }//end if
                

                this.pep_list_arr[s] = m_data;

            }
            //-------------------parse-------------------

            

            console.log('this.pep_list_arr',this.pep_list_arr);
        }//end if
        if(enable_loading){
            //this.global.loading = false;
        }//end if
    }



    async refresh_pep_list_adverse(enable_loading : boolean){

        
       
        var val = this.form.value;
        var schema = '';
        if(val.search_type == 'individual'){
            schema = 'Person';
        }else{
            schema = 'Company'
        }//end if

        
        var query_filter = "limit="+val.page_size_placeholder3+"&offset="+this.offset_adverse+"&schema="+schema;

        

        

        var open_sanction_filters = {
            "created_by" : this.global.login_session["id"],
            "search_type" : val.search_type,
            "queries" : {
                
                "q1" : {
                    "properties": {
                        "name": [
                            val.search_str_placeholder
                        ],
                    }
                },
                "schema": "Person"
            }
        }

      
        if(enable_loading){
            this.global.loading = true;
        }//end if
        
        var pep_list = await lastValueFrom(this.searchService.open_api_search(open_sanction_filters,query_filter));
        console.log('pep_list',pep_list);
        if(pep_list["success"]){
            this.data_list_adverse_pep = pep_list["data"]["results"];

            this.total_search_result2_adverse        = pep_list["data"]["total"].value;
            this.total_result2_adverse               = pep_list["data"]["total"].value;
            console.log('total_search_result2',this.total_search_result2);
            this.offset_adverse                     = (this.pageNumber2 * val.page_size_placeholder2)
            this.row_counter2_adverse                = (this.pageNumber2 * val.page_size_placeholder2) + 1;
            this.pagination_counter2_adverse         = this.row_counter2 + (this.pep_list_arr.length - 1);

            //-------------------parse-------------------
            for(const s in this.data_list_adverse_pep){
                var m_data = this.data_list_adverse_pep[s];

                if(m_data["properties"]["country"] !== undefined){
                    var tmp = new Array();
                    for(var j in m_data["properties"]["country"]){
                        const mm_data = m_data["properties"]["country"][j];
                        if(this.lib.map_countries(mm_data.toUpperCase()) !== undefined){
                            tmp.push(this.lib.map_countries(mm_data.toUpperCase()));
                        }//end if
                        
                    }
                    
                    m_data["properties"]["country_parsed"] = tmp.join(",");
                    
            
                }//end if
                

                this.data_list_adverse_pep[s] = m_data;

            }
            //-------------------parse-------------------

        }//end if
        if(enable_loading){
            this.global.loading = false;
        }//end if
    }

    async refresh_adverse_list(enable_loading : boolean){
       
        var val = this.form.value;
        var schema = '';
        if(val.search_type == 'individual'){
            schema = 'Person';
        }else{
            schema = 'Company'
        }//end if

        var open_sanction_filters = {
            "created_by" : this.global.login_session["id"],
            "search_type" : val.search_type,
            "queries" : {
                
                "q1" : {
                    "properties": {
                        "name": [
                            val.search_str_placeholder
                        ]
                    }
                },
                "schema": "Person"
            }
        }

        console.log('limit',val.page_size_placeholder2);
        console.log('offset',this.offset);
        if(enable_loading){
            this.global.loading = true;
        }//end if
        
        var pep_list = await lastValueFrom(this.searchService.open_api_search(open_sanction_filters,"limit="+val.page_size_placeholder2+"&offset="+this.offset+"&schema="+schema));
        console.log('pep_list',pep_list);
        if(pep_list["success"]){
            this.pep_list_arr = pep_list["data"]["results"];

            this.total_search_result2        = pep_list["data"]["total"].value;
            this.total_result2               = pep_list["data"]["total"].value;
            console.log('total_search_result2',this.total_search_result2);
            this.offset                     = (this.pageNumber2 * val.page_size_placeholder2)
            this.row_counter2                = (this.pageNumber2 * val.page_size_placeholder2) + 1;
            this.pagination_counter2         = this.row_counter2 + (this.pep_list_arr.length - 1);

            //-------------------parse-------------------
            for(const s in this.pep_list_arr){
                var m_data = this.pep_list_arr[s];

                if(m_data["properties"]["country"] !== undefined){
                    var tmp = new Array();
                    for(var j in m_data["properties"]["country"]){
                        const mm_data = m_data["properties"]["country"][j];
                        if(this.lib.map_countries(mm_data.toUpperCase()) !== undefined){
                            tmp.push(this.lib.map_countries(mm_data.toUpperCase()));
                        }//end if
                        
                    }
                    
                    m_data["properties"]["country_parsed"] = tmp.join(",");
                    
            
                }//end if
                

                this.pep_list_arr[s] = m_data;

            }
            //-------------------parse-------------------

            console.log('this.pep_list_arr',this.pep_list_arr);
        }//end if
        if(enable_loading){
            this.global.loading = false;
        }//end if
    }

    refresh_list(search_str : string,save_search_result : boolean,show_loading : boolean){
        const val = this.form.value;
        if(show_loading){
            this.global.loading = true;
        }//end if
        //
        try {

            var filters = {};
            var open_sanction_filters = {};
            var open_sanction_prop = {};
            
            open_sanction_prop["properties"] = {};
            if(search_str.trim() !== ""){
                if(filters["must"] === undefined){
                    filters["must"] = new Array();
                }//end if

                if(val.search_type == 'individual'){
                    open_sanction_prop["schema"] = "Person";
                    open_sanction_prop["properties"]["name"] = [search_str.trim().toLocaleLowerCase()];

                    //---------------------individual---------------------
                    filters["must"].push({
                        "bool" : {
                            "should" : [
                                {
                                    "fuzzy" : {
                                        
                                        "FULL_NAME" : search_str.trim().toLocaleLowerCase()
                                    }
                                },
                                /*{
                                    "match" : {
                                        "FULL_NAME" : {
                                            "query" : search_str.trim().toLocaleLowerCase(),
                                            "fuzziness": "auto"
                                        }
                                    }
                                },*/
                                {
                                    "wildcard" : {
                                        "FULL_NAME" : "*"+search_str.trim().toLocaleLowerCase()+"*"
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "FULL_NAME" : search_str.trim().trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "combined_fields" : {
                                        "query":      search_str.trim().toLocaleLowerCase(),
                                        "fields":     [ "FIRST_NAME","SECOND_NAME", "THIRD_NAME", "FOURTH_NAME"],
                                        "operator":   "and"
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_ALIAS.ALIAS_NAME" : search_str.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "fuzzy" : {
                                        "INDIVIDUAL_ALIAS.ALIAS_NAME" : search_str.trim().toLocaleLowerCase()
                                    }
                                },
                                /*{
                                    "match" : {
                                        "INDIVIDUAL_ALIAS.ALIAS_NAME" : {
                                            "query" : search_str.trim().toLocaleLowerCase(),
                                            "fuzziness": "auto"
                                        }
                                    }
                                },*/
                                {
                                    "wildcard" : {
                                        "INDIVIDUAL_ALIAS.ALIAS_NAME" : "*"+search_str.trim().toLocaleLowerCase()+"*"
                                    }
                                }
                            ]
                        }
                    })
                    //---------------------individual---------------------
                }else{
                    

                    //-----------------------entity-----------------------
                    filters["must"].push({
                        "bool" : {
                            "should" : [
                                /*{
                                    "terms" : { 
                                        "FIRST_NAME" : search_str.trim().toLocaleLowerCase().split(" ") 
                                    }
                                },*/
                                {
                                    "fuzzy" : {
                                        "FIRST_NAME" : search_str.trim().toLocaleLowerCase()
                                    }
                                },
                                /*{
                                    "match" : {
                                        "FIRST_NAME" : {
                                            "query" : search_str.trim().toLocaleLowerCase(),
                                            "fuzziness": "auto"
                                        }
                                    }
                                },*/
                                {
                                    "wildcard" : {
                                        "FIRST_NAME" : "*"+search_str.trim().toLocaleLowerCase()+"*"
                                    }
                                },
                                
                                {
                                    "match_phrase_prefix" : {
                                        "FIRST_NAME" : search_str.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "ENTITY_ALIAS.ALIAS_NAME" : search_str.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "fuzzy" : {
                                        "ENTITY_ALIAS.ALIAS_NAME" : search_str.trim().toLocaleLowerCase()
                                    }
                                },
                                /*{
                                    "match" : {
                                        "ENTITY_ALIAS.ALIAS_NAME" : {
                                            "query" : search_str.trim().toLocaleLowerCase(),
                                            "fuzziness": "auto"
                                        }
                                    }
                                },*/
                                {
                                    "wildcard" : {
                                        "ENTITY_ALIAS.ALIAS_NAME" : "*"+search_str.trim().toLocaleLowerCase()+"*"
                                    }
                                }
                            ]
                        }
                    })
                    //-----------------------entity-----------------------
                }

                
            }//end if


            if(val.filter_reference.trim() !== ""){
                
                open_sanction_prop["properties"]["referents"] = [val.filter_reference.trim().toLocaleLowerCase()];

                if(filters["must"] === undefined){
                    filters["must"] = new Array();
                }//end if
               
                //---------------------individual/entity---------------------
                filters["must"].push({
                    "match_phrase" : {
                        "REFERENCE_NUMBER" : val.filter_reference.trim().toLocaleLowerCase()
                    }
                })
                //---------------------individual/entity---------------------
                

            }//end if


            if(val.filter_nationality.trim() !== ""){
                open_sanction_prop["properties"]["nationality"] = [val.filter_nationality.trim().toLocaleLowerCase()];
                

                if(val.search_type == 'individual'){
                    //---------------------individual---------------------
                    if(filters["must"] === undefined){
                        filters["must"] = new Array();
                    }//end if

                    filters["must"].push({
                        "match_phrase" : {
                            "NATIONALITY" : val.filter_nationality.trim().toLocaleLowerCase()
                        }
                    })
                    //---------------------individual---------------------
                }//end if
            }//end if

            if(val.search_type == 'individual'){
                //---------------------individual---------------------
                
                

                if((val.filter_birth_date_month !== undefined && val.filter_birth_date_month !== "") || (val.filter_birth_date_day !== undefined && val.filter_birth_date_day !== "") || (val.filter_birth_date_year !== undefined && val.filter_birth_date_year !== "")) {
                    //partial date is set
                    if(filters["must"] === undefined){
                        filters["must"] = new Array();
                    }//end if

                    var placeholder = "";
                    var combined_date = "";
                    var combined_date2 = "";
                    var combined_date3 = "";
                    var combined_date4 = "";
                    var combined_date5 = "";
                    var combined_date6 = "";
                    var day_format1 = ""; //[5] without leading zeroes
                    var day_format2 = ""; //[05] without leading zeroes
                    var month_format1 = "";//[Dec] short name of months
                    var month_format2 = "";//[December] long name of months
                    var month_format3 = "";//5
                    var month_format4 = "";//05
                    var year_format = "";
                    

                    var filter_date = {};
                    filter_date["bool"] = {};
                    filter_date["bool"]["should"] = new Array();
                    
                    if(val.filter_birth_date_day !== "" && val.filter_birth_date_day !== undefined){
                        day_format1 = val.filter_birth_date_day;
                        day_format2 = val.filter_birth_date_day;
                        day_format2 = day_format2.padStart(2,'0');
                    }//end if

                    if(val.filter_birth_date_month !== "" && val.filter_birth_date_month !== null && val.filter_birth_date_month !== undefined){
                        month_format1 = this.months[parseInt(val.filter_birth_date_month) - 1]['short_name'].trim().toLocaleLowerCase();
                        month_format2 = this.months[parseInt(val.filter_birth_date_month) - 1]['long_name'].trim().toLocaleLowerCase();
                        month_format3 = this.months[parseInt(val.filter_birth_date_month) - 1]['index_name'].toString();
                        month_format4 = month_format3.padStart(2,'0');
                    }//end if

                    if(val.filter_birth_date_year !== "" && val.filter_birth_date_year !== undefined){
                        year_format = val.filter_birth_date_year;
                    }//end if

                    

                    combined_date = day_format1 + " " + month_format1 + " " + year_format; //5 Dec
                    combined_date = combined_date.trim();
                    combined_date2 = day_format1 + " " + month_format2 + " " + year_format; //5 December
                    combined_date2 = combined_date2.trim();
                    combined_date3 = day_format2 + " " + month_format1 + " " + year_format;//05 Dec
                    combined_date3 = combined_date3.trim();
                    combined_date4 = day_format2 + " " + month_format2 + " " + year_format;//05 December
                    combined_date4 = combined_date4.trim();

                    combined_date5 = year_format + " " + month_format3 + " " + day_format1;//2024-6-5
                    combined_date5 = combined_date5.trim();

                    combined_date5 = combined_date5.replace(/ /g,'-');

                    combined_date6 = year_format + " " + month_format4 + " " + day_format2;//2024-06-05
                    combined_date6 = combined_date6.trim();
                    combined_date6 = combined_date6.replace(/ /g,'-');


                    open_sanction_prop["properties"]["birthDate"] = [combined_date5,combined_date6]; //end if

                    

                    filter_date["bool"]["should"].push(
                        {
                            "match_phrase" : {
                                "INDIVIDUAL_DATE_OF_BIRTH.DATE" : combined_date
                            }
                        },
                        {
                            "match_phrase" : {
                                "INDIVIDUAL_DATE_OF_BIRTH.DATE" : combined_date2
                            }
                        },
                        {
                            "match_phrase" : {
                                "INDIVIDUAL_DATE_OF_BIRTH.DATE" : combined_date3
                            }
                        }
                        ,
                        {
                            "match_phrase" : {
                                "INDIVIDUAL_DATE_OF_BIRTH.DATE" : combined_date4
                            }
                        } 
                    );

                    if(val.filter_birth_date_year !== "" && val.filter_birth_date_year !== undefined){
                        
                        filter_date["bool"]["should"].push(
                           
                            {
                                "match_phrase" : {
                                    "INDIVIDUAL_DATE_OF_BIRTH.YEAR" : year_format
                                }
                            },
                            {
                                "wildcard" : {
                                    "INDIVIDUAL_DATE_OF_BIRTH.YEAR" : "*"+year_format+"*"
                                }
                            },
                            {
                                "match_phrase_prefix" : {
                                    "INDIVIDUAL_DATE_OF_BIRTH.FROM_YEAR" : year_format
                                }
                            },
                            {
                                "wildcard" : {
                                    "INDIVIDUAL_DATE_OF_BIRTH.FROM_YEAR" : "*"+year_format+"*"
                                }
                            },
                            {
                                "match_phrase_prefix" : {
                                    "INDIVIDUAL_DATE_OF_BIRTH.TO_YEAR" : year_format
                                }
                            },
                            {
                                "wildcard" : {
                                    "INDIVIDUAL_DATE_OF_BIRTH.TO_YEAR" : "*"+year_format+"*"
                                }
                            }
                        )
                    }//end if


                    filter_date["bool"]["should"].push(
                        {
                            "match_phrase" : {
                                "INDIVIDUAL_DATE_OF_BIRTH.DATE_PLACEHOLDER" : combined_date
                            }
                        }
                    )
                    
                    filters["must"].push(filter_date);
                   
                }
                    console.log('filters',filters)
                //}//end if

                
                //---------------------individual---------------------
            }//end if


            if(val.filter_source_list.trim() !== ""){
                if(filters["must"] === undefined){
                    filters["must"] = new Array();
                }//end if

                

               
                //---------------------individual/entity---------------------
                filters["must"].push({
                    "match_phrase" : {
                        "SOURCE_LIST" : val.filter_source_list.trim().toLocaleLowerCase()
                    }
                })
                //---------------------individual/entity---------------------
               
            }//end if



            if(val.search_type == 'individual'){
                //---------------------individual---------------------
                if(val.filter_pob.trim() !== ""){
                    if(filters["must"] === undefined){
                        filters["must"] = new Array();
                    }//end if

                    filters["must"].push({
                        "bool" : {
                            "should" : [
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_PLACE_OF_BIRTH.CITY" : val.filter_pob.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_PLACE_OF_BIRTH.STATE_PROVINCE" : val.filter_pob.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_PLACE_OF_BIRTH.NOTE" : val.filter_pob.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_PLACE_OF_BIRTH.STREET" : val.filter_pob.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_PLACE_OF_BIRTH.COUNTRY" : val.filter_pob.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_PLACE_OF_BIRTH.PLACEOFBIRTH" : val.filter_pob.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "combined_fields" : {
                                        "query":      val.filter_pob.trim().toLocaleLowerCase(),
                                        "fields":     [ "INDIVIDUAL_PLACE_OF_BIRTH.STREET","INDIVIDUAL_PLACE_OF_BIRTH.STATE_PROVINCE", "INDIVIDUAL_PLACE_OF_BIRTH.CITY", "INDIVIDUAL_PLACE_OF_BIRTH.COUNTRY"],
                                        "operator":   "and"
                                    }
                                }
                            ]
                        }
                    })
                }//end if
                //---------------------individual---------------------
            }//end if

            if(val.search_type == 'individual'){
                //---------------------individual---------------------
                if(val.filter_address.trim() !== ""){
                    if(filters["must"] === undefined){
                        filters["must"] = new Array();
                    }//end if

                    filters["must"].push({
                        "bool" : {
                            "should" : [
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_ADDRESS.CITY" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_ADDRESS.STATE_PROVINCE" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_ADDRESS.NOTE" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_ADDRESS.STREET" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_ADDRESS.COUNTRY" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "combined_fields" : {
                                        "query":      val.filter_address.trim().toLocaleLowerCase(),
                                        "fields":     [ "INDIVIDUAL_ADDRESS.STREET","INDIVIDUAL_ADDRESS.STATE_PROVINCE", "INDIVIDUAL_ADDRESS.CITY", "INDIVIDUAL_ADDRESS.COUNTRY"],
                                        "operator":   "and"
                                    }
                                }
                            ]
                        }
                    })
                }//end if

                
                //---------------------individual---------------------
            }else{
                //---------------------entity---------------------
                if(val.filter_address.trim() !== ""){
                    if(filters["must"] === undefined){
                        filters["must"] = new Array();
                    }//end if

                    filters["must"].push({
                        "bool" : {
                            "should" : [
                                {
                                    "match_phrase_prefix" : {
                                        "ENTITY_ADDRESS.CITY" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "ENTITY_ADDRESS.STATE_PROVINCE" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "ENTITY_ADDRESS.NOTE" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "ENTITY_ADDRESS.STREET" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "ENTITY_ADDRESS.COUNTRY" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "combined_fields" : {
                                        "query":      val.filter_address.trim().toLocaleLowerCase(),
                                        "fields":     [ "ENTITY_ADDRESS.STREET","ENTITY_ADDRESS.STATE_PROVINCE", "ENTITY_ADDRESS.CITY", "ENTITY_ADDRESS.COUNTRY"],
                                        "operator":   "and"
                                    }
                                }
                            ]
                        }
                    })
                }//end if
                //---------------------entity---------------------
            }//end if



            //comments filter
            if(val.filter_comments.trim() !== ""){
                if(filters["must"] === undefined){
                    filters["must"] = new Array();
                }//end if

                filters["must"].push({
                    "bool" : {
                        "should" : [
                            {
                                "match_phrase_prefix" : {
                                    "COMMENTS1" : val.filter_comments.trim().toLocaleLowerCase()
                                }
                            },
                            {
                                "wildcard" : {
                                    "COMMENTS1" : "*"+val.filter_comments.trim().toLocaleLowerCase()+"*"
                                }
                            }
                        ]
                    }
                })
            }//end if


            //name original script filter
            if(val.filter_name_original_script.trim() !== ""){
                if(filters["must"] === undefined){
                    filters["must"] = new Array();
                }//end if

                filters["must"].push({
                    "bool" : {
                        "should" : [
                            {
                                "match_phrase_prefix" : {
                                    "NAME_ORIGINAL_SCRIPT" : val.filter_name_original_script.trim().toLocaleLowerCase()
                                }
                            },
                            {
                                "wildcard" : {
                                    "NAME_ORIGINAL_SCRIPT" : "*"+val.filter_name_original_script.trim().toLocaleLowerCase()+"*"
                                }
                            },
                            {
                                "fuzzy" : {
                                    "NAME_ORIGINAL_SCRIPT" : search_str.trim().toLocaleLowerCase()
                                }
                            }
                        ]
                    }
                })
            }//end if
            

            if(filters["must"] === undefined){
                filters["must"] = new Array();

                
                filters["must"].push({
                    "bool" : {
                        "should" : [
                            {
                                "match" : {
                                    "FULL_NAME" : {
                                        "query" : search_str.trim().toLocaleLowerCase()
                                    }
                                }
                            }
                        ]
                    }
                })
            }//end if

            

            //sort
            open_sanction_filters["sort"] = ["caption:asc","id:asc"];
            open_sanction_filters = {
                "queries" : {
                    "q1" : open_sanction_prop,
                    
                }
                
            }
            var param = {
                "from" : (this.pageNumber * val.page_size_placeholder),
                "size" : val.page_size_placeholder,
                "created_by" : this.global.login_session["id"],
                "search_type" : val.search_type,
                "save_search_result" : save_search_result,
                "query" : {
                    "bool" : {
                        ...filters
                    }   
                }
            };

    

           
            var index = environment.elastic_index;
            if(val.search_type == 'individual'){
                index = environment.elastic_index;
            }else{
                index = environment.elastic_index_entity;
            }//end if

            
            this.searchService.elasticsearchList(index,param).subscribe(result => {
                
                if(result["success"]){

                    if(result["notify"] !== undefined){
                        this.toastr.warning(result["notify"]["message"],'System Message',{
                            timeOut: 10000,
                            enableHtml : true
                        });
                    }//end if

                    const hits = result["data"]["hits"];
                    var hits_result = hits["hits"];
                    //console.log(hits_result);

                    //manifest
                    
                    this.view_link = "/search-result/generate/"+val.search_type+'/'+result["data"]["search_id"];
                    //end manifest

                    for(const k in hits_result){
                        var fields = hits_result[k]['_source'];
                        for(const x in fields){
                            switch (x) {
                                case 'LISTED_ON' :
                                    fields[x] = new Date(fields[x]);
                                    if (Object.prototype.toString.call(fields[x]) === "[object Date]") {
                                        // it is a date
                                        if (isNaN(fields[x])) { // d.getTime() or d.valueOf() will also work
                                            fields["LISTED_ON_PARSED"] = "";
                                        } else {
                                            // date object is valid
                                            fields["LISTED_ON_PARSED"] = this.datePipe.transform((fields[x]), 'MMM dd, yyyy');
                                        }//end if
                                    }else{
                                        fields["LISTED_ON_PARSED"] = "";
                                    }//end
                                break;
                                
                                case ("INDIVIDUAL_ALIAS") :
                                    
                                    var html = "";
                                    if (fields[x] !== null && Array.isArray(fields[x]) ) {
                                        // if array
                                        fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]    = 0;
                                        fields["INDIVIDUAL_ALIAS_PARSED"]          = fields[x];

                                        let alias = (fields[x]);
                                        
                                        for(const y in alias){
                                            if(alias[y]["QUALITY"] == 'Good' || alias[y]["QUALITY"] == 'a.k.a.'){
                                                if(fields["INDIVIDUAL_ALIAS_G"] === undefined){
                                                    fields["INDIVIDUAL_ALIAS_G"] = new Array();
                                                }//end if

                                                html += "<b>Good</b>";
                                                html += "<hr/>";
                                                html += alias[y]["ALIAS_NAME"] + "<br/>";

                                                fields["INDIVIDUAL_ALIAS_G"].push({
                                                    QUALITY : alias[y]["QUALITY"],
                                                    ALIAS_NAME: alias[y]["ALIAS_NAME"]
                                                });

                                                fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]+=1;
                                            }//end if


                                            if(alias[y]["QUALITY"] == 'Low'){
                                                if(fields["INDIVIDUAL_ALIAS_L"] === undefined){
                                                    fields["INDIVIDUAL_ALIAS_L"] = new Array();
                                                }//end if
                                                fields["INDIVIDUAL_ALIAS_L"].push({
                                                    QUALITY : alias[y]["QUALITY"],
                                                    ALIAS_NAME: alias[y]["ALIAS_NAME"]
                                                });
                                                fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]+=1;
                                            }//end if
                                        }//end for

                                        fields["INDIVIDUAL_ALIAS_HTML"] = html;

                                    }else{
                                        fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]    = 1;
                                        fields["INDIVIDUAL_ALIAS_PARSED"]          = fields[x];
                                        fields["INDIVIDUAL_ALIAS_HTML"]            = html;
                                    }//end if
                                
                                break;

                                case "ENTITY_ALIAS":
                                    
                                    var html = "";
                                    if (fields[x] !== null && Array.isArray(fields[x]) ) {
                                        // if array
                                        fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]    = 0;
                                        fields["INDIVIDUAL_ALIAS_PARSED"]          = fields[x];

                                        let alias = (fields[x]);
                                        
                                        for(const y in alias){
                                            if(alias[y]["QUALITY"] == 'Good' || alias[y]["QUALITY"] == 'a.k.a.'){
                                                if(fields["INDIVIDUAL_ALIAS_G"] === undefined){
                                                    fields["INDIVIDUAL_ALIAS_G"] = new Array();
                                                }//end if

                                                html += "<b>Good</b>";
                                                html += "<hr/>";
                                                html += alias[y]["ALIAS_NAME"] + "<br/>";

                                                fields["INDIVIDUAL_ALIAS_G"].push({
                                                    QUALITY : alias[y]["QUALITY"],
                                                    ALIAS_NAME: alias[y]["ALIAS_NAME"]
                                                });

                                                fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]+=1;
                                            }//end if


                                            if(alias[y]["QUALITY"] == 'Low'){
                                                if(fields["INDIVIDUAL_ALIAS_L"] === undefined){
                                                    fields["INDIVIDUAL_ALIAS_L"] = new Array();
                                                }//end if
                                                fields["INDIVIDUAL_ALIAS_L"].push({
                                                    QUALITY : alias[y]["QUALITY"],
                                                    ALIAS_NAME: alias[y]["ALIAS_NAME"]
                                                });
                                                fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]+=1;
                                            }//end if
                                        }//end for

                                        fields["INDIVIDUAL_ALIAS_HTML"] = html;

                                    }else{
                                        fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]    = 1;
                                        fields["INDIVIDUAL_ALIAS_PARSED"]          = fields[x];
                                        fields["INDIVIDUAL_ALIAS_HTML"]            = html;
                                    }//end if
                                
                                break;
                            }//end switch

                            
                        }//end for
                    }//end for
                    console.log('pcl');
                    console.log(hits_result);
                    this.data_list                  = hits_result;
                    this.total_search_result        = hits["total"]["value"];
                    this.total_result               = hits["total"]["value"];
                    this.show_total_search_result   = true;
                    this.row_counter                = (this.pageNumber * val.page_size_placeholder) + 1;
                    this.pagination_counter         = this.row_counter + (this.data_list.length - 1);
                }else{
                    const errorObj = {
                        code : "Firebase_Error",
                        message : JSON.stringify(result["data"])
                    }//end

                    throw errorObj;
                }//end if

                if(show_loading){
                    this.global.loading = false;
                }//end if

                
            });
            

            
        }catch(e : any){
            return e;
            //this.toastr.error(e["message"],"System Message");
            //this.global.loading = false;
        }//end  try
    }




    refresh_list_promise(search_str : string,save_search_result : boolean,show_loading : boolean){
        const val = this.form.value;
   
        //
        try {

            var filters = {};
            var open_sanction_filters = {};
            var open_sanction_prop = {};
            
            open_sanction_prop["properties"] = {};
            if(search_str.trim() !== ""){
                if(filters["must"] === undefined){
                    filters["must"] = new Array();
                }//end if

                if(val.search_type == 'individual'){
                    open_sanction_prop["schema"] = "Person";
                    open_sanction_prop["properties"]["name"] = [search_str.trim().toLocaleLowerCase()];

                    //---------------------individual---------------------
                    filters["must"].push({
                        "bool" : {
                            "should" : [
                                {
                                    "fuzzy" : {
                                        
                                        "FULL_NAME" : search_str.trim().toLocaleLowerCase()
                                    }
                                },
                                /*{
                                    "match" : {
                                        "FULL_NAME" : {
                                            "query" : search_str.trim().toLocaleLowerCase(),
                                            "fuzziness": "auto"
                                        }
                                    }
                                },*/
                                {
                                    "wildcard" : {
                                        "FULL_NAME" : "*"+search_str.trim().toLocaleLowerCase()+"*"
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "FULL_NAME" : search_str.trim().trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "combined_fields" : {
                                        "query":      search_str.trim().toLocaleLowerCase(),
                                        "fields":     [ "FIRST_NAME","SECOND_NAME", "THIRD_NAME", "FOURTH_NAME"],
                                        "operator":   "and"
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_ALIAS.ALIAS_NAME" : search_str.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "fuzzy" : {
                                        "INDIVIDUAL_ALIAS.ALIAS_NAME" : search_str.trim().toLocaleLowerCase()
                                    }
                                },
                                /*{
                                    "match" : {
                                        "INDIVIDUAL_ALIAS.ALIAS_NAME" : {
                                            "query" : search_str.trim().toLocaleLowerCase(),
                                            "fuzziness": "auto"
                                        }
                                    }
                                },*/
                                {
                                    "wildcard" : {
                                        "INDIVIDUAL_ALIAS.ALIAS_NAME" : "*"+search_str.trim().toLocaleLowerCase()+"*"
                                    }
                                }
                            ]
                        }
                    })
                    //---------------------individual---------------------
                }else{
                    

                    //-----------------------entity-----------------------
                    filters["must"].push({
                        "bool" : {
                            "should" : [
                                /*{
                                    "terms" : { 
                                        "FIRST_NAME" : search_str.trim().toLocaleLowerCase().split(" ") 
                                    }
                                },*/
                                {
                                    "fuzzy" : {
                                        "FIRST_NAME" : search_str.trim().toLocaleLowerCase()
                                    }
                                },
                                /*{
                                    "match" : {
                                        "FIRST_NAME" : {
                                            "query" : search_str.trim().toLocaleLowerCase(),
                                            "fuzziness": "auto"
                                        }
                                    }
                                },*/
                                {
                                    "wildcard" : {
                                        "FIRST_NAME" : "*"+search_str.trim().toLocaleLowerCase()+"*"
                                    }
                                },
                                
                                {
                                    "match_phrase_prefix" : {
                                        "FIRST_NAME" : search_str.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "ENTITY_ALIAS.ALIAS_NAME" : search_str.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "fuzzy" : {
                                        "ENTITY_ALIAS.ALIAS_NAME" : search_str.trim().toLocaleLowerCase()
                                    }
                                },
                                /*{
                                    "match" : {
                                        "ENTITY_ALIAS.ALIAS_NAME" : {
                                            "query" : search_str.trim().toLocaleLowerCase(),
                                            "fuzziness": "auto"
                                        }
                                    }
                                },*/
                                {
                                    "wildcard" : {
                                        "ENTITY_ALIAS.ALIAS_NAME" : "*"+search_str.trim().toLocaleLowerCase()+"*"
                                    }
                                }
                            ]
                        }
                    })
                    //-----------------------entity-----------------------
                }

                
            }//end if


            if(val.filter_reference.trim() !== ""){
                
                open_sanction_prop["properties"]["referents"] = [val.filter_reference.trim().toLocaleLowerCase()];

                if(filters["must"] === undefined){
                    filters["must"] = new Array();
                }//end if
               
                //---------------------individual/entity---------------------
                filters["must"].push({
                    "match_phrase" : {
                        "REFERENCE_NUMBER" : val.filter_reference.trim().toLocaleLowerCase()
                    }
                })
                //---------------------individual/entity---------------------
                

            }//end if


            if(val.filter_nationality.trim() !== ""){
                open_sanction_prop["properties"]["nationality"] = [val.filter_nationality.trim().toLocaleLowerCase()];
                

                if(val.search_type == 'individual'){
                    //---------------------individual---------------------
                    if(filters["must"] === undefined){
                        filters["must"] = new Array();
                    }//end if

                    filters["must"].push({
                        "match_phrase" : {
                            "NATIONALITY" : val.filter_nationality.trim().toLocaleLowerCase()
                        }
                    })
                    //---------------------individual---------------------
                }//end if
            }//end if

            if(val.search_type == 'individual'){
                //---------------------individual---------------------
                
                

                if((val.filter_birth_date_month !== undefined && val.filter_birth_date_month !== "") || (val.filter_birth_date_day !== undefined && val.filter_birth_date_day !== "") || (val.filter_birth_date_year !== undefined && val.filter_birth_date_year !== "")) {
                    //partial date is set
                    if(filters["must"] === undefined){
                        filters["must"] = new Array();
                    }//end if

                    var placeholder = "";
                    var combined_date = "";
                    var combined_date2 = "";
                    var combined_date3 = "";
                    var combined_date4 = "";
                    var combined_date5 = "";
                    var combined_date6 = "";
                    var day_format1 = ""; //[5] without leading zeroes
                    var day_format2 = ""; //[05] without leading zeroes
                    var month_format1 = "";//[Dec] short name of months
                    var month_format2 = "";//[December] long name of months
                    var month_format3 = "";//5
                    var month_format4 = "";//05
                    var year_format = "";
                    

                    var filter_date = {};
                    filter_date["bool"] = {};
                    filter_date["bool"]["should"] = new Array();
                    
                    if(val.filter_birth_date_day !== "" && val.filter_birth_date_day !== undefined){
                        day_format1 = val.filter_birth_date_day;
                        day_format2 = val.filter_birth_date_day;
                        day_format2 = day_format2.padStart(2,'0');
                    }//end if

                    if(val.filter_birth_date_month !== "" && val.filter_birth_date_month !== null && val.filter_birth_date_month !== undefined){
                        month_format1 = this.months[parseInt(val.filter_birth_date_month) - 1]['short_name'].trim().toLocaleLowerCase();
                        month_format2 = this.months[parseInt(val.filter_birth_date_month) - 1]['long_name'].trim().toLocaleLowerCase();
                        month_format3 = this.months[parseInt(val.filter_birth_date_month) - 1]['index_name'].toString();
                        month_format4 = month_format3.padStart(2,'0');
                    }//end if

                    if(val.filter_birth_date_year !== "" && val.filter_birth_date_year !== undefined){
                        year_format = val.filter_birth_date_year;
                    }//end if

                    

                    combined_date = day_format1 + " " + month_format1 + " " + year_format; //5 Dec
                    combined_date = combined_date.trim();
                    combined_date2 = day_format1 + " " + month_format2 + " " + year_format; //5 December
                    combined_date2 = combined_date2.trim();
                    combined_date3 = day_format2 + " " + month_format1 + " " + year_format;//05 Dec
                    combined_date3 = combined_date3.trim();
                    combined_date4 = day_format2 + " " + month_format2 + " " + year_format;//05 December
                    combined_date4 = combined_date4.trim();

                    combined_date5 = year_format + " " + month_format3 + " " + day_format1;//2024-6-5
                    combined_date5 = combined_date5.trim();

                    combined_date5 = combined_date5.replace(/ /g,'-');

                    combined_date6 = year_format + " " + month_format4 + " " + day_format2;//2024-06-05
                    combined_date6 = combined_date6.trim();
                    combined_date6 = combined_date6.replace(/ /g,'-');


                    open_sanction_prop["properties"]["birthDate"] = [combined_date5,combined_date6]; //end if

                    

                    filter_date["bool"]["should"].push(
                        {
                            "match_phrase" : {
                                "INDIVIDUAL_DATE_OF_BIRTH.DATE" : combined_date
                            }
                        },
                        {
                            "match_phrase" : {
                                "INDIVIDUAL_DATE_OF_BIRTH.DATE" : combined_date2
                            }
                        },
                        {
                            "match_phrase" : {
                                "INDIVIDUAL_DATE_OF_BIRTH.DATE" : combined_date3
                            }
                        }
                        ,
                        {
                            "match_phrase" : {
                                "INDIVIDUAL_DATE_OF_BIRTH.DATE" : combined_date4
                            }
                        } 
                    );

                    if(val.filter_birth_date_year !== "" && val.filter_birth_date_year !== undefined){
                        
                        filter_date["bool"]["should"].push(
                           
                            {
                                "match_phrase" : {
                                    "INDIVIDUAL_DATE_OF_BIRTH.YEAR" : year_format
                                }
                            },
                            {
                                "wildcard" : {
                                    "INDIVIDUAL_DATE_OF_BIRTH.YEAR" : "*"+year_format+"*"
                                }
                            },
                            {
                                "match_phrase_prefix" : {
                                    "INDIVIDUAL_DATE_OF_BIRTH.FROM_YEAR" : year_format
                                }
                            },
                            {
                                "wildcard" : {
                                    "INDIVIDUAL_DATE_OF_BIRTH.FROM_YEAR" : "*"+year_format+"*"
                                }
                            },
                            {
                                "match_phrase_prefix" : {
                                    "INDIVIDUAL_DATE_OF_BIRTH.TO_YEAR" : year_format
                                }
                            },
                            {
                                "wildcard" : {
                                    "INDIVIDUAL_DATE_OF_BIRTH.TO_YEAR" : "*"+year_format+"*"
                                }
                            }
                        )
                    }//end if


                    filter_date["bool"]["should"].push(
                        {
                            "match_phrase" : {
                                "INDIVIDUAL_DATE_OF_BIRTH.DATE_PLACEHOLDER" : combined_date
                            }
                        }
                    )
                    
                    filters["must"].push(filter_date);
                   
                }
                    console.log('filters',filters)
                //}//end if

                
                //---------------------individual---------------------
            }//end if


            if(val.filter_source_list.trim() !== ""){
                if(filters["must"] === undefined){
                    filters["must"] = new Array();
                }//end if

                

               
                //---------------------individual/entity---------------------
                filters["must"].push({
                    "match_phrase" : {
                        "SOURCE_LIST" : val.filter_source_list.trim().toLocaleLowerCase()
                    }
                })
                //---------------------individual/entity---------------------
               
            }//end if



            if(val.search_type == 'individual'){
                //---------------------individual---------------------
                if(val.filter_pob.trim() !== ""){
                    if(filters["must"] === undefined){
                        filters["must"] = new Array();
                    }//end if

                    filters["must"].push({
                        "bool" : {
                            "should" : [
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_PLACE_OF_BIRTH.CITY" : val.filter_pob.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_PLACE_OF_BIRTH.STATE_PROVINCE" : val.filter_pob.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_PLACE_OF_BIRTH.NOTE" : val.filter_pob.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_PLACE_OF_BIRTH.STREET" : val.filter_pob.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_PLACE_OF_BIRTH.COUNTRY" : val.filter_pob.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_PLACE_OF_BIRTH.PLACEOFBIRTH" : val.filter_pob.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "combined_fields" : {
                                        "query":      val.filter_pob.trim().toLocaleLowerCase(),
                                        "fields":     [ "INDIVIDUAL_PLACE_OF_BIRTH.STREET","INDIVIDUAL_PLACE_OF_BIRTH.STATE_PROVINCE", "INDIVIDUAL_PLACE_OF_BIRTH.CITY", "INDIVIDUAL_PLACE_OF_BIRTH.COUNTRY"],
                                        "operator":   "and"
                                    }
                                }
                            ]
                        }
                    })
                }//end if
                //---------------------individual---------------------
            }//end if

            if(val.search_type == 'individual'){
                //---------------------individual---------------------
                if(val.filter_address.trim() !== ""){
                    if(filters["must"] === undefined){
                        filters["must"] = new Array();
                    }//end if

                    filters["must"].push({
                        "bool" : {
                            "should" : [
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_ADDRESS.CITY" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_ADDRESS.STATE_PROVINCE" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_ADDRESS.NOTE" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_ADDRESS.STREET" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "INDIVIDUAL_ADDRESS.COUNTRY" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "combined_fields" : {
                                        "query":      val.filter_address.trim().toLocaleLowerCase(),
                                        "fields":     [ "INDIVIDUAL_ADDRESS.STREET","INDIVIDUAL_ADDRESS.STATE_PROVINCE", "INDIVIDUAL_ADDRESS.CITY", "INDIVIDUAL_ADDRESS.COUNTRY"],
                                        "operator":   "and"
                                    }
                                }
                            ]
                        }
                    })
                }//end if

                
                //---------------------individual---------------------
            }else{
                //---------------------entity---------------------
                if(val.filter_address.trim() !== ""){
                    if(filters["must"] === undefined){
                        filters["must"] = new Array();
                    }//end if

                    filters["must"].push({
                        "bool" : {
                            "should" : [
                                {
                                    "match_phrase_prefix" : {
                                        "ENTITY_ADDRESS.CITY" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "ENTITY_ADDRESS.STATE_PROVINCE" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "ENTITY_ADDRESS.NOTE" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "ENTITY_ADDRESS.STREET" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "match_phrase_prefix" : {
                                        "ENTITY_ADDRESS.COUNTRY" : val.filter_address.trim().toLocaleLowerCase()
                                    }
                                },
                                {
                                    "combined_fields" : {
                                        "query":      val.filter_address.trim().toLocaleLowerCase(),
                                        "fields":     [ "ENTITY_ADDRESS.STREET","ENTITY_ADDRESS.STATE_PROVINCE", "ENTITY_ADDRESS.CITY", "ENTITY_ADDRESS.COUNTRY"],
                                        "operator":   "and"
                                    }
                                }
                            ]
                        }
                    })
                }//end if
                //---------------------entity---------------------
            }//end if



            //comments filter
            if(val.filter_comments.trim() !== ""){
                if(filters["must"] === undefined){
                    filters["must"] = new Array();
                }//end if

                filters["must"].push({
                    "bool" : {
                        "should" : [
                            {
                                "match_phrase_prefix" : {
                                    "COMMENTS1" : val.filter_comments.trim().toLocaleLowerCase()
                                }
                            },
                            {
                                "wildcard" : {
                                    "COMMENTS1" : "*"+val.filter_comments.trim().toLocaleLowerCase()+"*"
                                }
                            }
                        ]
                    }
                })
            }//end if


            //name original script filter
            if(val.filter_name_original_script.trim() !== ""){
                if(filters["must"] === undefined){
                    filters["must"] = new Array();
                }//end if

                filters["must"].push({
                    "bool" : {
                        "should" : [
                            {
                                "match_phrase_prefix" : {
                                    "NAME_ORIGINAL_SCRIPT" : val.filter_name_original_script.trim().toLocaleLowerCase()
                                }
                            },
                            {
                                "wildcard" : {
                                    "NAME_ORIGINAL_SCRIPT" : "*"+val.filter_name_original_script.trim().toLocaleLowerCase()+"*"
                                }
                            },
                            {
                                "fuzzy" : {
                                    "NAME_ORIGINAL_SCRIPT" : search_str.trim().toLocaleLowerCase()
                                }
                            }
                        ]
                    }
                })
            }//end if
            

            if(filters["must"] === undefined){
                filters["must"] = new Array();

                
                filters["must"].push({
                    "bool" : {
                        "should" : [
                            {
                                "match" : {
                                    "FULL_NAME" : {
                                        "query" : search_str.trim().toLocaleLowerCase()
                                    }
                                }
                            }
                        ]
                    }
                })
            }//end if

            console.log(filters);

            //sort
            open_sanction_filters["sort"] = ["caption:asc","id:asc"];
            open_sanction_filters = {
                "queries" : {
                    "q1" : open_sanction_prop,
                    
                }
                
            }
            var param = {
                "from" : (this.pageNumber * val.page_size_placeholder),
                "size" : val.page_size_placeholder,
                "created_by" : this.global.login_session["id"],
                "search_type" : val.search_type,
                "save_search_result" : save_search_result,
                "query" : {
                    "bool" : {
                        ...filters
                    }   
                }
            };

    

           
            var index = environment.elastic_index;
            if(val.search_type == 'individual'){
                index = environment.elastic_index;
            }else{
                index = environment.elastic_index_entity;
            }//end if

            return this.searchService.elasticsearchList(index,param).pipe(
                shareReplay(1)
            );

        }catch(e : any){
            return e;
        }//end  try
    }//end

    refresh_list2(search_str : string){

        
        
        const val = this.form.value;

       // alert(val.filter_birth_date);
        
        this.global.loading = true;
        try{

          

            let filters         = {};

          
           
            

            var consolidated_filter = new Array();

            if(search_str.trim() !== ""){
                if(filters["filterFieldArr"] === undefined){
                    filters["filterFieldArr"]       = new Array();
                    filters["filterOperatorArr"]    = new Array();
                    filters["filterValueArr"]       = new Array();
                    filters["orderByFieldArr"]      = new Array();
                    filters["sortOrderArr"]         = new Array();
                }//end if

                var search_str_arr = search_str.trim().toLowerCase();

                filters["filterFieldArr"].push("SEARCH_FULL_NAME");
                filters["filterOperatorArr"].push("array-contains");
                filters["filterValueArr"].push(search_str_arr);
                
                
                //consolidated_filter.push(search_str.toLowerCase().trim());
            }else{
                if(filters["filterFieldArr"] === undefined){
                    filters["filterFieldArr"]       = new Array();
                    filters["filterOperatorArr"]    = new Array();
                    filters["filterValueArr"]       = new Array();
                    filters["orderByFieldArr"]      = new Array();
                    filters["sortOrderArr"]         = new Array();
                }//end if

                if(val.search_type == 'individual'){
                    filters["orderByFieldArr"].push("FULL_NAME");
                    filters["orderByFieldArr"].push("DATAID");
                }else{
                    filters["orderByFieldArr"].push("FIRST_NAME");
                    filters["orderByFieldArr"].push("ID");
                }//end if
                filters["sortOrderArr"].push("asc");
                filters["sortOrderArr"].push("asc");
            }//end if

            if(val.filter_reference.trim() !== ""){
                if(filters["filterFieldArr"] === undefined){
                    filters["filterFieldArr"]       = new Array();
                    filters["filterOperatorArr"]    = new Array();
                    filters["filterValueArr"]       = new Array();
                    filters["orderByFieldArr"]      = new Array();
                    filters["sortOrderArr"]         = new Array();
                }//end if
                

                filters["filterFieldArr"].push("SEARCH_REFERENCE_NUMBER");
                filters["filterOperatorArr"].push("==");
                filters["filterValueArr"].push(val.filter_reference.toLowerCase().trim());

               
            }//end if


            if(val.filter_source_list.trim() !== ""){
                if(filters["filterFieldArr"] === undefined){
                    filters["filterFieldArr"]       = new Array();
                    filters["filterOperatorArr"]    = new Array();
                    filters["filterValueArr"]       = new Array();
                    filters["orderByFieldArr"]      = new Array();
                    filters["sortOrderArr"]         = new Array();
                }//end if
                

                filters["filterFieldArr"].push("SEARCH_SOURCE_LIST");
                filters["filterOperatorArr"].push("==");
                filters["filterValueArr"].push(val.filter_source_list.toLowerCase().trim());

                
            }//end if

        

            var index = environment.individual;
            if(val.search_type == 'individual'){
                index = environment.individual;
            }else{
                index = environment.entity;
            }//end if


            const data_param = {
                "record_type" : index,
                "insert_search_count" : true,
                "insert_hit_count" : true,
                
                "pageNumber" : this.pageNumber,
                "lastVisibleField" : this.lastVisibleField,
                "lastVisibleVal" : this.lastVisibleVal,
                "previous" : this.previous,
                "pageSize" : val.page_size_placeholder,
                "created_by" : this.global.login_session["id"],
                "search_type" : val.search_type,
                ...filters
            }//end

           


            this.searchService.filterList(data_param).subscribe(result => {
                console.log(result);
                if(result["success"]){

                    //manifest
                    console.log('test');
                    this.view_link = "/search-result/"+val.search_type+'/'+result["search_id"];
                    //end manifest

                    //parse data
                    for(const k in result["data"]["query_data"]){
                        const data = result["data"]["query_data"][k];
                        
                        for(const x in data){
                            switch (x) {
                                case 'LISTED_ON' :
                                    data[x] = new Date(data[x]);
                                    if (Object.prototype.toString.call(data[x]) === "[object Date]") {
                                        // it is a date
                                        if (isNaN(data[x])) { // d.getTime() or d.valueOf() will also work
                                            result["data"]["query_data"][k]["LISTED_ON_PARSED"] = "";
                                        } else {
                                            // date object is valid
                                            result["data"]["query_data"][k]["LISTED_ON_PARSED"] = this.datePipe.transform((data[x]), 'MMM dd, yyyy');
                                        }//end if
                                    }else{
                                        result["data"]["query_data"][k]["LISTED_ON_PARSED"] = "";
                                    }//end
                                break;
                                case "INDIVIDUAL_ALIAS" :   
                                    
                                    var html = "";
                                    if (data[x] !== null && Array.isArray(data[x]) ) {
                                        // if array
                                        result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED_COUNT"]    = 0;
                                        result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED"]          = data[x];

                                        let alias = (data[x]);
                                        
                                        for(const y in alias){
                                            if(alias[y]["QUALITY"] == 'Good'){
                                                if(result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_G"] === undefined){
                                                    result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_G"] = new Array();
                                                }//end if

                                                html += "<b>Good</b>";
                                                html += "<hr/>";
                                                html += alias[y]["ALIAS_NAME"] + "<br/>";

                                                result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_G"].push({
                                                    QUALITY : alias[y]["QUALITY"],
                                                    ALIAS_NAME: alias[y]["ALIAS_NAME"]
                                                });

                                                result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED_COUNT"]+=1;
                                            }//end if


                                            if(alias[y]["QUALITY"] == 'Low'){
                                                if(result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_L"] === undefined){
                                                    result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_L"] = new Array();
                                                }//end if
                                                result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_L"].push({
                                                    QUALITY : alias[y]["QUALITY"],
                                                    ALIAS_NAME: alias[y]["ALIAS_NAME"]
                                                });
                                                result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED_COUNT"]+=1;
                                            }//end if
                                        }//end for

                                        result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_HTML"] = html;

                                    }else{
                                        result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED_COUNT"]    = 1;
                                        result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED"]          = data[x];
                                        result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_HTML"]            = html;
                                    }//end if
                                
                                break;


                                case "ENTITY_ALIAS" :   
                                    
                                    var html = "";
                                    if (data[x] !== null && Array.isArray(data[x]) ) {
                                        // if array
                                        result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED_COUNT"]    = 0;
                                        result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED"]          = data[x];

                                        let alias = (data[x]);
                                        
                                        for(const y in alias){
                                            if(alias[y]["QUALITY"] == 'Good' || alias[y]["QUALITY"] == 'a.k.a.'){
                                                if(result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_G"] === undefined){
                                                    result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_G"] = new Array();
                                                }//end if

                                                html += "<b>Good</b>";
                                                html += "<hr/>";
                                                html += alias[y]["ALIAS_NAME"] + "<br/>";

                                                result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_G"].push({
                                                    QUALITY : alias[y]["QUALITY"],
                                                    ALIAS_NAME: alias[y]["ALIAS_NAME"]
                                                });

                                                result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED_COUNT"]+=1;
                                            }//end if


                                            if(alias[y]["QUALITY"] == 'Low'){
                                                if(result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_L"] === undefined){
                                                    result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_L"] = new Array();
                                                }//end if
                                                result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_L"].push({
                                                    QUALITY : alias[y]["QUALITY"],
                                                    ALIAS_NAME: alias[y]["ALIAS_NAME"]
                                                });
                                                result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED_COUNT"]+=1;
                                            }//end if
                                        }//end for

                                        result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_HTML"] = html;

                                    }else{
                                        result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED_COUNT"]    = 1;
                                        result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_PARSED"]          = data[x];
                                        result["data"]["query_data"][k]["INDIVIDUAL_ALIAS_HTML"]            = html;
                                    }//end if
                                
                                break;
                            }//end switch
                        }//end for
                    }//end for
                    //end parse data

                    this.data_list                  = result["data"]["query_data"];
                    this.total_search_result        = result["data"]["total_result"];
                    this.total_result               = result["data"]["total_result"];
                    this.show_total_search_result   = true;
                    this.row_counter                = (this.pageNumber * val.page_size_placeholder) + 1;
                    this.pagination_counter         = this.row_counter + (this.data_list.length - 1);
                    //for pagination
                    
                    
                    
                    //alert(this.total_result);

                }else{
                    this.toastr.error(result["data"]["message"],"System Message");
                }//end if
                
                this.global.loading = false;
            });

            
        }catch(e : any){
            this.toastr.error(e["message"],"System Message");
        }//end  try
    }//end if

    setPagination(){
        const val = this.form.value;


        

    }

    sync_data(){
        this.global.loading = true;
        const table_data = this.searchService.sync_data();
        table_data.subscribe((result) => {
            if(result["success"]){
                if(result["data"]["total_created"] !== undefined && result["data"]["total_created"] > 0){
                    alert("Data Synced Total Created : "+result["data"]["total_created"]);
                }else{
                    alert("No data synced!");
                }//end if
           }else{
             alert("Error : "+ JSON.stringify(result["data"]));
           }//end if

           this.global.loading = false;
        })
    }//end

    check(e : any,name){
        const field_to_skip = ["page_size_placeholder","search_str_placeholder"];
        for(const k in this.form.controls){
            if(name == k || field_to_skip.includes(k)){
                continue;
            }//end
            this.form.controls[k].setValue(false);
        }//end
        
    }//end 
    
    checkInclude(e : any,name){
        var val = this.subscriptionForm.value;
        
        

        if(val.includePEP){
            // alert('PEP Checked');
            this.selectedPEP = true;
        }else{
            
            this.selectedPEP = false;

            this.reset_search_wiki();

            

            //adverse pep variable
            /*this.data_list_adverse_pep = new Array();
            this.total_search_result2_adverse = 0;
            this.total_result2_adverse = 0;
            this.offset_adverse = 0;
            this.row_counter2_adverse = 0;
            this.pagination_counter2_adverse = 0;
            this.pageNumber2_adverse = 0;*/
            //adverse pep variable
        }

        if(val.includeAdverse){
            // alert('Adverse Checked');
            this.selectedAdverse = true;
        }else{
            this.selectedAdverse = false;

            this.reset_search_adverse();
        }

       
        
    }//end 

    prev(){
        const val           = this.form.value;
        if(this.pageNumber > 0){
            this.pageNumber -= 1;
            this.previous = true;
            this.lastVisibleVal         = this.prevPageVisibleVal[this.pageNumber];
            
            if(environment.enable_elastic){
                this.refresh_list(val.search_str_placeholder,false,true)
            }else{
                this.refresh_list2(val.search_str_placeholder)
            }
        }//end if
        
        
        
    }

    /*prev_adverse(){
        const val           = this.form.value;
        const val2          = this.form_adverse.value;
        if(this.pageNumber_adverse > 0){
            this.pageNumber_adverse -= 1;
            this.previous_adverse = true;
            this.lastVisibleVal_adverse         = this.prevPageVisibleVal_adverse[this.pageNumber_adverse];
            
            if(environment.enable_elastic){
                this.refresh_list(val.search_str_placeholder)
            }else{
                this.refresh_list2(val.search_str_placeholder)
            }
        }//end if
        
        
        
    }

    next_adverse(){
        const val           = this.form_adverse.value;
        const val2 = this.form.value;
        const total_pages   = Math.ceil((this.total_result_adverse / val.page_size_placeholder));
        
       
        if(total_pages > (this.pageNumber_adverse + 1)){
            
            this.pageNumber_adverse             += 1;
            this.previous_adverse               = false;
            this.lastVisibleVal_adverse         = this.data_list_adverse[this.data_list_adverse.length - 1]["ID"];

            if(!this.prevPageVisibleVal_adverse.includes(this.data_list_adverse[0]["ID"])){
                this.prevPageVisibleVal_adverse.push(this.data_list_adverse[0]["ID"]);
            }//end if

            
            if(environment.enable_elastic){
                this.refresh_list(val2.search_str_placeholder)
            }else{
                this.refresh_list2(val2.search_str_placeholder)
            }//end if
            
        }//end if
    }*/

    next(){
        const val           = this.form.value;
        const total_pages   = Math.ceil((this.total_result / val.page_size_placeholder));
        
       
        if(total_pages > (this.pageNumber + 1)){
            
            this.pageNumber             += 1;
            this.previous               = false;
            this.lastVisibleVal         = this.data_list[this.data_list.length - 1]["ID"];

            if(!this.prevPageVisibleVal.includes(this.data_list[0]["ID"])){
                this.prevPageVisibleVal.push(this.data_list[0]["ID"]);
            }//end if

            
            if(environment.enable_elastic){
                this.refresh_list(val.search_str_placeholder,false,true)
            }else{
                this.refresh_list2(val.search_str_placeholder)
            }
            
        }//end if
    }

    /*next2(){
        const val           = this.form.value;
        const total_pages   = Math.ceil((this.total_result2 / val.page_size_placeholder2));
        
       
        if(total_pages > (this.pageNumber2 + 1)){
            
            this.pageNumber2 += 1;
            this.offset += 1;
            
            this.refresh_pep_list(true);
            
        }//end if
    }*/
   next2(){
        const val           = this.form.value;
        
        this.pageNumber2 += 1;
        this.offset_wikidata += val.page_size_placeholder2;
        this.search_wikidata(true,false,true);
   }

    next2_adverse(){
        const val           = this.form.value;
        const total_pages   = Math.ceil((this.total_result2_adverse / val.page_size_placeholder3));
        
       
        if(total_pages > (this.pageNumber2_adverse + 1)){
            
            this.pageNumber2_adverse += 1;
            this.offset_adverse += 1;
            
            this.refresh_pep_list_adverse(true);
            
        }//end if
    }

    prev2_adverse(){
        const val           = this.form.value;
        if(this.pageNumber2_adverse > 0){
            this.pageNumber2_adverse -= 1;
            this.offset_adverse -= 1;
            this.refresh_pep_list_adverse(true);
        }//end if
        
    }

    /*prev2(){
        const val           = this.form.value;
        if(this.pageNumber2 > 0){
            this.pageNumber2 -= 1;
            this.offset -= 1;
            this.refresh_pep_list(true);
        }//end if
        
    }*/
    
    prev2(){
        const val           = this.form.value;
        if(this.pageNumber2 > 0){
            this.pageNumber2 -= 1;
            this.offset_wikidata -= val.page_size_placeholder2;
            this.search_wikidata(true,false,true);
        }//end if
    }
    

     search(save_search_result = true){
        //let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
        this.global.loading = true;
        try {
            const val = this.form.value;
            const subcriptionVal = this.subscriptionForm.value;



            let error_html_arr = new Array();
            if(!this.form.valid){
                for(var k in this.form.controls){
                    var field = this.form.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                    }//end if
                }//end for
            }//end if
            //end validate login

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "Require Field",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if


            this.previous                    = false;
            this.lastVisibleField            = "ID";
            this.lastVisibleVal              = "";
            this. prevPageVisibleVal          = new Array();
            this.pageNumber                  = 0;
            this.pageNumber2 = 0;
            this.offset = 0;

            this.reset_search_wiki();
            this.reset_search_adverse();

           
            var request_arr = new Array();
            var request_map = new Array();
            
            if(environment.enable_elastic){
                request_map.push("sanction");
                request_arr.push(lastValueFrom(this.refresh_list_promise(val.search_str_placeholder,save_search_result,false)));
                
            }else{
                request_map.push("sanction");
                request_arr.push(this.refresh_list2(val.search_str_placeholder));
                //this.refresh_list2(val.search_str_placeholder)
            }//end if
            //this.refresh_pep_list(false);

            if(subcriptionVal.includePEP){
                request_map.push("wikidata");
                request_arr.push(lastValueFrom(this.search_wikidata_promise(false,true,false)));
            }//end if
    
            if(subcriptionVal.includeAdverse){
                request_map.push("adverse");
                request_arr.push(this.load_adverse_promise(true,false));
            }//end if

            

            Promise.allSettled(request_arr).then((r) => {
                
                r.forEach((res, index) => {
                    if (res.status === 'fulfilled') {
                        
                        if(request_map[index] == 'sanction'){
                            const result = res.value;
                            //--------------------------sanction first index--------------------------
                            if(result["success"]){

                                if(result["notify"] !== undefined){
                                    this.toastr.warning(result["notify"]["message"],'System Message',{
                                        timeOut: 10000,
                                        enableHtml : true
                                    });
                                }//end if
            
                                const hits = result["data"]["hits"];
                                var hits_result = hits["hits"];
                                //console.log(hits_result);
            
                                //manifest
                                
                                this.view_link = "/search-result/generate/"+val.search_type+'/'+result["data"]["search_id"];
                                //end manifest
            
                                for(const k in hits_result){
                                    var fields = hits_result[k]['_source'];
                                    for(const x in fields){
                                        switch (x) {
                                            case 'LISTED_ON' :
                                                fields[x] = new Date(fields[x]);
                                                if (Object.prototype.toString.call(fields[x]) === "[object Date]") {
                                                    // it is a date
                                                    if (isNaN(fields[x])) { // d.getTime() or d.valueOf() will also work
                                                        fields["LISTED_ON_PARSED"] = "";
                                                    } else {
                                                        // date object is valid
                                                        fields["LISTED_ON_PARSED"] = this.datePipe.transform((fields[x]), 'MMM dd, yyyy');
                                                    }//end if
                                                }else{
                                                    fields["LISTED_ON_PARSED"] = "";
                                                }//end
                                            break;
                                            
                                            case ("INDIVIDUAL_ALIAS") :
                                                
                                                var html = "";
                                                if (fields[x] !== null && Array.isArray(fields[x]) ) {
                                                    // if array
                                                    fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]    = 0;
                                                    fields["INDIVIDUAL_ALIAS_PARSED"]          = fields[x];
            
                                                    let alias = (fields[x]);
                                                    
                                                    for(const y in alias){
                                                        if(alias[y]["QUALITY"] == 'Good' || alias[y]["QUALITY"] == 'a.k.a.'){
                                                            if(fields["INDIVIDUAL_ALIAS_G"] === undefined){
                                                                fields["INDIVIDUAL_ALIAS_G"] = new Array();
                                                            }//end if
            
                                                            html += "<b>Good</b>";
                                                            html += "<hr/>";
                                                            html += alias[y]["ALIAS_NAME"] + "<br/>";
            
                                                            fields["INDIVIDUAL_ALIAS_G"].push({
                                                                QUALITY : alias[y]["QUALITY"],
                                                                ALIAS_NAME: alias[y]["ALIAS_NAME"]
                                                            });
            
                                                            fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]+=1;
                                                        }//end if
            
            
                                                        if(alias[y]["QUALITY"] == 'Low'){
                                                            if(fields["INDIVIDUAL_ALIAS_L"] === undefined){
                                                                fields["INDIVIDUAL_ALIAS_L"] = new Array();
                                                            }//end if
                                                            fields["INDIVIDUAL_ALIAS_L"].push({
                                                                QUALITY : alias[y]["QUALITY"],
                                                                ALIAS_NAME: alias[y]["ALIAS_NAME"]
                                                            });
                                                            fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]+=1;
                                                        }//end if
                                                    }//end for
            
                                                    fields["INDIVIDUAL_ALIAS_HTML"] = html;
            
                                                }else{
                                                    fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]    = 1;
                                                    fields["INDIVIDUAL_ALIAS_PARSED"]          = fields[x];
                                                    fields["INDIVIDUAL_ALIAS_HTML"]            = html;
                                                }//end if
                                            
                                            break;
            
                                            case "ENTITY_ALIAS":
                                                
                                                var html = "";
                                                if (fields[x] !== null && Array.isArray(fields[x]) ) {
                                                    // if array
                                                    fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]    = 0;
                                                    fields["INDIVIDUAL_ALIAS_PARSED"]          = fields[x];
            
                                                    let alias = (fields[x]);
                                                    
                                                    for(const y in alias){
                                                        if(alias[y]["QUALITY"] == 'Good' || alias[y]["QUALITY"] == 'a.k.a.'){
                                                            if(fields["INDIVIDUAL_ALIAS_G"] === undefined){
                                                                fields["INDIVIDUAL_ALIAS_G"] = new Array();
                                                            }//end if
            
                                                            html += "<b>Good</b>";
                                                            html += "<hr/>";
                                                            html += alias[y]["ALIAS_NAME"] + "<br/>";
            
                                                            fields["INDIVIDUAL_ALIAS_G"].push({
                                                                QUALITY : alias[y]["QUALITY"],
                                                                ALIAS_NAME: alias[y]["ALIAS_NAME"]
                                                            });
            
                                                            fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]+=1;
                                                        }//end if
            
            
                                                        if(alias[y]["QUALITY"] == 'Low'){
                                                            if(fields["INDIVIDUAL_ALIAS_L"] === undefined){
                                                                fields["INDIVIDUAL_ALIAS_L"] = new Array();
                                                            }//end if
                                                            fields["INDIVIDUAL_ALIAS_L"].push({
                                                                QUALITY : alias[y]["QUALITY"],
                                                                ALIAS_NAME: alias[y]["ALIAS_NAME"]
                                                            });
                                                            fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]+=1;
                                                        }//end if
                                                    }//end for
            
                                                    fields["INDIVIDUAL_ALIAS_HTML"] = html;
            
                                                }else{
                                                    fields["INDIVIDUAL_ALIAS_PARSED_COUNT"]    = 1;
                                                    fields["INDIVIDUAL_ALIAS_PARSED"]          = fields[x];
                                                    fields["INDIVIDUAL_ALIAS_HTML"]            = html;
                                                }//end if
                                            
                                            break;
                                        }//end switch
            
                                       
                                    }//end for
                                }//end for
                                console.log('pcl');
                                console.log(hits_result);
                                this.data_list                  = hits_result;
                                this.total_search_result        = hits["total"]["value"];
                                this.total_result               = hits["total"]["value"];
                                this.show_total_search_result   = true;
                                this.row_counter                = (this.pageNumber * val.page_size_placeholder) + 1;
                                this.pagination_counter         = this.row_counter + (this.data_list.length - 1);
                            }else{
                                const errorObj = {
                                    code : "Firebase_Error",
                                    message : JSON.stringify(result["data"])
                                }//end
            
                                throw errorObj;
                            }//end if
        
                           
                            //--------------------------sanction first index--------------------------
                        }else if(request_map[index] == 'wikidata'){
                            const result = res.value;
                            //--------------------------wikidata second index--------------------------
                            var wikidata = result;
                            
                            if(wikidata["success"]){
                                this.data_list_wikidata = wikidata["data"]["search"];
                                if(wikidata["notify"] !== undefined){
                                    this.toastr.warning(wikidata["notify"]["message"],'System Message',{
                                        timeOut: 10000,
                                        enableHtml : true
                                    });
                                }//end if
                                this.row_counter2 = (this.pageNumber2 * val.page_size_placeholder2) + 1;
                            }else{
                                var error_message = wikidata["data"]["error"];
                                this.toastr.error(error_message["message"],"System Message");
                            }//end if
                            //--------------------------wikidata second index--------------------------
                        }else if(request_map[index] == 'adverse'){
                            const result = (res.value);
                            //--------------------------adverse third index-------------------------- 
                            this.adverse_list = JSON.parse(result);
                            console.log('r');

                            if(this.adverse_list["notify"] !== undefined){
                                this.toastr.warning(this.adverse_list["notify"]["message"],'System Message',{
                                    timeOut: 10000,
                                    enableHtml : true
                                });
                            }//end if
                            

                            if(this.adverse_list["data"]["searchInformation"]["totalResults"] > 0){
                                //console.log(this.adverse_list["data"]["searchInformation"]["totalResults"])
                                this.adverse_list_item = this.adverse_list["data"]["items"];
                                this.total_search_result_adverse  = parseInt(this.adverse_list["data"]["searchInformation"]["totalResults"]);
                                this.row_counter_adverse = (this.pageNumber_adverse * val.page_size_placeholder) + 1;
                                this.pagination_counter_adverse= this.row_counter_adverse + (this.adverse_list_item.length - 1);
                            }else{
                                this.adverse_list_item = new Array();
                                this.total_search_result_adverse  = parseInt(this.adverse_list["data"]["searchInformation"]["totalResults"]);
                                this.row_counter_adverse = 1;
                                this.pagination_counter_adverse= this.row_counter_adverse + (this.adverse_list_item.length - 1);
                            }//end if
                            
                            //--------------------------adverse third index--------------------------
                        }//end if

                    }//end if   
                  
                    
                });

                this.global.loading = false;
                
            })
         
        }catch (error : any) {
            this.toastr.error(error.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end try
    }

    /*search2(){
        //let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
        const val = this.form.value;
        this.pageNumber2 = 0;
        this.offset = 0;


        this.refresh_pep_list(true);
        
    }*/

    search2(){
        //let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
        const val = this.form.value;
        this.pageNumber2 = 0;
        this.offset_wikidata = 0;
        this.search_wikidata(true,false,true);
        
    }

    search3(){
        //let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
        this.pageNumber2_adverse = 0;
        this.offset_adverse = 0;
        this.refresh_pep_list_adverse(true);
        
    }


    get_source_list(){
        const val = this.form.value;
        var rt = "";

        if(val.search_type == 'individual'){
            rt = environment.source_list;
        }else{
            rt = environment.entity_source_list;
        }//end if

        const data_param = {
            "record_type" : rt,
            "pageSize" : 1000,
            "orderByFieldArr" : [
                "name"
            ],
            "sortOrderArr" : [
                "asc"
            ]
        }//end
        console.log('get_source_list');
        return this.searchService.filterList(data_param).pipe(
            shareReplay(1)
        );
    }//end get_source_list

    get_nationality_list(){
        const data_param = {
            "record_type" : environment.nationality_list,
            "pageSize" : 1000,
            "orderByFieldArr" : [
                "name"
            ],
            "sortOrderArr" : [
                "asc"
            ]
        }//end

        return  this.searchService.filterList(data_param).pipe(
            shareReplay(1)
        );
    }//end get_source_list

    get_place_of_birth(){
        const data_param = {
            "record_type" : environment.individual,
            "pageSize" : 1000,
            "orderByFieldArr" : [
                "name"
            ],
            "sortOrderArr" : [
                "asc"
            ]
        }//end

        return  this.searchService.filterList(data_param);
    }//end get_place_of_birth

    hide_link(event : any,id){
        var target = event.target || event.srcElement || event.currentTarget;
        //document.getElementsByClassName(id)[0].children;
        var el = document.getElementById(id);
        this.selected_event = el;

        if(this.selected_event.textContent == 'See aliases'){
            this.selected_event.textContent = 'Hide aliases';
        }else{
            setTimeout(() => {
                this.selected_event.textContent = 'See aliases';
            }, 500);
        }//end if

    
        
    }

    change_tab(event : any,id){
        var target = event.target || event.srcElement || event.currentTarget;
        //document.getElementsByClassName(id)[0].children;
        var el = document.getElementById(id);
        if(id == 'pills-pep-list-tab'){
            this.show_pep_result_counter = true;
            this.show_sanction_counter = false;
            this.show_adverse_counter = false;
        }else if(id == 'pills-home-tab'){
            this.show_pep_result_counter = false;
            this.show_adverse_counter = false;
            this.show_sanction_counter = true;
        }else if(id == 'pills-adverse-tab'){
            this.show_pep_result_counter = false;
            this.show_adverse_counter = true;
            this.show_sanction_counter = false;
        }
    }

    reset_search_wiki(){
      
        this.data_list_wikidata  = new Array();
        this.offset_wikidata = 0;
        this.row_counter_wikidata = 0;
        this.pagination_counter_wikidata = 0;
    }

    reset_search_adverse(){

        this.adverse_list_item = new Array();
        this.total_search_result_adverse  = 0;
        this.row_counter_adverse = 0;
        this.pagination_counter_adverse = 0;
    }

    async clear_data(){
        this.global.loading = true;
        this.form.controls['filter_reference'].setValue('');
        this.form.controls['filter_nationality'].setValue('');
        this.form.controls['filter_birth_date'].setValue('');
        this.form.controls['filter_pob'].setValue('');
        this.form.controls['search_str_placeholder'].setValue('');
        this.subscriptionForm.controls['includePEP'].setValue(false);

        this.data_list = [];
        this.total_search_result        = 0;
        this.total_result               = 0;
        this.show_total_search_result   = false;
        this.row_counter                = 0;

        

       

        
        const source_list = await lastValueFrom(this.get_source_list());
        if(source_list["success"]){
            this.source_list = source_list["data"]["query_data"];
        }//end if
        //this.refresh_list("MOHAMMAD TEST sdfdsa");
        this.global.loading = false;
    }

    closemodal(){
        this.closebutton.nativeElement.click();
    }

    get_date_from(){

    }


    set_complete_date(){
        var val = this.form.value;
        var month = val.filter_birth_date_month;
        var day = val.filter_birth_date_day;
        var year = val.filter_birth_date_year;

        
        var complete_date = month+"/"+day+"/"+year;
     
        
        if(month !== "" && day !== "" && year !== ""){
            
            this.form.controls['filter_birth_date'].setValue(new Date(complete_date));
        }else{
            this.form.controls['filter_birth_date'].setValue(null);
        }

    }

    bulk_search(){
        

        window.location.replace("main/bulk-search");
    }


    adverse_list = {};
    adverse_list_item = new Array();
    form_filter_adverse = this.fb.group({
        page_size_placeholder : [10],
        sort_by : ["date:d"]
     
    });
    async load_adverse(save_search_result : boolean, show_loading : boolean){
        if(show_loading){
            this.global.loading    = true;
        }//end if
        //this.global.loading    = true;
        const searchval = this.form.value;
        var full_name = searchval.search_str_placeholder;
        const val = this.form_filter_adverse.value;
        const valFilter = this.form.value;
        
        if(this.adverse_list["searchInformation"] === undefined){
            console.log('test');
        
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", environment.google_custom_search_key);
            const raw = JSON.stringify({
                "created_by" : this.global.login_session["id"],
                "search_type" : valFilter.search_type,
                "save_search_result" : save_search_result,
                "name": full_name,
                "num": val.page_size_placeholder,
                "start": this.row_counter_adverse,
                "sort" : val.sort_by
            });
            console.log('raw',raw);
            var requestOptions : any; 
            requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            

           await fetch(environment.google_custom_search, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    
                    this.adverse_list = JSON.parse(result);

                    if(this.adverse_list["notify"] !== undefined){
                        this.toastr.warning(this.adverse_list["notify"]["message"],'System Message',{
                            timeOut: 10000,
                            enableHtml : true
                        });
                    }//end if

                    if(this.adverse_list["data"]["searchInformation"]["totalResults"] > 0){
                        //console.log(this.adverse_list["data"]["searchInformation"]["totalResults"])
                        this.adverse_list_item = this.adverse_list["data"]["items"];
                        this.total_search_result_adverse  = parseInt(this.adverse_list["data"]["searchInformation"]["totalResults"]);
                        this.row_counter_adverse = (this.pageNumber_adverse * val.page_size_placeholder) + 1;
                        this.pagination_counter_adverse= this.row_counter_adverse + (this.adverse_list_item.length - 1);
                    }else{
                        this.adverse_list_item = new Array();
                        this.total_search_result_adverse  = parseInt(this.adverse_list["data"]["searchInformation"]["totalResults"]);
                        this.row_counter_adverse = 1;
                        this.pagination_counter_adverse= this.row_counter_adverse + (this.adverse_list_item.length - 1);
                    }//end if

                    if(show_loading){
                        this.global.loading    = false;
                    }//end if

                    //this.global.loading = false;

                })
                .catch((error) => {
                    if(show_loading){
                        this.global.loading    = true;
                    }//end if
                    console.log(error);
                });
        }//end if
    }//


    load_adverse_promise(save_search_result : boolean, show_loading : boolean){
       
        //this.global.loading    = true;
        const searchval = this.form.value;

        

        var full_name = searchval.search_str_placeholder;
        const val = this.form_filter_adverse.value;
        const valFilter = this.form.value;
        
 
        
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", environment.google_custom_search_key);
        const raw = JSON.stringify({
            "created_by" : this.global.login_session["id"],
            "search_type" : valFilter.search_type,
            "save_search_result" : save_search_result,
            "name": full_name,
            "num": val.page_size_placeholder,
            "start": this.row_counter_adverse,
            "sort" : val.sort_by
        });
        
        var requestOptions : any; 
        requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        //return fetch(environment.google_custom_search, requestOptions);
        return fetch(environment.google_custom_search, requestOptions).then((response) => {
            return response.text();
        });
    }


    prev_adverse(){
        const val           = this.form_filter_adverse.value;
        if(this.pageNumber_adverse > 0){
            this.pageNumber_adverse -= 1;
            this.row_counter_adverse = (this.pageNumber_adverse * val.page_size_placeholder) + 1;
            this.pagination_counter_adverse = this.row_counter_adverse + (this.adverse_list_item.length - 1);
            this.load_adverse(false,true);
        }//end if
    }

    next_adverse(){
        const val           = this.form_filter_adverse.value;
        const total_pages   = Math.ceil((this.total_search_result_adverse / val.page_size_placeholder));
        
       
        if(total_pages > (this.pageNumber_adverse + 1)){
            
            this.pageNumber_adverse             += 1;
           
            this.row_counter_adverse = (this.pageNumber_adverse * val.page_size_placeholder) + 1;
            this.pagination_counter_adverse = this.row_counter_adverse + (this.adverse_list_item.length - 1);
            this.load_adverse(false,true);
        }//end if
    }

}
