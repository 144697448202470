export const environment = {
  production              : true,
  firebase: {
    projectId: 'cstf-v2',
    appId: '1:276946023370:web:d316f79746bfb57489d533',
    databaseURL: 'https://cstf-v2-default-rtdb.asia-southeast1.firebasedatabase.app',
    storageBucket: 'cstf-v2.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyCs1ZSWtmqC9Db_LlUZBaKJwmsL-aRj4QA',
    authDomain: 'cstf-v2.firebaseapp.com',
    messagingSenderId: '276946023370',
    measurementId: 'G-TKP16B0HJ7',
  },
  firebaseConfig : {
    apiKey: "AIzaSyBjcSJiYEZhj2tfktG14z14SgL0x6L1emA",
    authDomain: "pjs-ucheck.firebaseapp.com",
    projectId: "pjs-ucheck",
    storageBucket: "pjs-ucheck.appspot.com",
    messagingSenderId: "969608894201",
    appId: "1:969608894201:web:cc40fafc3d986666d6b428"
  },

   /*getVisitorApplicationUr : "https://asia-east2-cstf-v2.cloudfunctions.net/getVisitorApplicationUr_staging",
    process_data_staging : "https://asia-east2-cstf-v2.cloudfunctions.net/process_data_staging",
    save_data_staging : "https://asia-east2-cstf-v2.cloudfunctions.net/save_data_staging",
    get_data_staging : "https://asia-east2-cstf-v2.cloudfunctions.net/get_data_staging",
    get_dashboard_counter_staging : "https://asia-east2-cstf-v2.cloudfunctions.net/get_dashboard_counter_staging",
  */
    getVisitorApplicationUr : "https://asia-east2-cstf-v2.cloudfunctions.net/getVisitorApplicationUr_staging",
    process_data_staging : "https://process-data-dev-gen2-ewnpuwsqrq-df.a.run.app",
    save_data_staging : "https://save-data-dev-gen2-ewnpuwsqrq-df.a.run.app/",
    get_data_staging : "https://get-data-dev-gen2-ewnpuwsqrq-df.a.run.app",
    get_dashboard_counter_staging : "https://get-dashboard-counter-dev-gen2-ewnpuwsqrq-df.a.run.app",
    send_manifest_staging : "https://send-manifest-dev-gen2-ewnpuwsqrq-df.a.run.app",
    generate_pdf : "https://generate-pdf-dev-gen2-ewnpuwsqrq-df.a.run.app",
    process_bulk : "https://process-bulk-dev-gen2-ewnpuwsqrq-df.a.run.app",

    sign_in : "https://signin-user-dev-gen2-ewnpuwsqrq-df.a.run.app",
    create_user : "https://create-user-dev-gen2-ewnpuwsqrq-df.a.run.app",
    get_custom_token : "https://generate-custom-token-dev-gen2-ewnpuwsqrq-df.a.run.app",
    send_user_invite : "https://send-invite-dev-gen2-ewnpuwsqrq-df.a.run.app",
    validate_user_invite : "https://validate-invite-dev-gen2-ewnpuwsqrq-df.a.run.app",

    google_custom_search : "https://google-custom-search-dev-gen2-ewnpuwsqrq-df.a.run.app",
    google_custom_search_key : "AIzaSyDdCY3P-bvjEWMm37tGuaw9jGvXCHQPK0Q",
    open_api_pep : "https://open-api-dev-gen2-ewnpuwsqrq-df.a.run.app",
    view_api_pep : "https://view-open-api-dev-gen2-ewnpuwsqrq-df.a.run.app",
    open_api_key : "ApiKey 6bf34afe53cb9de932275fc9b4ffcfae",
    search_wikidata : "https://search-wikidata-dev-gen2-ewnpuwsqrq-df.a.run.app",
    view_wikidata : "https://view-wikidata-dev-gen2-ewnpuwsqrq-df.a.run.app",
    web_api_key : "AIzaSyBjcSJiYEZhj2tfktG14z14SgL0x6L1emA",



    enable_elastic : true,
    
    elastic_url : "https://get-data-elastic-dev-gen2-ewnpuwsqrq-df.a.run.app",
    elastic_index : "search-ucheck_dev",
    elastic_index_entity : "search-ucheck-entity_dev",
    elastic_api_key : "ApiKey VEZ1Ym9aQUJFaXc4SUxHUEc1Nk46MzdEcTJUcmpULU9JRHo0VGdEaEVrQQ==",

    //elastic_index : "test",
    //elastic_api_key : "ApiKey dDFZLV9Ja0JaVjFRRXNOVElQcF86d1hEZ0d3OFpSR1d5LUhwbG9tVFBQUQ==",
    
    //collection
    individual : "individual_dev",
    entity : "entity_dev",
    setting : "setting_dev",
    dashboard_counter :"dashboard_counter_dev",
    user_management : "user_management_dev",
    source_list : "source_list_dev",
    entity_source_list : "entity_source_list_dev",
    nationality_list : "nationality_list_dev",
    storage_folder  : "u_check_admin_dev",
    user_permission : "user_permission_dev",
    support : "support_dev",
    generated_manifest : "generated_manifest_dev",
    company_information : "company_information_dev",
    bulk_search : "bulk_counter_dev",
    //end collection

    //support email
    support_email : "plangaman@unawa.asia",
    //support_email : "techsupport@unawa.asia",
    //support_email : "mligaoen@unawa.asia",
    from_email : "no-reply@unawa.asia",
    //end support email
    //app version
    app_version : "ver. 1.0.9"
}