

<div class="container-fluid">
    <button 
            class="d-none btn btn-warning" 
            onclick="enableAllTooltips()">
            Enable The tooltip
        </button>
        
        <button
            class="d-none  btn btn-secondary" 
            data-bs-toggle="tooltip" 
            data-bs-title="Welcome to GeeksforGeeks">
            Hover for Tooltip
        </button>

    <div class="row mx-3 mb-5">
        <div class="col-auto">
            <form [formGroup]="form">
                <label class="form-label">
                    <small>&nbsp;</small>
                </label>
                <select class="form-select" formControlName="search_type" (change)="clear_data()">
                    <option selected value="individual">Individual</option>
                    <option value="entity">Corporation</option>
                </select>
            </form>
        </div>
        
        <div class="col">
            <form [formGroup]="form">
                <label class="form-label">
                    <small>&nbsp;</small>
                </label>
                <div class="input-group">
                    <span class="input-group-text bg-white">
                        <img style="width:0.15in;height:0.15in;" src="{{'../../assets/images/search-icon.svg'}}">
                    </span>
                    <input style="border-left:none;" formControlName="search_str_placeholder" placeholder="Enter Name"  type="text" class="form-control" #input >
                </div>
            </form>
        </div>
        <div class="col-xxl-2 col-xl-2 col-lg-2 col-auto">
            <label class="form-label">
                <small>&nbsp;</small>
            </label>
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-primary font-700" onmouseover="enableAllTooltips()" (click)="search()">Search</button>
            </div>
        </div>

        <div class="col-xxl-2 col-xl-2 col-lg-2 col-auto">
            <label class="form-label">
                <small>&nbsp;</small>
            </label>
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-outline-primary font-700" (click)="bulk_search()">Bulk Search</button>
            </div>
        </div>
    </div>
    
    <form [formGroup]="subscriptionForm">
        <div class="row mx-3 mb-3 justify-content-end align-items-start">

            <div class="col-auto">
                <div class="form-check form-check-inline">
                    <!-- <a target="_blank" class="ms-3 btn btn-sm btn-primary text-white" data-bs-toggle="modal" data-bs-target="#confirmation_modal">Generate report</a> -->
                    <input class="form-check-input" formControlName="includePEP" type="checkbox" (change)="checkInclude($event,'includePEP')">
                    <!-- data-bs-toggle="modal" data-bs-target="#confirmation_modal" -->
                    <label class="form-check-label" for="">
                        Include PEP List
                    </label>
                </div>
            </div>

            <div class="col-auto">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" formControlName="includeAdverse" type="checkbox" (change)="checkInclude($event,'includeAdverse')">
                    <label class="form-check-label" for="">
                        Include News Search
                    </label>
                </div>
            </div>

            <div class="col-auto">
                <div class="form-check form-check-inline">
                    <a tabindex="0" onmouseover="enablePopover()" role="button" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-placement="bottom" data-bs-content="Consolidating PEP List and Adverse Media are paid service. Additional charges may apply.">
                        <div class="info-img"></div>
                    </a>
                </div>
            </div>
        </div>
    </form>
    
    <div class="row mx-3">
        <div class="col">
           <div class="card py-2 shadow">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-auto">
                            <p class="fw-lighter fw-semibold">Filter</p>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                            
                            
                            <form [formGroup]="form">
                                <div class="row gx-2">
                                    <div class="col d-none">
                                        <label class="form-label">
                                            <small>Family Name</small>
                                        </label>
                                        <input type="text" formControlName="filter_family_name" class="form-control">
                                    </div>

                                    <div class="col">
                                        <label class="form-label">
                                            <small class="text-dashboard-muted">List Reference</small>
                                        </label>
                                        <input type="text" formControlName="filter_reference" class="form-control">
                                    </div>
                                    
                                </div>
                            </form>
                        </div>

                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                           
                            <div class="row gx-2">
                                <div class="col">
                                    <form [formGroup]="form">
                                        <label class="form-label">
                                            <small class="text-dashboard-muted">Source</small>
                                        </label>
                                        <select class="form-select" formControlName="filter_source_list">
                                            <option value="">Select</option>

                                            <option *ngFor="let x of source_list" [value]="x.name" >{{x.name}}</option>
                                        </select>
                                    </form>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>

                    <hr *ngIf="form.value.search_type == 'individual' && enable_elastic" class="my-4" />
                    <div class="row align-items-center" *ngIf="form.value.search_type == 'individual' && enable_elastic">
                        <div class="col-auto fw-lighter fw-semibold">
                            Advance Filtering

                            
                        </div>

                        <div class="col-xxl-2 col-xl-2 col-lg-2 col-auto">
                            <a #expand class="navbar-brand d-none" data-bs-toggle="collapse" href=".collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                <img style="width:20px;height:20px;" src="../../assets/images/expand.svg" />
                            </a>
                        </div>

                        
                    </div>
                    <div class="row justify-content-center mt-3" *ngIf="form.value.search_type == 'individual' && enable_elastic">
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                            <form [formGroup]="form">
                                <div class="row gx-2 align-items-center">
                                    
                                    <div class="col-xxl-5 col-xl-5 col-auto">
                                        <label class="form-label">
                                            <small class="text-dashboard-muted">Nationality</small>
                                        </label>
                                        <select class="form-select" formControlName="filter_nationality">
                                            <option value="">Select</option>

                                            <option *ngFor="let x of nationality_list" [value]="x.name" >{{x.name}}</option>
                                        </select>
                                    </div>

                                
                                    <div class="col-xxl-2 col-xl-2 col-auto">
                                        <label class="form-label">
                                            <small class="text-dashboard-muted">Birth Date</small>
                                        </label>
                                        
                                        
                                        
                                        <select (change)="set_complete_date()" class="form-select" formControlName="filter_birth_date_day">
                                            <option value="">Day</option>
                                            <option *ngFor="let day of [].constructor(days); let i = index" value="{{i+1}}">{{i+1}}</option>
                                        </select>
                                        <!--<label class="form-label">
                                            <small class="text-dashboard-muted">Birth Date</small>
                                        </label>
                                        -->
                                        <div class="input-group d-none">
                                            <ngx-datepicker formControlName="filter_birth_date" (ngModelChange)="get_date_from()" [options]="date_option"></ngx-datepicker>
                                        </div>
                                    </div>

                                    <div class="col-xxl-3 col-xl-3 col-auto">
                                        <label class="form-label">
                                            <small class="text-dashboard-muted">&nbsp;</small>
                                        </label>
                                        
                                        <select (change)="set_complete_date()" class="form-select" formControlName="filter_birth_date_month">
                                            <option value="">Month</option>
                                            <option *ngFor="let month of months; let i = index" value="{{month.index_name}}">{{month.long_name}}</option>
                                        </select>
                                    </div>

                                    <div class="col-xxl-2 col-xl-2 col-auto">
                                        <label class="form-label">
                                            <small class="text-dashboard-muted">&nbsp;</small>
                                        </label>
                                        
                                        <select (change)="set_complete_date()" class="form-select" formControlName="filter_birth_date_year">
                                            <option value="">Year</option>
                                            <option *ngFor="let year of year_arr; let i = index" value="{{year}}">{{year}}</option>
                                        </select>
                                    
                                    </div>
                                    
                                </div>
                            </form>
                        </div>

                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                            <div class="col">
                                <form [formGroup]="form">
                                    <label class="form-label">
                                        <small class="text-dashboard-muted">Place of Birth</small>
                                    </label>

                                    <input type="text" formControlName="filter_pob" class="form-control">
                                </form>
                                
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col">
                            <form [formGroup]="form">
                                <label class="form-label">
                                    <small class="text-dashboard-muted">Address</small>
                                </label>
                                <input type="text" formControlName="filter_address" class="form-control">
                            </form>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col">
                            <form [formGroup]="form">
                                <label class="form-label">
                                    <small class="text-dashboard-muted">Comments</small>
                                </label>
                                <input type="text" formControlName="filter_comments" class="form-control">
                            </form>
                        </div>
                    </div>

                    <div class="row justify-content-center" *ngIf="enable_elastic">
                        <div class="col">
                            <form [formGroup]="form">
                                <label class="form-label">
                                    <small class="text-dashboard-muted">Name Original Script</small>
                                </label>
                                <input type="text" formControlName="filter_name_original_script" class="form-control">
                            </form>
                        </div>
                    </div>
                    
                </div>

           </div>
        </div>
    </div>

    <div class="row justify-content-center ms-3 me-3">
        <div class="col">
           
                <!--<p class="fw-bold mt-4 mb-4" *ngIf="show_total_search_result">{{total_search_result}} result(s) -->
                <p  class="fw-bold mt-4 mb-4" *ngIf="show_total_search_result && show_sanction_counter">{{lib.toCurrency_precision(total_search_result,0)}} result(s)
                    <!-- <a target="_blank" class="ms-3 btn btn-sm btn-primary text-white" data-bs-toggle="modal" data-bs-target="#confirmation_modal">Generate report</a> -->
                    <a target="_blank" href="{{view_link}}" class="ms-3 btn btn-sm btn-primary font-700">Generate report</a>
                </p>

                <!--<p *ngIf="show_pep_result_counter" class="fw-bold mt-4 mb-4 d-none">{{lib.toCurrency_precision(total_search_result2,0)}} result(s)</p>-->
                <p *ngIf="show_pep_result_counter" class="fw-bold mt-4 mb-4"></p>

                <p *ngIf="show_adverse_counter" class="fw-bold mt-4 mb-4">{{lib.toCurrency_precision((total_search_result_adverse <= 0? total_search_result2_adverse : total_search_result_adverse),0)}} result(s)</p>
                
                <form class="mt-4 mb-4 d-none" [formGroup]="form">
                    <p >Filter</p>
                    
                    <div class="form-check form-check-inline">
                        <input class="form-check-input filter_grp1" formControlName="filter_all" type="checkbox" (change)="check($event,'filter_all')">
                        <label class="form-check-label" for="flexCheckDefault">
                            All
                        </label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" formControlName="filter_first_name"  type="checkbox" (change)="check($event,'filter_first_name')">
                        <label class="form-check-label" for="flexCheckDefault">
                            First Name
                        </label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" formControlName="filter_last_name"  type="checkbox" (change)="check($event,'filter_last_name')">
                        <label class="form-check-label" for="flexCheckDefault">
                            Last Name
                        </label>
                    </div>
                    
                    
                </form>
                


                
                <ul class="nav nav-pills" id="pills-tab" role="tablist" *ngIf="show_total_search_result && enable_elastic">
                    <li class="nav-item me-2" role="presentation">
                        <button class="nav-link active fw-bold" id="pills-home-tab" (click)="change_tab($event,'pills-home-tab')" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Sanction List</button>
                    </li>
                    <li class="nav-item me-2" role="presentation" *ngIf="form.value.search_type == 'individual' && selectedPEP">
                        <button class="nav-link fw-bold" id="pills-pep-list-tab" (click)="change_tab($event,'pills-pep-list-tab')" data-bs-toggle="pill" data-bs-target="#pills-pep-list" type="button" role="tab" aria-controls="pills-pep-list" aria-selected="false">PEP</button>
                    </li>
                    <li class="nav-item" role="presentation" *ngIf="form.value.search_type == 'individual' && selectedAdverse">
                        <button class="nav-link fw-bold" id="pills-adverse-tab" (click)="change_tab($event,'pills-adverse-tab')" data-bs-toggle="pill" data-bs-target="#pills-adverse" type="button" role="tab" aria-controls="pills-adverse" aria-selected="false">Adverse Media</button>
                    </li>
                </ul>

                <div class="tab-content" id="pills-tabContent" *ngIf="show_total_search_result && enable_elastic">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                        <div class="card border-0 shadow" *ngIf="total_search_result == 0">
                            <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">
            
                                <div class="col-auto">
                                    <div class="row align-items-center justify-content-center">
                                        <div class="col-auto">
                                            <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                                        </div>
                                        <div class="col-12">
                                            <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="shadow bg-white rounded" *ngIf="total_search_result > 0 && show_total_search_result && enable_elastic">

                    
                            <div class="table-responsive bg-white rounded" style="max-height:500px;">
                                <table class="table align-middle" *ngIf="show_total_search_result" style="min-width:2in;">
                                    <thead>
                                        <tr>
                                            
                                            <th class="text-muted text-center px-3" style="min-width:0.5in;max-width:0.5in;">No.</th>
                                            <th class="text-muted px-3" style="min-width:1.5in;max-width:1.5in;">Source</th>
                                            <th class="text-muted px-3">Full Name</th>
                                            <th class="text-muted px-3" style="min-width:2in;max-width:2in;">Also known as</th>
                                            <th class="text-muted px-3" style="min-width:1.3in;max-width:1.3in;">Listed On</th>
                                            <th class="text-muted text-center px-3" style="min-width:0.8in;max-width:0.8in;">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of data_list; let x = index">
                                            <td class="text-center px-3">{{row_counter + x}}</td>
                                            <td class="px-3">{{data['_source'].SOURCE_LIST}}</td>
                                            <td class="px-3 fw-bold">{{ form.value.search_type == 'individual'? data['_source'].FULL_NAME : data['_source'].FIRST_NAME}}</td>
                                            <td class="px-3" style="min-width:2in;max-width:2in;">
                                                
                                                <a id="c{{data['_source'].ID}}" (click)="hide_link($event,'c'+data['_source'].ID)" *ngIf="data['_source'].INDIVIDUAL_ALIAS_PARSED_COUNT > 0" data-bs-toggle="collapse" href=".c{{data['_source'].ID}}" role="button" aria-expanded="false" aria-controls="collapseExample">See aliases</a>
                                                <span class="c{{data['_source'].ID}} collapse" *ngFor="let alias of data['_source'].INDIVIDUAL_ALIAS_G;let x = index">
                                                    <div *ngIf="x==0 && alias.QUALITY !== undefined && (alias.QUALITY=='Good' || alias.QUALITY=='a.k.a.')" class="row align-items-center">
                                                        <label class="col-form-label"><b>{{alias.QUALITY}}</b></label>
                                                        <div class="d-inline-block">
                                                           
                                                            
            
                                                            <span>
                                                                {{alias.ALIAS_NAME !== undefined? alias.ALIAS_NAME : ""}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </span>
                                                
                                               
            
                                                <span class="c{{data['_source'].ID}} collapse" *ngFor="let alias of data['_source'].INDIVIDUAL_ALIAS_G;let x = index">
                                                    
                                                    <div *ngIf="x > 0 && alias.QUALITY !== undefined && (alias.QUALITY=='Good' || alias.QUALITY=='a.k.a.')" class="row align-items-center">
                                                        
                                                        <div>
                                                            {{alias.ALIAS_NAME !== undefined? alias.ALIAS_NAME : ""}}
                                                        </div>
                                                    </div>
                                                </span>
            
                                                <span  class="c{{data['_source'].ID}} collapse" *ngFor="let alias of data['_source'].INDIVIDUAL_ALIAS_L;let y = index">
                                                    
                                                    <div *ngIf="alias.QUALITY !== undefined && (alias.QUALITY=='Low')" class="row align-items-center">
                                                        <label *ngIf="y==0" class="col-form-label"><b>{{alias.QUALITY}}</b></label>
                                                        <div>
                                                            {{alias.ALIAS_NAME !== undefined? alias.ALIAS_NAME : ""}}
                                                        </div>
                                                    </div>
                                                </span>
                                            </td>
                                            
                                            <td class="px-3">{{data['_source'].LISTED_ON_PARSED}}</td>
                                            <td class="text-center">
                                                <div class="d-grid gap-2">
                                                    <a target="_blank" [routerLink]="['/search/'+form.value.search_type, data['_source'].ID,'sanction',(selectedAdverse ? 1 : 0)]" type="button" class="btn btn-primary btn-sm font-700 py-2">View</a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    
                                    
                                </table>
                            </div>
        
                            <div class="row container-fluid justify-content-end align-items-center mt-4">
                                <div class="col-auto">
                                    <form [formGroup]="form" *ngIf="show_total_search_result">
        
                                        <div class="mb-3 row align-items-center">
                                            <label for="inputPassword" class="col-auto col-form-label font-14">
                                                Rows per page
                                            </label>
                                            <div class="col">
                                                <select style="border-style:none !important;" (change)="search(false)" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                                    <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                                </select>
                                            </div>
                                        </div>
        
                                        
        
                                        
                                    </form>
                                </div>
        
                                <div class="col-auto">
                                    <div class="mb-3 row align-items-center">
                                        <div class="col-auto">
                                            {{row_counter}} - {{pagination_counter}} of {{total_search_result}}
                                        </div>
                                    </div>
                                    
                                </div>
        
                                <div class="col-auto">
                                    <nav aria-label="Page navigation example" *ngIf="show_total_search_result">
                                        <ul class="pagination border-opacity-10">
                                            <li class="page-item " (click)="prev()" >
                                                <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                    <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                                </a>
                                            </li>
                                         
                                            <li class="page-item" (click)="next()">
                                                <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                    <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- PEP List Tab Here -->
                    <div class="tab-pane fade" *ngIf="selectedPEP" id="pills-pep-list" role="tabpanel" aria-labelledby="pills-pep-list-tab" tabindex="1">
                        <!-- Wikidata -->
                        <div class="card border-0 shadow" *ngIf="data_list_wikidata.length == 0">
                        
                            <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">
            
                                <div class="col-auto">
                                    <div class="row align-items-center justify-content-center">
                                        <div class="col-auto">
                                            <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                                        </div>
                                        <div class="col-12">
                                            <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="shadow bg-white rounded" *ngIf="data_list_wikidata.length > 0">
                            <div class="table-responsive bg-white rounded" style="max-height:500px;">
                                <table class="table align-middle"  style="min-width:2in;">
                                    <thead>
                                        <tr>
                                            
                                            <th class="text-muted text-center px-3" style="min-width:0.5in;max-width:0.5in;">No.</th>
                                            <th class="text-muted px-3" style="min-width:1.5in;max-width:1.5in;">Title</th>
                                            <th class="text-muted px-3">Full Name</th>
                                            <th class="text-muted px-3" style="min-width:2in;max-width:2in;">Also known as</th>
                                            <th class="text-muted text-center px-3" style="min-width:0.8in;max-width:0.8in;">Action</th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        <tr *ngFor="let data of data_list_wikidata; let x = index">
                                            <td class="text-center px-3">{{row_counter2 + x}}</td>
                                            <td class="px-3">{{data['title'] !== undefined? data['title'] : ""}}</td>
                                            <td class="px-3">{{data['label'] !== undefined? data['label'] : '' }}</td>
                                            <td class="px-3" style="min-width:2in;max-width:2in;">
                                                <a id="c{{data['id']}}" (click)="hide_link($event,'c'+data['id'])" *ngIf="data['aliases'] !== undefined && data['aliases'].length > 0" data-bs-toggle="collapse" href=".c{{data['id']}}" role="button" aria-expanded="false" aria-controls="collapseExample">See aliases</a>
                                                

                                                <span class="c{{data['id']}} collapse" *ngFor="let alias of data['aliases'];let x = index">
                                                        
                                                    <div class="row align-items-center">
                                                        
                                                        <div>
                                                            {{alias}}
                                                        </div>
                                                    </div>
                                                </span>

                                                

                                                
                                            </td>
                                            <td class="text-center">
                                                <div class="d-grid gap-2">
                                                    <a target="_blank" [routerLink]="['/search-wiki/'+form.value.search_type, data['id']]" type="button" class="btn btn-primary btn-sm font-700 py-2">View</a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div class="row container-fluid justify-content-end align-items-center mt-4">
                                <div class="col-auto">
                                    <form [formGroup]="form" *ngIf="show_total_search_result">
        
                                        <div class="mb-3 row align-items-center">
                                            <label for="inputPassword" class="col-auto col-form-label font-14">
                                                Rows per page
                                            </label>
                                            <div class="col">
                                                <select style="border-style:none !important;" (change)="search2()" formControlName="page_size_placeholder2" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                                    <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                                </select>
                                            </div>
                                        </div>
        
                                        
        
                                        
                                    </form>
                                </div>
        
                                <div class="col-auto d-none">
                                    <div class="mb-3 row align-items-center">
                                        <div class="col-auto">
                                            {{row_counter2}} - {{pagination_counter2}} of {{total_search_result2}}
                                        </div>
                                    </div>
                                    
                                </div>
        
                                <div class="col-auto">
                                    <nav aria-label="Page navigation example" *ngIf="show_total_search_result">
                                        <ul class="pagination border-opacity-10">
                                            <li class="page-item " (click)="prev2()" >
                                                <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                    <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                                </a>
                                            </li>
                                        
                                            <li class="page-item" (click)="next2()">
                                                <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                    <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <!-- Wikidata -->

                        
                        <div class="card border-0 shadow d-none" *ngIf="total_search_result2 == 0">
                        
                            <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">
            
                                <div class="col-auto">
                                    <div class="row align-items-center justify-content-center">
                                        <div class="col-auto">
                                            <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                                        </div>
                                        <div class="col-12">
                                            <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="shadow bg-white rounded d-none" *ngIf="total_search_result2 > 0">
                            <div class="table-responsive bg-white rounded" style="max-height:500px;">
                                <table class="table align-middle"  style="min-width:2in;">
                                    <thead>
                                        <tr>
                                            
                                            <th class="text-muted text-center px-3" style="min-width:0.5in;max-width:0.5in;">No.</th>
                                            <th class="text-muted px-3" style="min-width:1.5in;max-width:1.5in;">Country</th>
                                            <th class="text-muted px-3">Full Name</th>
                                            <th class="text-muted px-3" style="min-width:2in;max-width:2in;">Also known as</th>
                                            <th class="text-muted text-center px-3" style="min-width:0.8in;max-width:0.8in;">Action</th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        <tr *ngFor="let data of pep_list_arr; let x = index">
                                            <td class="text-center px-3">{{row_counter2 + x}}</td>
                                            <td class="px-3">{{data["properties"]['country_parsed'] !== undefined? data["properties"]['country_parsed'] : ""}}</td>
                                            <td class="px-3">{{data['caption']}}</td>
                                            <td class="px-3" style="min-width:2in;max-width:2in;">
                                                <a id="c{{data['id']}}" (click)="hide_link($event,'c'+data['id'])" *ngIf="data['properties']['alias'] !== undefined && data['properties']['alias'].length > 0" data-bs-toggle="collapse" href=".c{{data['id']}}" role="button" aria-expanded="false" aria-controls="collapseExample">See aliases</a>
                                                <span class="c{{data['id']}} collapse" *ngFor="let alias of data['properties']['alias'];let x = index">
                                                    <div *ngIf="x==0" class="row align-items-center">
                                                        <label class="col-form-label"><b>Good</b></label>
                                                        <div class="d-inline-block">
                                                            <span>
                                                                {{alias}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </span>

                                                <span class="c{{data['id']}} collapse" *ngFor="let alias of data['properties']['alias'];let x = index">
                                                        
                                                    <div *ngIf="x > 0" class="row align-items-center">
                                                        
                                                        <div>
                                                            {{alias}}
                                                        </div>
                                                    </div>
                                                </span>

                                                <span class="c{{data['id']}} collapse" *ngFor="let alias of data['properties']['weakAlias'];let x = index">
                                                    <div *ngIf="x==0" class="row align-items-center">
                                                        <label class="col-form-label"><b>Low</b></label>
                                                        <div class="d-inline-block">
                                                            <span>
                                                                {{alias}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </span>

                                                <span class="c{{data['id']}} collapse" *ngFor="let alias of data['properties']['weakAlias'];let x = index">
                                                        
                                                    <div *ngIf="x > 0" class="row align-items-center">
                                                        
                                                        <div>
                                                            {{alias}}
                                                        </div>
                                                    </div>
                                                </span>

                                                
                                            </td>
                                            <td class="text-center">
                                                <div class="d-grid gap-2">
                                                    <a target="_blank" [routerLink]="['/search-pep/'+form.value.search_type, data['id']]" type="button" class="btn btn-primary btn-sm font-700 py-2">View</a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div class="row container-fluid justify-content-end align-items-center mt-4">
                                <div class="col-auto">
                                    <form [formGroup]="form" *ngIf="show_total_search_result">
        
                                        <div class="mb-3 row align-items-center">
                                            <label for="inputPassword" class="col-auto col-form-label font-14">
                                                Rows per page
                                            </label>
                                            <div class="col">
                                                <select style="border-style:none !important;" (change)="search2()" formControlName="page_size_placeholder2" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                                    <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                                </select>
                                            </div>
                                        </div>
        
                                        
        
                                        
                                    </form>
                                </div>
        
                                <div class="col-auto">
                                    <div class="mb-3 row align-items-center">
                                        <div class="col-auto">
                                            {{row_counter2}} - {{pagination_counter2}} of {{total_search_result2}}
                                        </div>
                                    </div>
                                    
                                </div>
        
                                <div class="col-auto">
                                    <nav aria-label="Page navigation example" *ngIf="show_total_search_result">
                                        <ul class="pagination border-opacity-10">
                                            <li class="page-item " (click)="prev2()" >
                                                <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                    <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                                </a>
                                            </li>
                                        
                                            <li class="page-item" (click)="next2()">
                                                <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                    <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>


                    </div>
                    <!-- Adverse Tab Here -->
                    <div class="tab-pane fade" id="pills-adverse" role="tabpanel" aria-labelledby="pills-adverse-tab" tabindex="0" *ngIf="selectedAdverse">
                        

                        <div class="shadow bg-white rounded px-5 py-5">
                            <div class="row align-items-center">
                                <div class="col-xxl-7 col-xl-7 col-auto">
                                    <h5>About {{lib.toCurrency_precision(this.total_search_result_adverse,0)}} result(s)</h5>
                                </div>
                                <div class="col-auto">
                                    <label class="form-label">
                                        Sort By
                                    </label>
                                </div>
                                <div class="col-auto">
                                    <form [formGroup]="form_filter_adverse">
                                        <select class="form-select" (change)="load_adverse(false,true)" formControlName="sort_by">
                                            <option value="date:d">Most Recent</option>
                                            <option value="review-rating:d">Top News</option>
                                        </select>  
                                    </form>
                                    
                                </div>
                            </div>
                            
                            <hr/>

                            <div class="card border-0" *ngIf="total_search_result_adverse <= 0">
                                <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">
                
                                    <div class="col-auto">
                                        <div class="row align-items-center justify-content-center">
                                            <div class="col-auto">
                                                <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                                            </div>
                                            <div class="col-12">
                                                <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <div *ngIf="total_search_result_adverse > 0">
                                <div *ngFor="let dt of adverse_list_item; let x = index">
                            
                                    <div class="card mb-3 border-0">
                                        <div class="row">
                                            <div class="col-auto col-form-label"><a target="_blank" href="{{dt.link}}">{{dt.title}}</a></div>
                                        </div>
                                        <div class="row g-0">
                                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-auto">
        
                                                <img *ngIf="dt['pagemap'] === undefined || dt['pagemap']['cse_thumbnail'] === undefined" style="height:1in;width:1in;" src="../../assets/images/indiv-profile-pic.png" class="img-fluid" alt="...">
        
                                                <img *ngIf="dt['pagemap'] !== undefined && dt['pagemap']['cse_thumbnail'] !== undefined" style="height:1in;width:1in;"  src="{{dt['pagemap']['cse_thumbnail'][0]['src']}}" class="img-fluid" alt="...">
                                            </div>
                                            <div class="col-xxl-10 col-xl-10 col-lg-10 col-auto">
                                                <div class="card-body">
                                                    
                                                    <p class="card-text" [innerHTML]="dt.htmlSnippet"></p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
    
                                <div class="row container-fluid justify-content-end align-items-center mt-4 adverse">
                                    <div class="col-auto">
                                        <form [formGroup]="form_filter_adverse" *ngIf="total_search_result_adverse > 0">
            
                                            <div class="mb-3 row align-items-center">
                                                <label for="inputPassword" class="col-auto col-form-label font-14">
                                                    Rows per page
                                                </label>
                                                <div class="col">
                                                    <select style="border-style:none !important;" (change)="load_adverse(false,true)" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                                        <option *ngFor="let x of pageSize3" [value]="x" >{{x}}</option>
                                                    </select>
                                                </div>
                                            </div>
            
                                            
            
                                            
                                        </form>
                                    </div>
            
                                    <div class="col-auto">
                                        <div class="mb-3 row align-items-center">
                                            <div class="col-auto">
                                                {{row_counter_adverse}} - {{pagination_counter_adverse}} of {{lib.toCurrency_precision(total_search_result_adverse,0)}}
                                            </div>
                                        </div>
                                        
                                    </div>
            
                                    <div class="col-auto">
                                        <nav aria-label="Page navigation example" *ngIf="total_search_result_adverse > 0">
                                            <ul class="pagination border-opacity-10">
                                                <li class="page-item " (click)="prev_adverse()" >
                                                    <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                        <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                                    </a>
                                                </li>
                                             
                                                <li class="page-item" (click)="next_adverse()">
                                                    <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                        <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>

                            </div>
                            
                    
                            
                            
                        </div>
                    </div>
                </div>
                
                

                <!--firebase search-->

                
                
                <div class="shadow bg-white rounded" *ngIf="show_total_search_result && !enable_elastic">
                    

                    <div class="table-responsive bg-white rounded" style="max-height:500px;">
                        <table class="table align-middle" *ngIf="show_total_search_result" style="min-width:2in;">
                            <thead>
                                <tr>
                                    
                                    <th class="text-muted text-center px-3" style="min-width:0.5in;max-width:0.5in;">No.</th>
                                    <th class="text-muted px-3" style="min-width:1.5in;max-width:1.5in;">Source</th>
                                    <th class="text-muted px-3">Full Name</th>
                                    <th class="text-muted px-3" style="min-width:2in;max-width:2in;">Also known as</th>
                                    <th class="text-muted px-3" style="min-width:1.3in;max-width:1.3in;">Listed On</th>
                                    <th class="text-muted text-center px-3" style="min-width:0.8in;max-width:0.8in;">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of data_list; let x = index">
                                    <td class="text-center px-3">{{row_counter + x}}</td>
                                    <td class="px-3">{{data.SOURCE_LIST}}</td>
                                    <td class="px-3 fw-bold">{{ form.value.search_type == 'individual'? data.FULL_NAME : data.FIRST_NAME}}</td>
                                    <td class="px-3" style="min-width:2in;max-width:2in;">
                                        
                                        <a id="c{{data.ID}}" (click)="hide_link($event,'c'+data.ID)" *ngIf="data.INDIVIDUAL_ALIAS_PARSED_COUNT > 0" data-bs-toggle="collapse" href=".c{{data.ID}}" role="button" aria-expanded="false" aria-controls="collapseExample">See aliases</a>
                                        <span class="c{{data.ID}} collapse" *ngFor="let alias of data.INDIVIDUAL_ALIAS_G;let x = index">
                                            <div *ngIf="x==0 && alias.QUALITY !== undefined && (alias.QUALITY=='Good' || alias.QUALITY=='a.k.a.')" class="row align-items-center">
                                                <label class="col-form-label"><b>{{alias.QUALITY}}</b></label>
                                                <div class="d-inline-block">
                                                   
                                                    
    
                                                    <span>
                                                        {{alias.ALIAS_NAME !== undefined? alias.ALIAS_NAME : ""}}
                                                    </span>
                                                </div>
                                            </div>
                                        </span>
                                        
                                       
    
                                        <span class="c{{data.ID}} collapse" *ngFor="let alias of data.INDIVIDUAL_ALIAS_G;let x = index">
                                            
                                            <div *ngIf="x > 0 && alias.QUALITY !== undefined && (alias.QUALITY=='Good' || alias.QUALITY=='a.k.a.')" class="row align-items-center">
                                                
                                                <div>
                                                    {{alias.ALIAS_NAME !== undefined? alias.ALIAS_NAME : ""}}
                                                </div>
                                            </div>
                                        </span>
    
                                        <span  class="c{{data.ID}} collapse" *ngFor="let alias of data.INDIVIDUAL_ALIAS_L;let y = index">
                                            
                                            <div *ngIf="alias.QUALITY !== undefined && (alias.QUALITY=='Low')" class="row align-items-center">
                                                <label *ngIf="y==0" class="col-form-label"><b>{{alias.QUALITY}}</b></label>
                                                <div>
                                                    {{alias.ALIAS_NAME !== undefined? alias.ALIAS_NAME : ""}}
                                                </div>
                                            </div>
                                        </span>
                                    </td>
                                    
                                    <td class="px-3">{{data.LISTED_ON_PARSED}}</td>
                                    <td class="text-center">
                                        <div class="d-grid gap-2">
                                            <a target="_blank" [routerLink]="['/search/'+form.value.search_type, data.ID]" type="button" class="btn btn-primary btn-sm">View</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            
                            
                        </table>
                    </div>

                    <div class="row container-fluid justify-content-end align-items-center mt-4">
                        <div class="col-auto">
                            <form [formGroup]="form" *ngIf="show_total_search_result">

                                <div class="mb-3 row align-items-center">
                                    <label for="inputPassword" class="col-auto col-form-label font-14">
                                        Rows per page
                                    </label>
                                    <div class="col">
                                        <select style="border-style:none !important;" (change)="search()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                            <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                        </select>
                                    </div>
                                </div>

                                

                                
                            </form>
                        </div>

                        <div class="col-auto">
                            <div class="mb-3 row align-items-center">
                                <div class="col-auto">
                                    {{row_counter}} - {{pagination_counter}} of {{total_search_result}}
                                </div>
                            </div>
                            
                        </div>
                        
                        <div class="col-auto">
                            <nav aria-label="Page navigation example" *ngIf="show_total_search_result">
                                <ul class="pagination border-opacity-10">
                                    <li class="page-item " (click)="prev()" >
                                        <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                            <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                        </a>
                                    </li>
                                 
                                    <li class="page-item" (click)="next()">
                                        <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                            <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        
                        
                    </div>
                </div>
                <!--firebase search-->
                
                
        </div>
    </div>
</div>

<!--
<div class="container">
    <div class="row justify-content-center">
        <div class="col-xxl-1 col-xl-1 col-lg-1">
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-success" (click)="sync_data()">Sync</button>
            </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6">
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Search</span>
                <input placeholder="Enter Name (Word Match)"  type="text" class="form-control" #input >
            </div>
        </div>
        <div class="col-xxl-1 col-xl-1 col-lg-1">
            

            <div class="d-grid gap-2">
                <button type="button" class="btn btn-primary" (click)="refresh_list(input.value)">Search</button>
            </div>
        </div>
        
    </div>

    <div class="row justify-content-center">
        <div class="col-xxl-8 col-xl-8 col-lg-8">
            
            
        </div>
    </div>
</div>
-->


<div class="modal fade" id="confirmation_modal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header border border-bottom-0">
                
                <button #confirmation_modal_close_button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        
            </div>
            <div class="modal-body">
              
                
                <div class="row justify-content-center align-items-center">
                    <div class="col-auto">
                        <p style="font-size: 24px !important;font-style: normal !important;font-weight: 700 !important;line-height: 28px !important;">
                            Generating reports is a billed service.
                        </p>

                        
                    </div>
                </div>

                <div class="row justify-content-center align-items-center">
                    <div class="col-auto">
                
                        <p class="text-center" style="font-family: DM Sans !important; font-size: 18px !important; font-style: normal !important; font-weight: 400 !important; line-height: normal !important;">
                            You will be billed for all reports generated by the end of the month.<br/>
                            Click "Continue" to generate a report.
                        </p>
                    </div>
                </div>

                <div class="row justify-content-center align-items-center p-3">
                    <div class="col-xl-3 col-lg-3 col-auto">

                        <div class="d-grid gap-2">
                            <a (click)="closemodal()" target="_blank" href="{{view_link}}" class="btn btn-primary">Continue</a>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="subscription_modal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header border border-bottom-0">
                
                <button #confirmation_modal_close_button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        
            </div>
            <div class="modal-body">
              
                
                <div class="row justify-content-center align-items-center">
                    <div class="col-auto">
                        <p style="font-size: 24px !important;font-style: normal !important;font-weight: 700 !important;line-height: 28px !important;">
                            Searching PEP and Adverse Media are paid services
                        </p>

                        
                    </div>
                </div>

                <div class="row justify-content-center align-items-center">
                    <div class="col-auto">
                
                        <p class="text-center" style="font-family: DM Sans !important; font-size: 18px !important; font-style: normal !important; font-weight: 400 !important; line-height: normal !important;">
                            You will be billed for all reports generated by the end of the month.<br/>
                            Click "Continue" to generate a report.
                        </p>
                    </div>
                </div>

                <div class="row justify-content-center align-items-center p-3">
                    <div class="col-xl-3 col-lg-3 col-auto">

                        <!-- <div class="d-grid gap-2">
                            <a (click)="closemodal()" target="_blank" href="{{view_link}}" class="btn btn-primary">Continue</a>
                        </div> -->
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

