import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { CommonService } from "../library/pcl_lib";
import axios from "axios";
@Injectable({
    providedIn : "root"
})

export class ManifestService {
    constructor(private router: Router,
        private http: HttpClient,
        private lib : CommonService){        
    }//end constructor

    public filterList(q): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+localStorage.getItem('accessToken')
        });
        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        return this.http.post(environment.get_data_staging,q,requestOptions);
    }//end

    public sendManifest(q): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+localStorage.getItem('accessToken')
        });
        const requestOptions: Object = {
            responseType: 'json',
            header : headers
        }//end
        return this.http.post(environment.send_manifest_staging,q,requestOptions);
    }//end

    public generate_manifest(q): Observable<result_data>{
        const headers = new HttpHeaders({
            'Authorization' : 'Bearer '+localStorage.getItem('accessToken')
        });

        const requestOptions: Object = {
            responseType: 'json',
            headers : headers
        }//end
        
        return this.http.post(environment.generate_pdf,q,requestOptions);
    }
}//end class

export interface result_data {}