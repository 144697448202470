import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from './global/global-variable';
import { getAuth,onAuthStateChanged } from 'firebase/auth';
import { FirebaseApp } from "@angular/fire/app";
import { LoginService } from 'src/app/services/login-service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-root',
  
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  loading = false;
  constructor(public global : Globals,
    public cd : ChangeDetectorRef,
    private router : Router,
    private toastr : ToastrService,
    private loginService : LoginService,
    private afApp : FirebaseApp
    ){
      
  }

  receivename($event: boolean) {  
    this.loading = $event;  
  } 

  async ngOnInit(){
    
    this.start_session();
    
    const auth      = getAuth(this.afApp);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
        //this.global.login_session['accessToken'] = await user.getIdToken(true);
        localStorage.setItem('accessToken',await user.getIdToken(true));
        
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
    //refreshTokenUser(this.afApp)
    

    /*const auth      = getAuth(this.afApp);
    auth.onIdTokenChanged(async user => {
       console.log("user",user)
       
        if(user){  
            //if(current_token !== null){
                //if(current_token !== await user.getIdToken()){
                    //console.log('token_changed='+await user.getIdToken());
                    const token = await user.getIdToken(true);
                   
                    this.global.login_session['accessToken'] = token
                   
                //}//end if
            //}//end if
        }else{
          this.global.clearSession();
          this.router.navigate(['/login']);
        }//end if
    });*/
    
  
  }
  title = 'U-CHECK';

  @HostListener('window:mousemove') refreshUserState() {
    const now = new Date().getTime() as any;
    if (localStorage.getItem("session_start") !== null) {
        localStorage.setItem('session_start',now);
    }//end if

    
    
    
 
  }//end


  start_session(){
      const handle = setInterval(() => {
          if(localStorage.getItem('session_start') !== null){
              
              const now           = new Date().getTime() as any;
              const setup_time    = localStorage.getItem('session_start') as any;
              let diff            = 0;
              
              if(setup_time !== null){
                  diff = ((now - setup_time) / 1000) / 60;
              }//end if

              

              if(diff >= 60){ //adjust this to 60mins when live
                
                this.logout();
                //console.log('session_start');
                //console.log(diff);
                //
               
              }//end if
              
              
          }//end if

          if(localStorage.getItem('date_set_time') !== null){
              const now           = new Date().getTime() as any;
              const set_time      = localStorage.getItem('date_set_time') as any;
              let diff2           = 0;

              if(set_time !== null){
                  diff2 = ((((now - set_time) / 1000) / 60) / 60) / 24;
              }//end if

              
              //check if session is 7 days
              if(diff2 >= 7){
                this.logout();
              }//end if
              //end check if session is 7 days
          }//end if

          //return () => clearInterval(handle);
      },((1000 * 60) * 1)) //1 minutes adjust this to 60 when live
      //this.gVar.session_timer = handle;
  }//end

  logout(){
    this.global.loading = true;
    try {
        this.loginService.signOut().then((response) => {
            
            this.global.loading = false;
            if(response.success){
                //update login
                /*const request_param = {
                    "records" : [{
                        "record_type"   : environment.user_management,
                        "action" : "update",
                        "id"  : this.global.login_session["id"],
                        "logged_in" : false
                    }]
                };
                const api = await lastValueFrom(this.userManagementService.save_data(request_param));
                */
                //ennd update login

                this.global.clearSession();
                this.router.navigate(['/login']);
            }else{
                const errObj = {
                    code : response.data["code"],
                    message : response.data["message"],
                }//end 
                this.toastr.error(response.data["message"],"System Message");
                //throw errObj;
                
            }//end if
        })
    } catch (e : any) {
        this.toastr.error(e.message,"System Message",{enableHtml : true});
        this.global.loading = false;
    }//end 
    
}//end logout

  
}


export function refreshToken(app){
    
  const handle    = setInterval(async () => {
  const auth      = getAuth(app);
  
  auth.onIdTokenChanged(async user => {
    

      if(user){
        alert('onIdTokenChanged')
          const login_session = this.global.login_session;
          const current_token = login_session['accessToken'];

          //if(current_token !== null){
              //if(current_token !== await user.getIdToken()){
                  //console.log('token_changed='+await user.getIdToken());
                  var token = await user.getIdToken();
                  this.global.login_session['accessToken'] = token;
                  //localStorage.setItem("accessToken",token);
              //}//end if
          //}//end if
      }else{
        this.global.clearSession();
        this.router.navigate(['/login']);
      }//end if
  });
      // clean up setInterval
      return () => clearInterval(handle);
      //},(1000))
  },((1000 * 60) * 29))
}


export function refreshTokenUser(app){
  const handle  = setInterval(async () => {
    
    if(localStorage.getItem('token_start') !== null){
      const now = new Date().getTime() as any;
      const setup_time  = localStorage.getItem('token_start') as any;
      let diff = 0;
      
      if(setup_time !== null){
          diff = ((now - setup_time) / 1000) / 60;
      }//end if

      if(diff >= 59){
       
        const auth      = getAuth(app);
        await auth.currentUser?.getIdToken(true).then(idToken => {
          alert('refreshtoken')
          console.log('idToken',idToken)
          this.global.login_session['accessToken'] = idToken;
        });
      }//end if
    }
  },((1000 * 60) * 1))

}
